<template>
    <div class="content-box" id="box">
        <div class="hint" :class="isHint ? 'activate' : ''">CP任务已经发布咯,打个卡吧~</div>
        <div class="title-box" v-if="cpInfo && cpInfo.status == 3">
            <div class="row">
                <div class="photo">
                    <div class="photo-link">
                        <img src="https://static.lspace.vip/images/cp/photoLink.png" mode="widthFix" />
                    </div>
                    <div class="img-box manBg">
                        <img class="img" :src="cpInfo.headPhoto" mode="" />
                    </div>
                    <div class="img-box womanBg">
                        <img class="img" :src="cpInfo.objectHeadPhoto" mode=""/>
                    </div>
                </div>
                <div class="text-box">
                    <div class="title">七天CP匹配成功</div>
                    <div class="text">
                        你们在
                        <span>{{ cpInfo.perfectMatch }}</span>
                        上很般配
                    </div>
                </div>
                <div class="task-btn" @click="clickExpand">
                    <div class="dot" v-if="!isTask"></div>
                    <img src="https://static.lspace.vip/images/cp/task.png" mode=""/>
                    <div>任务</div>
                </div>
            </div>
            <div class="row issue">
                <div class="circle"></div>
                hello!不知道聊什么的话,我们可互相提有趣的问题哦~
            </div>
            <div class="btn" @click="clickExpand">选一个问题问TA</div>
        </div>
        <!-- <drop-down @refresherrefresh="refresherrefresh" @scrolltolower="onReachBottomFn" :refresh="refresh" :reduceHeight="reduceHeight" :isTab="false" :scrollTopNum="scrollTop" :animation="animation" v-if="cpInfo"> -->
        <div class="main-box" id="mainBox" ref="mainBox" :class="[isOpen ? 'bottom' : '', cpInfo && cpInfo.status == 3 ? 'top' : '']">
            <template v-for="(item, index) in msgList">
                <div class="clearfix" :class="item.from == objId ? 'left' : 'right'" :key="index" v-if="item.ext.type == 0">
                    <div class="time" v-if="item.ext.showTime">
                        {{item.time | weChatTime}}
                    </div>
                    <div class="photo" v-if="item.from == objId">
                        <img :src="cpInfo.objectHeadPhoto" mode="" @click="dealWithClick(cpInfo.objectHeadPhoto)"/>
                    </div>
                    <div class="text">
                        <template v-for="(t,i) of item.sourceMsg" >
                            <span v-if="t.type == 'txt'" :key="i">{{ t.data }}</span>
                            <img v-if="t.type == 'emoji'" :key="i" :src="require(`./emoji/${t.data}`)" mode="" />
                        </template>
                    </div>
                </div>
                <div class="clearfix" :class="item.from == objId ? 'left' : 'right'" :key="index" v-if="item.ext.type == 6">
                    <div class="time" v-if="item.ext.showTime">
                        {{item.time | weChatTime}}
                    </div>
                    <div class="photo" v-if="item.from == objId">
                        <img :src="cpInfo.objectHeadPhoto+'?imageView2/0/w/200/h/200'" mode="" @click="dealWithClick(cpInfo.objectHeadPhoto)"/>
                    </div>
                    <div class="img">
                        <img :src="item.sourceMsg[0].data+'?imageView2/0/w/200/h/200'" mode="widthFix" @click="previewImg(item)"/>
                    </div>
                </div>
            </template>
        </div>
        <div class="footer-box" :class="isOpen ? 'activate' : ''">
            <div class="usage">
                <div class="icon switch">
                    <img
                    :src="isText ? 'https://static.lspace.vip/images/cp/iconVoice.png' : 'https://static.lspace.vip/images/cp/incoText.png'" 
                    mode="" 
                    @click="isText = !isText"/>
                </div>
                <div class="text" v-if="isText">
                    <!-- <textarea :value="textVal" @focus="inputFocus" @blur="inputBlur" :focus="isFocus" :cursor="cursor" @input="inputChange"
                    @confirm="inputConfirm" placeholder="请输入内容" :auto-height="true" :show-confirm-bar='false' :cursor-spacing="500" /> -->
                    <van-field
                        class="textarea"
                        v-model="textVal"
                        @focus="inputFocus"
                        @blur="inputBlur"
                        rows="1"
                        autosize
                        type="textarea"
                        placeholder="请输入内容"
                    />
                    </div>
                <div class="voice" v-if="!isText">
                    <button @click="clickExpand">按住 说话</button>
                </div>
                <div class="icon emoticon" @click="clickEmoji"><img src="https://static.lspace.vip/images/cp/iconEmoticon.png" mode=""/></div>
                <div class="icon send" v-if="isSend">
                    <div @click="inputConfirm">发送</div>
                </div>
                <div class="icon open" @click="clickOpen" v-if="!isSend"><img src="https://static.lspace.vip/images/cp/iconOpen.png" mode=""/></div>
            </div>
            <div class="expand">
                <div class="handle" v-show="isExpand? 'handle':''">
                    <div class="item" v-for="(item, index) of expandList" :key="index" @click="clickExpand(item)" v-show="isExpand">
                        <img :class="item.status ? 'activate' : ''" :src="item.img" mode=""/>
                        <div>{{ item.text }}</div>
                    </div>
                </div>
                <div class="scroll-view_H">
                    <div v-for="(item, index) in WebIMEmoji" :key="index" v-show="isEmoji">
                        <img v-if="item" class="Emoji" :src="require('./emoji/'+item)" @click="addEmoji(item, index)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getCpStatusInfo, getChatHistory, sendMessage} from '@/api/user.js'
import { ImagePreview } from "vant";
let Emoji = {
  path: "./emoji",
  map: {
	"[微笑]": "weixiao.gif",
	"[撇嘴]": "piezui.gif",
	"[色]": "se.gif",
	"[发呆]": "fadai.gif",
	"[得意]": "deyi.gif",
	"[流泪]": "liulei.gif",
	"[害羞]": "haixiu.gif",
	"[闭嘴]": "bizui.gif",
	"[睡]": "shui.gif",
	"[大哭]": "daku.gif",
	"[尴尬]": "ganga.gif",
	"[发怒]": "fanu.gif",
	"[调皮]": "tiaopi.gif",
	"[呲牙]": "ciya.gif",
	"[惊讶]": "jingya.gif",
	"[难过]": "nanguo.gif",
	"[酷]": "ku.gif",
	"[冷汗]": "lenghan.gif",
	"[抓狂]": "zhuakuang.gif",
	"[吐]": "tu.gif",
	"[偷笑]": "touxiao.gif",
	"[可爱]": "keai.gif",
	"[白眼]": "baiyan.gif",
	"[傲慢]": "aoman.gif",
	"[饥饿]": "jie.gif",
	"[困]": "kun.gif",
	"[惊恐]": "jingkong.gif",
	"[流汗]": "liuhan.gif",
	"[憨笑]": "hanxiao.gif",
	"[大兵]": "dabing.gif",
	"[奋斗]": "fendou.gif",
	"[咒骂]": "zhouma.gif",
	"[疑问]": "yiwen.gif",
	"[嘘]": "xu.gif",
	"[晕]": "yun.gif",
	"[折磨]": "zhemo.gif",
	"[衰]": "shuai.gif",
	"[骷髅]": "kulou.gif",
	"[敲打]": "qiaoda.gif",
	"[再见]": "zaijian.gif",
	"[擦汗]": "cahan.gif",
	"[抠鼻]": "koubi.gif",
	"[鼓掌]": "guzhang.gif",
	"[糗大了]": "qiudale.gif",
	"[坏笑]": "huaixiao.gif",
	"[左哼哼]": "zuohengheng.gif",
	"[右哼哼]": "youhengheng.gif",
	"[哈欠]": "haqian.gif",
	"[鄙视]": "bishi.gif",
	"[委屈]": "weiqu.gif",
	"[快哭了]": "kuaikule.gif",
	"[阴险]": "yinxian.gif",
	"[亲亲]": "qinqin.gif",
	"[吓]": "xia.gif",
	"[可怜]": "kelian.gif",
	"[眨眼睛]": "zhayanjian.gif",
	"[笑哭]": "xiaoku.gif",
	"[doge]": "doge.gif",
	"[泪奔]": "leiben.gif",
	"[无奈]": "wunai.gif",
	"[托腮]": "tuosai.gif",
	"[斜眼笑]": "xieyanxiao.gif",
	"[喷血]": "penxue.gif",
	"[惊喜]": "jingxi.gif",
	"[骚扰]": "saorao.gif",
	"[小纠结]": "xiaojiujie.gif",
	"[我最美]": "wozuimei.gif",
    "[爱你]": "aini.gif",
	"[抱拳]": "baoquan.gif",
	"[强]": "qiang.gif",
	"[勾引]": "gouyin.gif",
	"[OK]": "OK.gif",
	"[拳头]": "quantou.gif",
	"[胜利]": "shengli.gif",
    "[爱心]": "aixin.gif",
    "[棒棒糖]": "bangbangtang.gif",
    "[生气]": "baojin.gif",
    "[菜刀]": "caidao.gif",
    "[吃]": "chi.gif",
    "[好棒]": "haobang.gif",
    "[喝彩]": "hecai.gif",
    "[河蟹]": "hexie.gif",
    "[菊花]": "juhua.gif",
    "[篮球]": "lanqiu.gif",
    "[啤酒]": "pijiu.gif",
    "[手枪]": "shouqiang.gif",
    "[握手]": "woshou.gif",
    "[小样儿]": "xiaoyanger.gif",
    "[西瓜]": "xigua.gif",
    "[羊驼]": "yangtuo.gif",
    "[幽灵]": "youling.gif",
  }
};
export default {
	data() {
		return {
			cp: {},
			WebIMEmoji: Emoji.map,
			expandList: [
				{
					id: 1,
					text: '照片',
					img: 'https://static.lspace.vip/images/cp/iconPicture.png',
					fn: 'pictureFn',
					status: 1
				},
				{
					id: 2,
					text: '相机',
					img: 'https://static.lspace.vip/images/cp/iconCamera.png',
					fn: 'cameraFn',
					status: 1
				},
				{
					id: 3,
					text: '帮问',
					img: 'https://static.lspace.vip/images/cp/iconIssue.png',
					fn: 'issueFn',
					status: 1
				},
				{
					id: 4,
					text: '交换微信',
					img: 'https://static.lspace.vip/images/cp/iconWeChat.png',
					fn: 'WeChatFn',
					status: 1
				},
				{
					id: 5,
					text: '举报',
					img: 'https://static.lspace.vip/images/cp/iconReport.png',
					fn: 'reportFn',
					status: 1
				},
				{
					id: 6,
					text: '客服',
					img: 'https://static.lspace.vip/images/cp/iconService.png',
					fn: 'serviceFn',
					status: 1
				},
				{
					id: 7,
					text: '消息免打扰',
					img: 'https://static.lspace.vip/images/cp/iconNodisturb.png',
					fn: 'nodisturb',
					status: 0
				}
			],
			headerData: {
				title: '七天CP'
			},
			refresh: true,
			msgList: [],
			objId: null,
			isText: true,
			isSend: false,
			textVal: '',
			scrollTop: 0,
			old: {
				scrollTop: 0
			},
			animation: false,
			isShowLoading: true,
			clientY: 0,
			isVoiceImg: false,
			Voice: false,
			awaitResponseTimer: null,
			isShowProblem: false,
			isFocus: false,
			isExpand: false,
			isEmoji: false,
			isOpen: false,
			cursor: 0,
			reduceHeight: 0,
			isHint: false,
			cpInfo: null,
			isNodisturb: 0,
			bottomNum: false,
			iosBotton: 442,
			isTask: false,
            mainBox: null,
            isSendOk: false,
		};
	},
	created() {
        this.enterPage()
        let objectId = this.$route.query.objectId
        getCpStatusInfo({objectId}).then(e=> {
            if(e && e.code == 200) {
                this.cpInfo = e.data
                this.objId = this.cpInfo.objectId
            }
        })
        getChatHistory({to:objectId,pageNo:1,pageSize:99}).then(res => {
            console.log(res)
            let list = res.data.records.reverse()
            list.forEach(item => {
                item.ext = JSON.parse(item.ext)
            })
            this.filterList(list)
        })
	},
	watch: {
		msgList: function() {
			setTimeout(() => {
				// this.scrollBottom();
				// this.animation = true
			}, 0);
		},
		isOpen: function(nval) {
			if (nval) {
				this.bottomNum = true
				// this.scrollBottom();
			} else {
				this.bottomNum = false
			}
		},
		textVal: function(nval) {
			if (nval != '') {
				this.isSend = true;
			} else {
				this.isSend = false;
			}
		}
    },
    filters:{
        weChatTime: unix_stamp => {
            let _today_obj = new Date(),
                _today_date = {
                    y: _today_obj.getFullYear(),
                    m: (_today_obj.getMonth() + 1 < 10 ? '0' + (_today_obj.getMonth() - -1) : (_today_obj.getMonth() - -1)),
                    d: (_today_obj.getDate() < 10 ? '0' + _today_obj.getDate() : _today_obj.getDate())
                }

            let _today_stamp = Date.parse(_today_date.y + '/' + _today_date.m + '/' + _today_date.d + ' 00:00:00')

            let stamp = []
            stamp[0] = _today_stamp + 86400000
            stamp[1] = _today_stamp
            stamp[2] = _today_stamp - 86400000
            stamp[3] = _today_stamp - 172800000

            stamp[4] = _today_stamp - 518400000 // 7天

            stamp[5] = _today_stamp - 31536000000 // 365天

            let _compare_obj = new Date()
            _compare_obj.setTime(unix_stamp)

            let return_str

            if (unix_stamp >= stamp[1] && unix_stamp < stamp[0]) {
                return_str = _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() :
                    _compare_obj.getMinutes())
            } else if (unix_stamp >= stamp[2] && unix_stamp < stamp[1]) {
                let yesterdayText = '昨天'
                return_str = yesterdayText + ' ' + _compare_obj.getHours() + ':' +
                    (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes())
            } else if (unix_stamp >= stamp[3] && unix_stamp < stamp[2]) {
                let theDayBeforeYesterdayText = '前天'
                return_str = theDayBeforeYesterdayText + ' ' + _compare_obj.getHours() + ':' +
                    (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes())

            } else if (unix_stamp >= stamp[4] && unix_stamp < stamp[3]) { // 7天内
                let daynames = ['天', '一', '二', '三', '四', '五', '六']
                let dathStr = '星期' + daynames[_compare_obj.getDay()]

                let SundayText = '星期天'
                let MondayText = '星期一'
                let TuesdayText = '星期二'
                let WednesdayText = '星期三'
                let ThursdayText = '星期四'
                let FridayText = '星期五'
                let SaturdayText = '星期六'

                let dathStr2

                switch (dathStr) {
                    case '星期天':
                        dathStr2 = SundayText
                        break
                    case '星期一':
                        dathStr2 = MondayText
                        break
                    case '星期二':
                        dathStr2 = TuesdayText
                        break
                    case '星期三':
                        dathStr2 = WednesdayText
                        break
                    case '星期四':
                        dathStr2 = ThursdayText
                        break
                    case '星期五':
                        dathStr2 = FridayText
                        break
                    case '星期六':
                        dathStr2 = SaturdayText
                        break
                    default:
                        dathStr2 = dathStr
                        break
                }

                return_str = dathStr2 + ' ' + _compare_obj.getHours() + ':' +
                    (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes())
            } else if (unix_stamp >= stamp[5] && unix_stamp < stamp[4]) { // 365天内
                let monthText = '月'
                let dayText = '日'
                return_str = (_compare_obj.getMonth() - (-1)) + monthText + _compare_obj.getDate() + dayText + ' ' +
                    _compare_obj.getHours() + ':' + (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes())

            } else {
                let yearText = '年'
                let monthText = '月'
                let dayText = '日'
                return_str = _compare_obj.getFullYear() + yearText + (_compare_obj.getMonth() - (-1)) +
                    monthText + _compare_obj.getDate() + dayText + ' ' + _compare_obj.getHours() + ':' +
                    (_compare_obj.getMinutes() < 10 ? '0' + _compare_obj.getMinutes() : _compare_obj.getMinutes())
            }
            return return_str
        }
    },
    mounted() {
        // this.scrollBottom()
    },
	methods: {
		nodisturb() {
			this.expandList.forEach(i => {
				if (i.id == 7) {
					if(i.status) {
						this.setNodisturb(i)
					}
				}
			});
		},
		setNodisturb(obj) {
            console.log(obj)
		},
		serviceFn() {
			this.$Router.push({
				path: '/pages/meet/cp/feedback'
			});
		},
		cpState() {
			if (this.cpInfo && this.cpInfo.status == 3 || this.cpInfo.status == 5) {
				return false;
			} else {
				this.warn('CP关系已解除');
				return true;
			}
		},
		addEmoji(item, index) {
            this.isFocus = false;
			this.textVal = this.textVal + index;
		},
		clickEmoji() {
			if (this.isOpen && this.isEmoji) {
				this.isOpen = false;
				return;
			}
			this.isExpand = false;
			this.isEmoji = true;
			this.isOpen = true;
		},
		clickOpen() {
			if (this.isOpen && this.isExpand) {
				this.isOpen = false;
				return;
			}
			this.isEmoji = false;
			this.isExpand = true;
			this.isOpen = true;
		},
		reportFn() {
			this.$Router.push('/pages/meet/report/report?id=' + this.objId);
		},
		issueFn() {
			this.isShowProblem = true;
		},
		selectProblem(item) {
			this.isText = true
			this.isOpen = false;
			this.textVal = item.title;
			this.isFocus = true;
			
		},
		clearTimer() {
			if (this.awaitResponseTimer) {
				clearTimeout(this.awaitResponseTimer);
				this.awaitResponseTimer = null;
			}
        },
        dealWithClick(img) {
            console.log(img)
            ImagePreview({
                images: [img],
                closeable: true,
            })
        },
		previewImg(item) {
            let arr = item.sourceMsg.map(i => i.data)
            ImagePreview({
                images: arr,
                closeable: true,
            })
		},
		inputBlur(e) {
			this.focus = false;
			this.cursor = e.detail.cursor;
		},
		filterList(list) {
            console.log(list)
			let conditionList = list.filter(item => item.ext.type == 99);
			let dataList = list.filter(item => item.ext.type != 99 && item.ext.type !== 4);
			conditionList.forEach(i => {
				dataList.forEach(j => {
					if (i.ext) {
						if (i.ext.objId == j.ext.id) {
							j.ext = { ...j.ext, ...i.ext.objParam };
						}
					}
				});
			});
			dataList.forEach(i => {
				if (typeof i.sourceMsg == 'string') {
					let o = this.IMfilterText(i.sourceMsg);
					i.sourceMsg = o.objList;
				}
			});
            console.log(conditionList, dataList)
            let num = 0
            dataList.forEach(i => {
                let n = (Math.floor(Math.random()*5) + 3)* 1000
                num += n
                setTimeout(() => {
                    this.msgList.push(i)
                }, num);
            })
			// this.msgList = dataList;
			this.isShowLoading = false;
			console.log(this.msgList, 'msgList')
			this.$forceUpdate();
        },
        IMfilterText(msg) {
            console.log(Emoji)
			if (typeof Emoji === "undefined" || typeof Emoji.map === "undefined") {
				return msg;
			}
			let emoji = Emoji,
				reg = null;
			let msgList = [];
			let objList = [];
            let isEmoji = false
            console.log(emoji.map, 'emoji.map')
			for (let face in emoji.map) {
				// eslint-disable-next-line no-prototype-builtins
				if (emoji.map.hasOwnProperty(face)) {
					while (msg.indexOf(face) > -1) {
						msg = msg.replace(face, "^" + emoji.map[face] + "^");
					}
				}
			}
			let ary = msg.split("^");
			reg = /.*.gif$/;
			for (let i = 0; i < ary.length; i++) {
				if (ary[i] != "") {
					msgList.push(ary[i]);
				}
			}
			for (let i = 0; i < msgList.length; i++) {
                let obj = {}
				if (reg.test(msgList[i])) {
					obj.data = msgList[i];
					obj.type = "emoji";
					objList.push(obj);
					isEmoji = true
				} else {
					obj.data = msgList[i];
					obj.type = "txt";
					objList.push(obj);
				}
			}
			return {
				objList,
				isEmoji
			};
		},
		inputFocus() {
			// if(this.isOpen && !this.isEmoji) {
				this.isOpen = false;
			// }
		},
		textSend(obj, isVal = false) {
			this.WebIMsend(obj, 'txt',this.cpInfo).then(res => {
				
				let val = this.IMfilterText(res.sourceMsg).objList;
				res.sourceMsg = val
				console.log(res, 'resresrser')
				console.log(res)
				this.msgList.push(res);
				if (isVal) return;
				this.textVal = '';
			});
        },
        timestamp() {
			return new Date().getTime()
		},
        WebIMsend(obj, type, cpInfo) {
			// let that = this
			// let id = WebIM.conn.getUniqueId();
			let showTime = 0
			let showTimeList = this.msgList.filter(i => i.ext.showTime == 1)
			return new Promise((rs) => {
				// console.log(this.timestamp() - this.msgList[this.msgList.length-1].time, 'time')
				if(showTimeList.length > 0) {
					if(this.timestamp() - showTimeList[showTimeList.length-1].time > 300000) {
						showTime = 1
					}
				} else {
					showTime = 1
				}
				let param = {
					ext: JSON.stringify({...obj.param,...{showTime}}),
					to: obj.to,
					sourceMsg: obj.content
				}
				sendMessage(param).then(res=> {
					console.log(res, 'resres')
					if(res && res.code == 200) {
						let data = res.data
						if(res.data.ext) {
							data.ext = JSON.parse(res.data.ext)
						}
						rs(data)
						let objectInfo = {
							nickName: cpInfo.objectNickName,
							memberId: cpInfo.objectId,
							avatarUrl: cpInfo.objectHeadPhoto,
						}
						let history = {
							data: data.sourceMsg,
							ext: data.ext,
							sourceMsg: data.sourceMsg,
							from: data.to,
							to: data.from,
							time: data.time,
							...objectInfo,
							memberInfo: objectInfo,
						}
						console.log(history)
					}
				})
			})
		},
		inputConfirm() {
            if (this.cpState()) return;
            if (this.isSendOk) {
                console.log('第二条')
                this.clickExpand()
                return
            }
            this.isSendOk = true
			let data = {
				to: this.objId,
				content: this.textVal,
				param: {
					type: 0
				}
			};
			this.textSend(data);
		},
		inputChange(e) {
			this.textVal = e.detail.value;
		},
		clickExpand() {
            console.log('获取完整功能')
            this.$dialog.alert({
                message: `为了您更好的体验，关注公众号
“佳偶天成”`,
                confirmButtonColor:'#FF7C8F'
            }).then(() => {
                this.$router.push('/copy')
            });
		},
		WeChatFn() {
			if(this.cpInfo.status != 5){
				this.warn('完成7日任务，才可交换微信哦');
				return
			}
			let isWechat = false;
			this.msgList.forEach(itme => {
				if (itme.ext.state == 0) {
					isWechat = true;
				}
			});
			if (isWechat) {
				// uni.showToast({
				// 	title: '请等待回应哦！',
				// 	duration: 1000,
				// 	icon: 'none'
				// });
				return;
			}
			let data = {
				to: this.objId,
				content: '交换微信',
				param: {
					type: 2,
					state: 0
				}
			};
			this.textSend(data, true);
		},
		WeChatReceive(e) {
            console.log(e)
			// uni.showLoading({
			// 	title: '交换中...'
			// });
			// getDetail({
			// 	memberId: this.objId
			// }).then(res => {
			// 	if (res && res.code == 200) {
			// 		uni.hideLoading();
			// 		this.objInfo = res.data;
			// 		console.log(e, 'eeeee')
			// 		let data = {
			// 			to: this.objId,
			// 			content: '同意微信',
			// 			param: {
			// 				type: 99,
			// 				obj: 2,
			// 				objId: e.ext.id,
			// 				objParam: {
			// 					state: 1,
			// 					memberWechatCode: this.userInfo.wechatNum || '暂未填写',
			// 					objWechatCode: this.objInfo.wechatNum || '暂未填写'
			// 				}
			// 			}
			// 		};
			// 		e.ext.state = 1;
			// 		e.ext.memberWechatCode = this.userInfo.wechatNum;
			// 		e.ext.objWechatCode = this.objInfo.wechatNum;
			// 		this.textSend(data, true);
			// 	} else {
			// 		uni.hideLoading();
			// 	}
			// });
		},
		WeChatError(e) {
			e.ext.state = 2;
			console.log(e,'eeee')
			let data = {
				to: this.objId,
				content: '拒绝微信',
				param: {
					type: 99,
					obj: 2,
					objId: e.ext.id,
					objParam: {
						state: 2
					}
				}
			};
			this.textSend(data, true);
		},
		upLoadFile(res) {
			let imgs = []
			res.tempFiles.forEach(item=> {
				imgs.push({
					newImg: true,
					path: item.path,
					size: item.size,
					// types: item.name ? (/\.[^\.]+$/).exec(item.name)[0].toLowerCase() : null
				})
			})
			// uni.showLoading({
			// 	title: '发送中...'
			// });
			// uploadImgs(imgs, null, 7, "getImgInfo").then(res=> {
			// 	let d = res[0]
			// 	let data = {
			// 		to: this.objId,
			// 		content: d.imgUrl,
			// 		param: {
			// 			type: 6,
			// 			height: d.height,
			// 			width: d.width,
			// 		}
			// 	};
			// 	this.textSend(data, true);
			// 	uni.hideLoading();
			// })
		}
	}
};
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
