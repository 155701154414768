<template>
  <div class="bgImg">
    <div class="title">
      <img src="../../assets/cp/7daysCp_title.png"
           alt="">
    </div>
    <div class="center_content">
      <img src="../../assets/cp/7daysCp_content.png"
           alt="">
    </div>
    <div class="btn">
      <img @click="toInfoNewSex()"
           src="../../assets/cp/7daysCp_btn.png"
           alt="">
    </div>
    <div class="agreement">
      <label for="">
        <input type="checkbox"
               v-model="isChecked" /><span>同意</span><span @click="toUserAgreement">《隐私协议》</span></label>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      isChecked: false,
      pageInfo: {},

    }
  },
  methods: {
    toInfoNewSex () {
      if (this.isChecked) {
        this.$router.push({
          path: '/info/newSex',
          query: { ...this.$route.query, layout: 'bili' },
        })
      } else {
        this.$toast('请确认同意隐私协议')
      }
    },
    toUserAgreement () {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    }
  }

}
</script>

<style scoped lang="scss">
.bgImg {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/cp/7daysCp_bg.png") no-repeat;
  background-size: cover;
  background-position-y: 100%;
  display: flex;
  flex-direction: column;

  .title {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 103px;
    }
  }
  .center_content {
    position: absolute;
    bottom: 130px;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 411px;
    }
  }
  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    img {
      height: 53px;
    }
  }
  .agreement {
    font-size: 14px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    label {
      input {
        vertical-align: middle;
        height: 16px;
        width: 16px;
      }
      span {
        vertical-align: middle;
      }

      span:nth-of-type(1) {
        margin-left: 4px;
        color: rgb(92, 83, 83);
      }
    }
  }
}
</style>