<style scoped>
  @import './style/main.css';
  @import './style/animationOnAndOut.css';
  @import './style/makaElementEffect.css';
</style>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<template>
  <div class="maka-canvas">
    <div class="maka-pages" id="maka-pages">
      <div class="maka-page maka-page-8" v-show="current == 1">
        <div class="page-bg-8"></div>
        <div class="maka-eleCanvas maka-eleCanvas-8">
          <div ele-id="124">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/9891cde1fafdc89759c43dffe8f9ff63.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="125">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/99b4c0b0eccb475458e76027f88d65a1.png?x-oss-process=image/auto-orient,1" >
            </div>
          </div>
          <div ele-id="126">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/7baa8a05e4ff6d03484dbd405a6b8021.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="127">
            <div>
              <img src="https://img1.maka.im/materialStore/sucaisheji/xiangce/10/M_TK81O0KG/M_TK81O0KG_v1.png?x-oss-process=image/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="128">
            测试结果：A 桃花运满满
          </div>
          <div ele-id="129">
            <div class="text">
              <div class="explain">解析：</div>
              <div class="span">你今年很快就会遇到生命中的那个TA，你们的相遇也好像是上天注定一般，很快就能够展开一段美好的恋情了。所以过完年之后，不妨多留意一下身边的好人选，也不要去排斥家人朋友给你介绍的对象，你要是心存希望，就更有可能会遇到你钟意的那个人，如果运气好的话，甚至很有可能在年前就顺利找到对象</div>
            </div>
          </div>
          <div ele-id="140">
            <div>
              <span style="font-size: 16px;color: rgb(246, 102, 153);font-weight: normal;font-style: normal;text-decoration: none;">佳偶天成</span>
              <br />
              <span style="font-size: 16px;color: #000;font-weight: normal;font-style: normal;text-decoration: none;">一个真实有趣的单身社交平台</span>
            </div>
          </div>
          <div ele-id="141">
            <div @click="toTest" v-if="isBtn">我也要测测</div>
          </div>
          <div ele-id="142">
            <div @click="toHome">点我，领取一个对象</div>
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-9" v-show="current == 2">
        <div class="page-bg-9"></div>
        <div class="maka-eleCanvas maka-eleCanvas-9">
          <div ele-id="135">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/9891cde1fafdc89759c43dffe8f9ff63.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="136">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/99b4c0b0eccb475458e76027f88d65a1.png?x-oss-process=image/auto-orient,1" >
            </div>
          </div>
          <div ele-id="137">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/7baa8a05e4ff6d03484dbd405a6b8021.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="138">
            <div>
              <img src="https://img1.maka.im/materialStore/sucaisheji/xiangce/10/M_ZHB1DAO8/M_ZHB1DAO8_v1.png?x-oss-process=image/crop,x_0,y_56,w_236,h_235/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="128">
            测试结果：B 可能被催婚
          </div>
          <div ele-id="129">
            <div class="text">
              <div class="explain">解析：</div>
              <div class="span">过年回家的时候，你很有可能会遭遇到亲朋好友的轮番轰炸，问你为什么还没有对象等等，也许现在的你心中已经有了钟意的人，只不过你的表现并没有给对方相同的感觉，所以也许本来有希望的事，也会从你手边悄悄溜走，所以最近你一定要思考，自己究竟要的是什么，不要再让机会白白从指缝流失了。</div>
            </div>
          </div>
          <div ele-id="140">
            <div>
              <span style="font-size: 16px;color: rgb(246, 102, 153);font-weight: normal;font-style: normal;text-decoration: none;">佳偶天成</span>
              <br />
              <span style="font-size: 16px;color: #000;font-weight: normal;font-style: normal;text-decoration: none;">一个真实有趣的单身社交平台</span>
            </div>
          </div>
          <div ele-id="141" v-if="isBtn">
            <div @click="toTest">我也要测测</div>
          </div>
          <div ele-id="142">
            <div @click="toHome">点我，领取一个对象</div>
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-10" v-show="current == 3">
        <div class="page-bg-10"></div>
        <div class="maka-eleCanvas maka-eleCanvas-10">
          <div ele-id="143">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/9891cde1fafdc89759c43dffe8f9ff63.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="144">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/99b4c0b0eccb475458e76027f88d65a1.png?x-oss-process=image/auto-orient,1" >
            </div>
          </div>
          <div ele-id="145">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/7baa8a05e4ff6d03484dbd405a6b8021.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="138">
            <div>
              <img src="https://img1.maka.im/materialStore/sucaisheji/xiangce/10/M_ZHB1DAO8/M_ZHB1DAO8_v1.png?x-oss-process=image/crop,x_0,y_56,w_236,h_235/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="128">
            测试结果：C 或许会有意外之喜
          </div>
          <div ele-id="129">
            <div class="text">
              <div class="explain">解析：</div>
              <div class="span">今年脱单的希望渺茫，并不是因为遇不到钟意的人，而是因为你本身还没有做好迎接恋情的准备，你十分享受自己一个人的感觉，不希望有人来破坏这份宁静，但是虽然你不着急，可你的家人着急，不过你也很倔强，不会因为别人的期望，就轻易改变自己的想法，不过缘分到了的话，也不要躲，不妨试着先相处看看，说不定会有意外惊喜。</div>
            </div>
          </div>
          <div ele-id="140">
            <div>
              <span style="font-size: 16px;color: rgb(246, 102, 153);font-weight: normal;font-style: normal;text-decoration: none;">佳偶天成</span>
              <br />
              <span style="font-size: 16px;color: #000;font-weight: normal;font-style: normal;text-decoration: none;">一个真实有趣的单身社交平台</span>
            </div>
          </div>
          <div ele-id="141" v-if="isBtn">
            <div @click="toTest">我也要测测</div>
          </div>
          <div ele-id="142">
            <div @click="toHome">点我，领取一个对象</div>
          </div>
        </div>
      </div>
      <!-- <div class="maka-page maka-page-11" v-show="current == 4">
        <div class="page-bg-11"></div>
        <div class="maka-eleCanvas maka-eleCanvas-11">
          <div ele-id="143">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/9891cde1fafdc89759c43dffe8f9ff63.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="144">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/99b4c0b0eccb475458e76027f88d65a1.png?x-oss-process=image/auto-orient,1" >
            </div>
          </div>
          <div ele-id="145">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/7baa8a05e4ff6d03484dbd405a6b8021.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="151">
            <div>
              <img src="https://img1.maka.im/materialStore/sucaisheji/xiangce/10/M_2KHQHHXD/M_2KHQHHXD_v1.png?x-oss-process=image/crop,x_108,y_0,w_420,h_640/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="147">
            <div class="text">D、不妨先问自己一个问题，自己有没有从这份感情中走出来？你的上一段感情并没有带来好结果，这也导致了你对之后的感情也抱有怀疑态度，你不知道还能不能遇到真爱，痛苦使你不愿意去面对下一段感情，但是不要紧，只要走出去，一切就会好。不用急于一时，不妨先放松心情，试着重新找到曾经的自己。</div>
          </div>
          <div ele-id="148">
            <div>
              <span style="font-size: 16px;color: rgb(246, 102, 153);font-weight: normal;font-style: normal;text-decoration: none;">佳偶天成</span>
              <br />
              <span style="font-size: 16px;color: #000;font-weight: normal;font-style: normal;text-decoration: none;">一个真实有趣的单身社交平台</span>
            </div>
          </div>
          <div ele-id="149">
            <div @click="toTest">我也要测测</div>
          </div>
          <div ele-id="150">
            <div @click="toHome">点我，领取一个对象</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import {WeChatH5Mandate, WeChatFriendsShare} from '@/common/WXH5.js'
  export default {
    data() {
      return {
        current: 1,
        isPopups: false,
        isInfo: false,
        isBtn: false
      }
    },
    created() {
      WeChatH5Mandate(window.location.href, ['ready', 'onMenuShareAppMessage', 'onMenuShareTimeline']).then(() => {
        let data = {
          title: '我生命中的那个TA竟然会在这个时候出现！你也来测测吧~',
          desc: '我刚试特别准，你也来测测吧~',
          link: '/lancer/#/h5',
          imgUrl: 'https://img1.maka.im/user/13840054/images/88d4e0170437440093b7ba8296dc60eb.png@23-22-172-172a'
        }
        WeChatFriendsShare(data)
      })
      if(this.$route.query && this.$route.query.info) {
        this.current = this.$route.query.info
      } else {
        this.$router.push('/h5')
      }
      if(this.$route.query && this.$route.query.btn) {
        this.isBtn = true
      }
    },
    methods: {
      toTest() {
        this.$router.push('/h5')
      },
      toHome() {
        window.location.href = window.location.origin
      }
    }
  }
</script>
