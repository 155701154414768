<style lang="scss" scoped>
.btn {
  text-align: center;
  background: var(--btnBg, #ff7c8f);
  color: #fff;
  margin: 0px 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 44px;
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 var(--rgbaColor);
  // background-image: linear-gradient(135deg,#FF7C8F,rgba($color: #FF7C8F, $alpha: .5));
}
.btn-box {
  padding-top: 20px;
}
</style>
<template>
  <div class="region">
    <div class="region-select" v-if="columns.length > 0">
      <!-- <van-area title="标题" :area-list="columns" /> -->
      <van-picker
        ref="picker"
        :columns="columns"
        :swipe-duration="500"
        @change="onChange"
      />
    </div>
    <div class="btn-box">
      <div class="btn" @click="goToUrl">下一步</div>
    </div>
  </div>
</template>

<script>
import { region } from '../assets/area.js'
// import { getCityCode } from '@/api/user.js'
export default {
  data() {
    return {
      columns: [],
      area: [],
      param: null,
    }
  },
  created() {
    this.enterPage()
    const _region = JSON.parse(JSON.stringify(region))
    _region.unshift({
      text: '请选择',
      values: 'c-1',
      children: [
        {
          text: '请选择',
          values: 'c-1',
          children: [
            {
              text: '请选择',
              values: 'c-1',
            },
          ],
        },
      ],
    })
    this.columns = _region
  },
  mounted() {
    if (this.$route.query.layout === 'bili')
      document
        .getElementsByTagName('body')[0]
        .style.setProperty('--btnBg', '#80a3e0')
    else if (this.$route.query.layout === 'layout')
      document
        .getElementsByTagName('body')[0]
        .style.setProperty('--btnBg', '#ff7c8f')
  },
  watch: {
    '$store.state.pageInfo': {
      immediate: true,
      handler(pageInfo) {
        this.param = JSON.parse(sessionStorage.getItem('lancer')) || {}
        this.area = this.param.workCity || this.area
        if (this.param && this.param.workCity) return this.defaultWorkCity()
        if (pageInfo.tenantId === undefined) return
        // if (pageInfo.tenantId == '44') {
        //   if (navigator.geolocation) {
        //     console.log('获取定位')
        //     navigator.geolocation.getCurrentPosition(
        //       (position) => {
        //         getCityCode({
        //           lng: position.coords.longitude,
        //           lat: position.coords.latitude,
        //         }).then((res) => {
        //           console.log(res)
        //           if (res.code === 200) {
        //             this.setColumns(res.data)
        //           } else {
        //             this.setColumns('-1')
        //           }
        //         })
        //       },
        //       (err) => {
        //         this.$toast('获取定位失败')
        //         console.log(err, 'err')
        //         this.defaultWorkCity()
        //       },
        //       { timeout: 10000 }
        //     )
        //   }
        // } else {
          this.defaultWorkCity()
        // }
      },
    },
  },
  methods: {
    defaultWorkCity() {
      this.$nextTick(() => {
        console.log(this.$route.query.tenantId)
        if (
          this.$route.query.tenantId === '6' &&
          this.$route.query.shopId === '160'
        )
          return this.setColumns('-1')
        let workCity = this.param.workCity || false
        if (workCity) {
          this.$refs.picker.setColumnIndex(0, workCity[0].index)
          this.$refs.picker.setColumnIndex(1, workCity[1].index)
          this.$refs.picker.setColumnIndex(2, workCity[2].index)
        } else {
          if (
            this.pageInfo.tenantId == '44' 
            // &&
            // (this.$route.query.shopId === '134' ||
            //   this.$route.query.shopId === '75')
          ) {
            console.log('tenantId44')
            this.setColumns('-1')
          } else {
            console.log(this.pageInfo.defaultWorkCity)
            this.setColumns(this.pageInfo.defaultWorkCity)
          }
        }
      })
    },
    onChange(picker, value) {
      let index = this.$refs.picker.getIndexes()
      let obj = []
      this.columns.forEach((item) => {
        if (item.text == value[0]) {
          item.children.forEach((i) => {
            if (i.text == value[1]) {
              i.children.forEach((j) => {
                if (j.text == value[2]) {
                  obj.push({
                    text: item.text,
                    id: item.values,
                    index: index[0],
                  })
                  obj.push({
                    text: i.text,
                    id: i.values,
                    index: index[1],
                  })
                  obj.push({
                    text: j.text,
                    id: j.values,
                    index: index[2],
                  })
                }
              })
            }
          })
        }
      })
      this.area = obj
    },
    goToUrl() {
      console.log(this.area, 'this.area')
      if (!this.area.length) return this.$toast('请选择地区')
      if (this.area[0].index === 0) return this.$toast('请选择地区')
      this.param.workCity = this.area
      sessionStorage.setItem('lancer', JSON.stringify(this.param))
      setTimeout(() => {
        this.$router.push({
          path: '/info/year',
          query: {
            ...this.$route.query,
          },
        })
      }, 300)
    },
    setColumns(val) {
      let newVal = 'c' + val
      this.columns.forEach((v1, i1) => {
        if (v1.children) {
          v1.children.forEach((v2, i2) => {
            if (v2.children) {
              v2.children.forEach((v3, i3) => {
                if (v3.values == newVal) {
                  this.$refs.picker.setColumnIndex(0, i1)
                  this.$refs.picker.setColumnIndex(1, i2)
                  this.$refs.picker.setColumnIndex(2, i3)
                  this.onChange('', [v1.text, v2.text, v3.text])
                }
              })
            }
          })
        }
      })
    },
  },
}
</script>
