// mock:本地模拟，develop:开发环境，test: 测试环境，pre: 预发布环境，produce:生产环境
export const ENV = process.env.NODE_ENV
export const VERSION = '0'
export const mapKey = 'LP6BZ-ZOFK2-5PYUX-CYGXE-3S26S-RUFEQ'
export const Bucket = 'userphotos-1251077853'
export const Region = 'ap-beijing'
export const appId = (() => {
    let appId = ''
    switch (ENV) {
        case 'development':
            appId = 'wxc75fb5b9de447a28'
            break
        case 'production':
            appId = 'wx4f76018ef67d683d'
            break
    }
    return appId
})()
// request接口域名
// http://api.lspace.yihot.com/community/message/query-message-for-page?pageSize=5&pageIndex=1&memberId=20&messageType=2
console.log(ENV, 'envenvenv')
export const baseUrl = (() => {
    let baseUrl = ''
    switch (ENV) {
        case 'mock':
            baseUrl = 'http://yapi.lspaceoa.com/mock/527/'
            break
        case 'development':
            // baseUrl = 'https://api-jotc.yihot.com'
            baseUrl = 'https://api-dev.yihot.com/'
            // baseUrl = 'https://dev-app.lspaceoa.com/api/'
            break
        case 'test':
            baseUrl = 'https://api-demo.yihot.com/'
            break
        case 'production':
            // baseUrl = 'https://api-jotc.yihot.com'
            // baseUrl = 'https://api.lspace.yihot.com/'
            // baseUrl = 'https://dev-app.lspaceoa.com
            baseUrl = 'https://api.lspace.vip'
            break
        case 'local':
            baseUrl = 'http://localhost:2333/'
            break
    }
    return baseUrl
})()

export const socketUrl = (() => {
    let socketUrl = ''
    switch (ENV) {
        case 'development':
            socketUrl = 'wss://dev.api.lspace.yihot.com'
            break
        case 'test':
            socketUrl = 'wss://test.api.lspace.yihot.com'
            break
        case 'production':
            socketUrl = 'wss://api.lspace.vip'
            break
    }
    return socketUrl
})()