import {get, post } from "../utils/http";
// var timestampId = [Date.parse(new Date()), Math.floor(Math.random() * 900) + 100].join('')
// 获取配置信息
function formatData(data) {
    if (window.tenantId) data.tenantId = window.tenantId
    if (window.shopId) data.shopId = window.shopId
    return data
}
// 获取用户信息
export const pageInfo = (data) => {
        let newData = formatData(data)
        return get(`app/adv/page-info`, newData)
    }
    // 验证码发送信息
export const submitTmpInfo = (data) => {
        let newData = formatData(data)
        return post(`app/adv/submit-tmp-info`, newData)
    }
    // 获取验证码
export const verifyCode = (data) => {
    let newData = formatData(data)
    return get(`app/login/send-verifyCode`, newData);
};
// 登录
export const H5Login = (data) => {
    let newData = formatData(data)
    return post(`app/login/H5-login`, newData);
};
// 注册
export const wechatRepair = (data) => {
    let newData = formatData(data)
    return post(`app/login/wechat-repair`, newData);
};
// 埋点
export const enterPage = (data) => {
    let newData = formatData(data)
    return get(`app/adv/enter-page?timestamp=${Date.parse(new Date())}`, newData)
};
// 获取基础资料
export const detailInfo = (data) => {
    return get(`app/member/detail-info`, data)
};
// 获取资料标签
export const getMakerTags = (data) => {
    return get(`app/member-cards/get-maker-tags`, data);
};
// 获取心动卡牌
export const getMemberCards = (data) => {
    return get(`app/member-cards/get-member-cards`, data);
};
// 获取三观问答
export const getMemberValuesNotDefault = (data) => {
    return get(`app/member-values/get-member-values-not-default`, data);
};
// 获取认证信息
export const findAllAuth = (data) => {
    return get(`app/member-auth/find-all-auth`, data);
};
// 获取推荐资料
export const recommend = (data) => {
    return get(`app/adv/recommend`, data);
};

// CP落地页资料
export const cpSubmitInfo = (data) => {
    if (window.tenantId) data.tenantId = window.tenantId
    return post(`app/adv/cp-submit-info`, data);
};
// 7天cp报名
export const join = (data) => {
    return post(`activity/cp/task/join`, data);
};
// 查看CP状态
export const getCurrentCp = (data) => {
    return get(`activity/cp/task/get-current-cp`, data);
};
// 查看聊天记录
export const getChatHistory = (data) => {
    return get(`app/emchat/get-chat-history`, data);
};
// 获取CP信息
export const getCpStatusInfo = (data) => {
    return get(`activity/cp/member/get-cp-status-info`, data);
};
// 发送消息
export const sendMessage = (data) => {
    return post(`app/emchat/send-message`, data);
};
// 获取wxjssdk签名
export const getSdkPermission = (params) => {
    return get(`app/order/js-sdk-permission`, params);
};
// 短信code登录
export const smsRecallLogin = (params) => {
    return post(`app/login/sms-recall-login`, params);
};
// 上传答案
export const sendResult = (params) => {
    return post(`app/topic/topic-user`, params);
};

// 上传答案
export const getCityCode = (params) => {
	return get(`app/login/cityCode`, params);
};