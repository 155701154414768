<template>
  <div class="bgImg"></div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.bgImg {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/submit_success.jpg") no-repeat;
  background-size: 100% 100%;
}
</style>