<style lang="scss" scoped>
    .height-box{
        max-height: 450px;
        min-height: 400px;
        overflow: hidden;
        overflow-y: auto;
    }
    .flex-box {
        display: flex;
    }
    .height {
        border-bottom: 1px solid #F0F0F0;
    }
    .height-name {
        position: relative;
        width: 57.5px;
        color: var(--btnBg);
        text-align: center;
        .height-num {
            font-size: 21px;
        }
        div{
            font-size: 15px;
            &:last-child {
                width: 1px;
                height: 95px;
                background: var(--rgbaColor);
                margin-left: 27px;
            }
        }
    }
    .item-box {
        flex: 1;
        font-size: 14px;
        color: #3a3a3a;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        .item {
            width:25%;
            text-align: center;
            line-height: 50px;
            height: 50px;
            border-bottom: 1px solid #F0F0F0;
            border-right: 1px solid #f0f0f0;
            margin-bottom: -1px;
            margin-right: -1px;
            box-sizing: border-box;
        }
        .item.selected {
            color:#fff;
            background-color:var(--btnBg);
            border: none;
            box-shadow: 2px 3px 5px 1px var(--rgbaColor);
            border-radius: 2px;
        }
        .item.nonactivated {
            color: #F0F0F0;
        }
    }
</style>
<template>
  <div class="height-box" ref="itemBox">
      <div class="flex-box height" v-for="(item,index) of heightList" :key="index">
          <div class="height-name" >
              <p class="height-num">{{item.text}}</p>
              <div>cm</div>
              <div></div>
          </div>
          <div class="item-box">
              <template v-for="(iitem, i) of item.list">
                  <div class="item" :key="i" :class="[iitem.activate? '':'nonactivated', iitem.on? 'selected':'']" @click="itemChecked(iitem)">{{iitem.text}}</div>
              </template>
              <div class="item"></div>
              <div class="item"></div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            heightList: [],
            heightVal: ''
        }
    },
    created() {
        this.enterPage()
        let arr = [
            this.yearFn('120',9,10),
            this.yearFn('130',0,10),
            this.yearFn('140',0,10),
            this.yearFn('150',0,10),
            this.yearFn('160',0,10),
            this.yearFn('170',0,10),
            this.yearFn('180',0,10),
            this.yearFn('190',0,10),
            this.yearFn('200',0,10),
            this.yearFn('210',0,2)
        ]
        this.heightList = arr
        let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
        this.param = param
        this.heightList.forEach(i=> {
            i.list.forEach(j=> {
                if(param.height == j.text) {
                    j.on = true
                }
            })
        })
    },
    mounted() {
        this.box = this.$refs.itemBox
        this.box.scrollTop = 500
    },
    methods: {
        yearFn(prefix, min, max) {
            let obj = {
                text: prefix,
                list: (() => {
                    let arr = []
                    for(let i=0; i < 10;i++) {
                        let obj = {
                            text:prefix.slice(0,2) + i,
                            on: false
                        }
                        if(i>= min && i<=max) {
                            obj.activate =true
                        } else {
                            obj.activate =false
                        }
                        arr.push(obj)
                    }
                    return arr
                })()
            }
            return obj
        },
        goToUrl() {
            let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
            param.height = this.heightVal
            sessionStorage.setItem('lancer',JSON.stringify(param))
            setTimeout(()=> {
                this.$router.push({path:'/info/education',query: {
                    ...this.$route.query
                }})
            }, 300)
        },
        itemChecked(item) {
            this.heightList.forEach(i=> {
                i.list.forEach(j=> {
                    j.on = false
                })
            })
            item.on = true
            this.heightVal = item.text
            this.goToUrl()
        }
    }
}
</script>