<style lang="scss" scoped>
    .year-box {
        max-height: 450px;
        min-height: 400px;
        overflow: hidden;
        overflow-y: auto;
    }
    .yearName {
        line-height: 42.5px;
        padding-left: 16px;
        font-size: 21px;
        color: var(--rgbaColor,rgba(255,124,143,.5));
        border-bottom: 1px solid #F0F0F0;
        span {
            font-size: 15px;
        }
    }
    .itemBox {
        .yearItem {
            float: left;
            width: 20%;
            text-align: center;
            height: 50px;
            line-height: 50px;
            box-sizing: border-box;
            border-bottom: 1px solid #F0F0F0;
            border-right: 1px solid #F0F0F0;
            color: #3a3a3a;
            font-size: 14px;
            &:nth-child(5) {
                border-right: none;
            }
            &:nth-child(10) {
                border-right: none;
            }
        }
        .yearItem.selected {
            color:#fff;
            background-color:var(--btnBg);
            border: none;
            box-shadow: 2px 3px 5px 1px var(--rgbaColor,rgba(255,124,143,.5));
            border-radius: 2px;
        }
        .yearItem.nonactivated {
            color: #F0F0F0;
        }
    }
</style>
<template>
  <div class="year-box" ref="itemBox">
      <div class="flexBox" v-for="(item, index) of yearList" :key="index">
          <div class="yearName" :id="item.text">
              {{item.text}} <span>后</span>
          </div>
          <div class="itemBox" >
              <template v-for="(iitem, i) of item.list">
                <div class="yearItem" :key="i" :class="[iitem.activate? '':'nonactivated', iitem.on? 'selected':'']" @click="itemChecked(iitem)">{{iitem.text}}</div>
              </template>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            yearList: [{
                text: 50,
                list: []
            }],
            yearVal: ''
        }
    },
    created() {
        this.enterPage()
        let arr = [
          this.yearFn('1950',0,10),
          this.yearFn('1960',0,10),
          this.yearFn('1970',0,10),
          this.yearFn('1980',0,10),
          this.yearFn('1990',0,10),
          this.yearFn('2000',0,2)
        ]
        this.yearList = arr
        let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
        this.param = param
        if (param.birthday) {
            this.yearList.forEach(i=> {
                i.list.forEach(j=> {
                    if(param.birthday.slice(0,4) == j.text) {
                        j.on = true
                    }
                })
            })
        }
    },
    mounted() {
        this.box = this.$refs.itemBox
        this.box.scrollTop = 290
    },
    methods: {
        yearFn(prefix, min, max) {
            let obj = {
                text: prefix.substring(prefix.length - 2),
                list: (() => {
                    let arr = []
                    for(let i=0; i < 10;i++) {
                        let obj = {
                            text:prefix.slice(0,3) + i,
                            on: false
                        }
                        if(i>= min && i<=max) {
                            obj.activate =true
                        } else {
                            obj.activate =false
                        }
                        arr.push(obj)
                    }
                    return arr
                })()
            }
            return obj
        },
        goToUrl() {
            let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
            param.birthday = this.yearVal + '-01-01'
            sessionStorage.setItem('lancer',JSON.stringify(param))
            setTimeout(()=> {
                this.$router.push({path:'/info/height',query: {
                    ...this.$route.query
                }})
            }, 300)
        },
        itemChecked(item) {
            this.yearList.forEach(i=> {
                i.list.forEach(j=> {
                    j.on = false
                })
            })
            item.on = true
            this.yearVal = item.text
            this.goToUrl()
        }
    }
}
</script>