/* eslint-disable no-irregular-whitespace */
<style lang="scss" scoped>
    @keyframes showMatch {
        0%{
            transform: scale(0.1) translateZ(0);
        }
        90%{
            transform: scale(1.1) translateZ(0);
        }
        100% {
            transform: scale(1) translateZ(0);
        }
    }
    .index {
        min-height: 100vh;
        background-image: url('../assets/bg1.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 7px 11px;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        .logo-box {
          padding: 0 0 10px 0;
          text-align: left;
        }
        .logo {
            width: 120px;
        }
        .banner-box {
            // padding-top: 15px;
            text-align: center;
            // height: 200px;
            margin-bottom: 20px;
            .banner {
              margin-top: 26px;
            }
            .banner.show {
                -webkit-animation: showMatch 1.25s ease;
                animation: showMatch 1.25s ease;
                -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }
            .banner-text {
                font-size: 20px;
                color: #fff;
                font-weight: 600;
                padding-top: 10px;
            }
        }
    }
    .btn-box {
        width:230px;
        height: 40px;
        margin: 30px auto 10px;
        .btn {
            color: #4D2600;
            font-size: 15px;
            font-weight: bold;
            background-color: #FFD053;
            border-color: #06c29c;
            border-width: 0px;
            border-style: solid;
            opacity: 1;
            border-radius: 7px;
            text-align: center;
            height: 100%;
            width: 100%;
            padding: 1px 6px;
            font: 600 18px Arial;
            line-height: 40px;
        }
    }
    .footer-box {
        color: #fff;
        text-align: left;
        .title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .text {
            line-height: 20px;
            font-size: 10px;
            white-space: nowrap;
        }
    }
    .shop-box {
      color: #fff;
      text-align: left;
      padding: 20px 6px 0;
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .text {
        font-size: 10px;
        // white-space: nowrap;
      }
    }
    .img_box {
      display: flex;
      margin: 5px 0;
      .van-image {
        margin: 3px;
      }
    }
    .list_item {
      padding-top: 20px;
    }
    .agreement {
      color: #fff !important;
      font-size: 14px;
      text-align: center;
      display: flex;
      justify-content: center;
      line-height: 20px;
      .van-checkbox {
        margin-right: 5px;
      }
      span {
        color: #FFD053;
        font-weight: bold;
      }
    }
</style>
<template>
    <div class="index">
        <div class="logo-box">
            <!-- <van-image class="logo" width="59" height="24" src="../assets/logo.png" fit="contain" /> -->
            <img class="logo" width="200" height="" :src="pageInfo.logo" alt="">
        </div>
        <div class="banner-box">
            <!-- <van-image width="108" v-if="!isShow" height="114" src="https://photo.zastatic.com/images/common-cms/pms/20181120/1542702600330_933626.gif" fit="contain" />
            <div class="banner-text" v-if="!isShow">通过智能算法匹配优质异性中……</div> -->
            <van-image class="banner" :class="isShow?'show': ''" v-if="isShow" width="228" height="97" src="https://static.lspace.vip/lancer_images/banner.png" fit="contain" />
        </div>
        <van-image width="295" height="37" src="https://static.lspace.vip/lancer_images/textBanner2.png" fit="contain" />
        <div class="btn-box">
            <div class="btn" @click="goToUrl('')">
                立即报名
            </div>
        </div>
        <div class="agreement" v-if="isChecked"><van-checkbox v-model="checked" checked-color="#FFD053" ></van-checkbox>我已同意<span @click="toUserAgreement">《用户协议》</span></div>
        <div class="footer-box">
          <div class="list_item" v-for="(item,index) of pageInfo.contentLists" :key="index">
            <div class="title">
                {{item.title}}：
            </div>
            <template v-for="(e,i) of item.advPageVals">
              <div class="text" :key="i" v-if="e.type == 1">
                {{e.val}}
              </div>
              <div class="img_box" :key="i" v-else-if="e.type == 2">
                <van-image v-for="(item,index) of e.imgs" :key="index" width="100" :src="item" />
              </div>
            </template>
          </div>
        </div>
        <!-- <div class="shop-box">
          <div class="title">
            门店照片:
          </div>
          <div class="img_box">
            <van-image width="100" src="https://static.lspace.vip/lancer_images/jotc/jotc_companyImage5.jpg" fit="contain" />
            <van-image width="100" src="https://static.lspace.vip/lancer_images/jotc_companyImage2.jpg" fit="contain" />
            <van-image width="100" src="https://static.lspace.vip/lancer_images/jotc/jotc_companyImage3.jpg" fit="contain" />
            <van-image width="100" src="https://static.lspace.vip/lancer_images/jotc/jotc_companyImage4.jpg" fit="contain" />
          </div>
          <div class="text">地址:武汉武昌区汉街国际总部A座26楼（佳偶天成）</div>
        </div> -->
    </div>
</template>

<script>

export default {
    name: 'index',
    components: {
    },
    data() {
        return {
          isShow: false,
          pageInfo: {},
          checked: false,
          isChecked: false
        }
    },
    mounted() {
        this.enterPage()
        setTimeout(()=>{
          this.isShow = true
        }, 100)
    },
    created() {
      let data = Object.assign(this.$route.query,this.GetRequest())
      console.log(data, '$toast$toast')
      if(data.from && data.from.indexOf('tc_') == 0 ) {
        this.isChecked = true
      } else {
        this.checked = true
      }
      if(data && data.sex == 1) {
        this.sex = 1
        this.imgUrl = 'https://static.lspace.vip/lancer_images/bannerSex1.png'
      }
      if(data && data.sex == 2) {
        this.sex = 2
      }
    },
    methods: {
        goToUrl() {
          if(this.checked) {
            this.$router.push({path:'/info/sex',query: {
              ...this.$route.query,
            }})
          } else {
            this.$toast('请确认同意用户协议')
          }
        },
        toUserAgreement() {
          this.$router.push({path:'/userAgreement',query: {
            appName: this.pageInfo.publicAppName
          }})
        }
    }
}
</script>
