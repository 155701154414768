<style lang="scss" scoped>
@import './index.scss';
</style>

<template>
  <div class="bithirdBgImg">
    <div class="inner">
      <img
        class="innerImg"
        :src="require('../../assets/bili/bithirdInner.png')"
        alt=""
      />
    </div>
    <div class="content">
      <div>真正的爱情，是两个灵魂间的相互吸引</div>
      <div>茫茫人海，不偏不倚，恰好是你</div>
    </div>
    <div class="bithirdBtn">
      <div class="applyBtn" @click="toInfoNewSex()">
        <img :src="require('../../assets/bili/bithirdBtn.png')" alt="" />
      </div>
    </div>
    <div class="agreement">
      <label for="">
        <input type="checkbox" v-model="isChecked" /><span>同意</span
        ><span @click="toUserAgreement">《隐私协议》</span></label
      >
      <h5
        v-if="
          show &&
            (pageInfo.tenantId === '6' ||
              pageInfo.tenantId === '78' ||
              pageInfo.tenantId === '44')
        "
      >
        深圳爱渡情感管理有限公司
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChecked: false,
      show: false,
      pageInfo: {},
    }
  },
  created() {
    if (
      window.location.hostname === 'h5.idoemotion.com' &&
      this.$route.query.from === 'kuaishou'
    ) {
      this.show = true
    }
  },
  methods: {
    toInfoNewSex() {
      if (this.isChecked) {
        this.$router.push({
          path: '/info/newSex',
          query: { ...this.$route.query, layout: 'bili' },
        })
      } else {
        this.$toast('请确认同意隐私协议')
      }
    },
    toUserAgreement() {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    },
  },
}
</script>
