<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url(../components/index.scss);
.van-nav-bar {
  background: var(--titleColor);
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  /deep/ .van-nav-bar__text {
    color: #fff;
  }
  /deep/ .van-icon {
    color: #ffffff;
  }
}
.main-box {
  .infoGif {
    width: 100%;
  }
  .main {
    .title {
      padding: 25px 25px 10px 25px;
      font-size: 19px;
      color: #424966;
      font-weight: 600;
      .strong {
        margin-left: 10px;
        color: #ff6b6b;
      }
      .why {
        margin-left: 10px;
      }
      p {
        display: flex;
        align-items: center;
      }
    }
  }
  .phone {
    padding: 25px 40px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    margin-top: 65px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e0e0e0;
    padding: 0 10px;
    margin: 5px 0;
    img {
      width: 22px;
      height: 22px;
    }
  }
  .input {
    flex: 1;
    input {
      padding-left: 5px;
      line-height: 38px;
      width: 100%;
      border: none;
      font-size: 13px;
    }
    /deep/ .van-cell {
      padding-right: 0;
    }
  }
  .btn {
    line-height: 44px;
    height: 44px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    margin: 25px auto 0;
    width: 325px;
    background-image: linear-gradient(135deg, var(--btnBg), var(--rgbaColor));
    box-shadow: 0 5px 20px 0 var(--rgbaColor);
  }
  .footer {
    font-size: 12px;
    color: #889296;
    text-align: center;
    width: 100%;
    padding-top: 200px;
    span {
      color: var(--btnBg);
    }
  }
  .code {
    padding: 25px 25px 10px 25px;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 325px;
  height: 500px;
  padding: 34px 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  color: #333;
  .title {
    margin-bottom: 18px;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }
  .text {
    font-size: 14px;
    line-height: 20px;
  }
  .table {
    margin: 15px 0;
    text-align: center;
    .row {
      display: flex;
      font-size: 10px;
      align-items: center;
      .icon {
        width: 12px;
        height: 12px;
      }
      &:nth-of-type(2n-1) {
        background: #efefef;
      }
      &:first-of-type {
        font-size: 12px;
        color: #fff;
        background: var(--rgbaColor);
      }
      div {
        flex: 1;
        padding: 7px 2px 7px 7px;
      }
    }
  }
  .btn {
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-radius: 6px;
    background-image: linear-gradient(135deg, var(--btnBg), var(--rgbaColor));
    box-shadow: 0 5px 20px 0 var(--rgbaColor);
    text-align: center;
    color: #fff;
  }
}
.code-text {
  font-size: 14px;
  color: #999;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
  span {
    display: inline-block;
  }
  .disabled {
    color: var(--btnBg);
  }
}
</style>
<template>
  <div :class="layoutType">
    <van-nav-bar :style="color"
                 :title="title"
                 :left-text="leftText"
                 :right-text="rightText"
                 :left-arrow="leftArrow"
                 :safe-area-inset-top="true"
                 @click-left="onClickLeft"
                 :border="false" />
    <div class="main-box">
      <img class="infoGif"
           src="../assets/infoGif.gif"
           alt=""
           v-if="isGigShow" />
      <div class="main"
           v-if="!isGigShow">
        <div class="title">
          <p>真棒！</p>
          <p>
            你的恋爱成功率
            <span class="strong">很高</span>
            <van-icon class="why"
                      size="14"
                      color="#e2e2e2"
                      name="question-o"
                      @click="open" />
          </p>
        </div>
        <div class="phone"
             v-if="!isCode">
          <div class="item">
            <div class="label">
              <img class="icon"
                   src="../assets/phone.png"
                   alt="" />
            </div>
            <div class="input">
              <van-field v-model="tel"
                         type="tel"
                         :maxlength="11"
                         placeholder="输入常用手机号查看你的缘分" />
            </div>
          </div>
        </div>
        <div class="code"
             v-if="isCode">
          <van-field v-model="code"
                     type="tel"
                     :maxlength="6"
                     placeholder="请输入验证码" />
          <!-- <van-password-input
                        :value="code"
                        :focused="false"
                        :mask="false"
                        @focus="showKeyboard = true" /> -->
          <!-- <van-number-keyboard
                        v-model="code"
                        :show="showKeyboard"
                        @blur="showKeyboard = false" /> -->
          <div class="code-text">
            请输入6位验证码
            <span @click="countdown(true)"
                  :class="codeDisabled ? '' : 'disabled'">{{ codeText }}</span>
          </div>
        </div>
        <div class="btn"
             @click="sub">{{ btnText ? '完成' : '确定' }}</div>
        <div class="footer">
          已阅读并同意
          <span class="j-deal1"
                @click="toUserAgreement">
            {{ pageInfo.shopName }}服务协议
          </span>
          和
          <span class="j-deal2"
                @click="toUserAgreement">
            个人信息保护政策
          </span>
        </div>
      </div>
    </div>
    <van-overlay :show="show"
                 @click="show = false">
      <div class="wrapper"
           @click.stop>
        <div class="block">
          <div class="title">恋爱成功率是如何计算的？</div>
          <div class="text">
            恋爱成功率根据你的基本资料情况与当前在库异性的数量、恋爱意愿、基本资料、择偶条件等多因子计算得出。
          </div>
          <div class="table">
            <div class="row">
              <div>恋爱成功率等级</div>
              <div class="font-big">说明</div>
            </div>
            <div class="row">
              <div>
                很高（<img class="icon"
                     src="../assets/icon.png"
                     alt="" />你在这里）
              </div>
              <div class="font-big">
                资料与大量的异性匹配，在短时间内你可以成功恋爱
              </div>
            </div>
            <div class="row">
              <div>高</div>
              <div class="font-big">资料与部分异性匹配，通过努力可能脱单</div>
            </div>
            <div class="row">
              <div>中</div>
              <div class="font-big">匹配的异性很少，近期不太容易脱单</div>
            </div>
            <div class="row">
              <div>低</div>
              <div class="font-big">个人基本情况太差，较难成功恋爱</div>
            </div>
          </div>
          <div class="text">
            你的幸福掌握在自己手里，成功恋爱率越高，越应该主动把握机会。
          </div>
          <div class="btn"
               @click="close">
            好的
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  verifyCode,
  H5Login,
  wechatRepair,
  submitTmpInfo,
  sendResult,
} from '@/api/user'
// import {ENV} from '../config.js'
export default {
  data () {
    return {
      title: '姓名',
      leftText: '',
      rightText: '',
      leftArrow: false,
      show: false,
      isGigShow: true,
      tel: '',
      code: '',
      errorInfo: '',
      showKeyboard: true,
      isCode: false,
      btnText: 0,
      codeText: '获取验证码',
      codeDisabled: false,
      isNewLayout: false,
      color: '',
      layoutType: '',
    }
  },
  created () {
    let navData = this.$route.meta
    this.layoutType = this.$route.query.layout
    if (!this.$route.query.layout) {
      this.layoutType = 'defaultColor'
    } else if (this.$route.query.layout === 'layout') {
      this.layoutType = 'mugiColor'
      this.color = '--bgColor:#f34b6f'
    } else {
      this.layoutType = 'biliColor'
      this.color = '--bgColor:#4859dc'
    }

    window.document.body.style.backgroundColor = '#fff'
    this.navDataFn(navData)
    this.enterPage()
    this.pageInfo = this.$store.state.pageInfo
  },
  mounted () {
    setTimeout(() => {
      this.isGigShow = false
    }, 5000)
  },
  watch: {
    '$store.state.pageInfo': function () {
      this.pageInfo = this.$store.state.pageInfo
    },
    $route (nval) {
      this.navDataFn(nval.meta)
    },
    code (nval) {
      if (nval.length === 6) {
        this.sub()
      }
    },
  },
  methods: {
    toUserAgreement () {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    },
    countdown (i) {
      if (this.codeDisabled) return
      if (i) {
        this.getCode()
      }
      this.codeDisabled = true
      this.codeText = `请60s后重试`
      let second = 60
      let timer = null
      timer = setInterval(() => {
        second -= 1
        if (second > 0) {
          this.codeDisabled = true
          this.codeText = `请${second}s后重试`
        } else {
          clearInterval(timer)
          this.codeDisabled = false
          this.codeText = '获取验证码'
        }
      }, 1000)
    },
    open () {
      this.show = true
    },
    close () {
      this.show = false
    },
    navDataFn (data) {
      let navData = data
      this.title = navData.title
      if (navData.back) {
        this.leftArrow = true
        this.leftText = '返回'
      } else {
        this.leftArrow = false
        this.leftText = ''
      }
      if (navData.right) {
        this.rightText = navData.right
      } else {
        this.rightText = ''
      }
      this.$forceUpdate()
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    getCode () {
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (myreg.test(this.tel)) {
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        })
        let lancer = JSON.parse(sessionStorage.getItem('lancer')) || {}
        if (lancer && lancer.workCity[2])
          lancer.workCity = lancer.workCity[2].id.slice(1)
        let p = window.location.href
        let likeMemberId = this.$route.query.likeMemberId
        submitTmpInfo({
          ...lancer,
          ...{ traceInfo: p },
          likeMemberId,
          ...{ phone: this.tel },
        }).then(() => { })
        verifyCode({ phone: this.tel }).then((res) => {
          this.$toast.clear()
          if (res.code == 200) {
            if (res.data.success) {
              this.$toast('验证码发送成功')
              this.btnText = 1
              this.isCode = true
              this.countdown(false)
            } else {
              this.$toast(res.data.message)
            }
          } else {
            this.$toast(res.message)
          }
        })
      } else {
        this.$toast('手机号输入有误')
      }
    },
    sub () {
      // let url = 'https://'+window.location.host

      let lancer = JSON.parse(sessionStorage.getItem('lancer')) || {}
      let isParam = false
      if (
        lancer.sex != undefined &&
        lancer.workCity != undefined &&
        lancer.birthday != undefined &&
        lancer.height != undefined &&
        lancer.education != undefined &&
        lancer.marriage != undefined &&
        lancer.income != undefined
      ) {
        lancer.workCity = lancer.workCity[2].id.slice(1)
        isParam = true
      }
      if (!isParam) {
        this.$toast('资料有误,请重新填写')
        setTimeout(() => {
          this.$router.push({ path: '/info/sex' })
        }, 500)
        return false
      }
      if (this.btnText) {
        let param = {
          phone: this.tel,
          verifyCode: this.code,
          loginType: 3,
        }
        if (lancer.ownRegister) param.ownRegister = lancer.ownRegister
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        })
        let str = new RegExp(/^[(http)(https)]:\/\//)
        H5Login(param).then((res) => {
          if (res.code == 200) {
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('memberId', res.data.userId)
            localStorage.setItem('sex', lancer.sex)
            let getUserId = res.data.userId
            let p = window.location.href
            let likeMemberId = this.$route.query.likeMemberId
            if (this.$route.query.result && this.$route.query.current == 7) {
              wechatRepair({
                ...lancer,
                ...{ userId: res.data.userId },
                ...{ traceInfo: p },
                likeMemberId,
              }).then((res) => {
                if (res.code == 200) {
                  // eslint-disable-next-line no-undef
                  _rlog_youdao.push(["_trackCustom", "landingpage_submitform"]);
                  // eslint-disable-next-line no-undef
                  VAD_EVENT && VAD_EVENT.sendAction('submit', {
                    act: 'submit',
                    name: '表单组件'
                  });
                  // eslint-disable-next-line no-undef
                  _ks_trace.push({
                    event: 'form',
                    convertId: 491502,
                    cb: function () {
                      console.log('Your callback function here!')
                    },
                  })
                  let result = this.$route.query.result
                  sendResult({
                    userId: getUserId,
                    resultId: result.charCodeAt() - 64,
                  })
                  this.$toast.clear()
                  let query = {
                    ...this.$route.query,
                    current: 8,
                  }
                  this.$router.push({
                    path: '/h5',
                    query,
                  })
                  return false
                } else {
                  this.$toast(res.message)
                }
              })
            } else if (!res.data.infoComplete) {
              wechatRepair({
                ...lancer,
                ...{ userId: res.data.userId },
                ...{ traceInfo: p },
                likeMemberId,
              }).then((res) => {
                if (res.code == 200) {
                  // eslint-disable-next-line no-undef
                  _rlog_youdao.push(["_trackCustom", "landingpage_submitform"]);
                  // eslint-disable-next-line no-undef
                  VAD_EVENT && VAD_EVENT.sendAction('submit', {
                    act: 'submit',
                    name: '表单组件'
                  });
                  // eslint-disable-next-line no-undef
                  _ks_trace.push({
                    event: 'form',
                    convertId: 491502,
                    cb: function () {
                      console.log('Your callback function here!')
                    },
                  })
                  this.$toast.clear()
                  console.log(
                    this.$route.query.current && this.$route.query.result
                  )
                  if (this.$route.query.current && this.$route.query.result) {
                    console.log('跳转')
                    let query = {
                      ...this.$route.query,
                      current: 8,
                    }
                    console.log(this.query, query, ...this.$route.query)
                    this.$router.push({
                      path: '/h5',
                      query,
                    })
                    return false
                  }

                  this.$dialog
                    .alert({
                      message: `${this.pageInfo.successText}`,
                      confirmButtonColor: '#FF7C8F',
                    })
                    .then(() => {
                      if (str.test(this.pageInfo.successUrl)) {
                        window.location.href = this.pageInfo.successUrl
                      } else {
                        this.$router.push(this.pageInfo.successUrl)
                      }
                    })
                } else {
                  this.$toast(res.message)
                }
              })
            } else {
              this.$dialog
                .alert({
                  message: `${this.pageInfo.successText}`,
                  confirmButtonColor: '#FF7C8F',
                })
                .then(() => {
                  if (str.test(this.pageInfo.successUrl)) {
                    window.location.href = this.pageInfo.successUrl
                  } else {
                    this.$router.push(this.pageInfo.successUrl)
                  }
                })
              this.$toast.clear()
            }
          } else {
            this.$toast(res.message)
          }
        })
      } else {
        this.getCode()
      }
    },
    GetRequest () {
      let url = location.search //获取url中"?"符后的字串
      let theRequest = new Object()
      // let p = url.indexOf("?")
      if (url.indexOf('?') != -1) {
        let str = url.substr(1)
        let strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
        }
        return theRequest
      } else {
        return false
      }
    },
  },
}
</script>
