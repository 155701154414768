<template>
  <div id="app">
    <router-view v-if="!isInit"></router-view>
  </div>
</template>
<script>
import {smsRecallLogin} from '@/api/user.js'
import {ENV} from '@/config.js'
import vuex from '@/store/index'
export default {
    data() {
      return {
        code: null,
        isInit: false
      }
    },
    created() {
      let data = Object.assign(this.$route.query,this.GetRequest())
      this.code = data.code
      if(this.code) {
        this.goToUrl()
      }
      let param = {
        type: 1
      }
      if(this.$route.name == 'cp') param.type = 2
      if(this.$route.name == 'v1') param.type = 3
      if(data.tenantId) window.tenantId = data.tenantId
      if(data.shopId) window.shopId = data.shopId
      if(data.addressType) {
        window.addressType = data.addressType
        param.addressType = data.addressType
      }
      vuex.dispatch('getPageInfo', param)
      this.isInit = this.$store.state.isInit
    },
    methods: {
      getQueryVariable(variable) {
          let query = window.location.search.substring(1);
          let vars = query.split("&");
          for (let i=0;i<vars.length;i++) {
              let pair = vars[i].split("=");
              if(pair[0] == variable){return pair[1];}
          }
          return(false);
      },
      
      goToUrl() {
        let obj = {
          code: this.code,
          loginType: 3
        }
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        })
        smsRecallLogin(obj).then(res => {
          if(res.code ==200) {
            if(res.data.infoComplete) {
              localStorage.setItem('token',res.data.token)
              localStorage.setItem('memberId',res.data.userId)
              localStorage.setItem('sex',res.data.sex)
              let url
              if(ENV == 'development'){
                url = 'https://h5.lspace.yihot.com/'
              } else {
                url = 'https://h5.lspace.vip/'
              }
                window.location.href = url;
            }
          }else {
            this.$toast(res.message)
          }
          this.$toast.clear()
        })
      }
    },
}
</script>

<style lang="scss">
body {
    background: #f3f5f7;
    min-height: 100vh;
}
p {
    padding: 0;
    margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
</style>
