<style lang="scss" scoped >
    @import './index.scss';
</style>
<template>
     <div class="content">
        <div class="content-text">你的CP正在路上，请耐心等待哦~</div>
        <div class="content-bg"></div>
        <div class="main">
            <img src="https://static.lspace.yihot.com/images/cp/activityBg.png" mode="widthFix" />
            <div class="title">正在为您寻找最佳CP</div>
            <div class="picture" v-if="headPhoto">
                <img class="img" :src="headPhoto"/>
                <div class="h1"></div>
                <div class="h2"></div>
                <div class="h3"></div>
            </div>
            <div class="text">
                已为你检索{{number}}位{{sex == 1 ? '小哥哥': '小姐姐'}}
            </div>
            <div class="text-box">
				<div class="explain-box fillet">
					<div class="title">匹配说明</div>
					<div class="text text-main">
						<div>1.</div>
						<div>7天CP，盲盒交友，为你找到灵魂相契的TA。请关注微信公众号“佳偶天成”，不错过任何一条消息。</div>
					</div>
					<!-- <div class="text">
                    <div>2.</div>
                        <div>匹配成功后，请及时完成打卡任务哦~连续 两天未打卡，双方会自动解除匹配。</div>
					</div> -->
				</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCurrentCp } from '@/api/user.js'
	export default {
		data() {
			return {
				sex: null,
                headPhoto: '',
                status: 1,
                createTime: null,
                number: 0,
                ss: null,
                st: null,
                id: null,
				headerData: {
					title: '七天CP',
					isBlack: true,
					isShadow: true
				},
				isLoading: true,
				isSelect: true
			}
        },
        created() {
            this.enterPage()
            this.sex = localStorage.getItem('sex')
            let num = (Math.floor(Math.random()*5) + 5)* 1000
            getCurrentCp().then(res => {
                if(res&& res.code == 200) {
                    this.headPhoto = res.data.headPhoto
                    this.status = res.data.status
                    this.createTime = res.data.createTime
                    this.initNumber()
                    this.numberPeople()
                    this.st = setTimeout(() => {
                        this.$router.push({
                            path: '/cp/IM',
                            query: {
                                objectId: res.data.objectId
                            }
                        })
                        console.log(num)
                    },num)
                }
            })
        },
        beforeDestroy() {
            this.ss = null
            this.st = null
        },
        methods: {
            numberPeople() {
                this.ss = setInterval(() => {
                    this.initNumber()
                }, 1000)
            },
            initNumber() {
                this.number = 0
                let n = this.deltaT()
                if(n >= 300) {
                    this.number += 600
                    if(n >= 1800) {
                        this.number += 1440
                        if(n >= 3600) {
                            this.number += 60
                            if(n >= 86400){
                                this.number += 276
                                this.number += parseInt(((n-3600)/60)/10)
                            } else {
                                this.number += parseInt(((n-3600)/60)/5)
                            }
                        } else {
                            this.number += parseInt(((n-1800)/60)*2)
                        }
                    } else {
                        this.number += parseInt((n-300)*1)
                    }
                } else {
                    this.number = parseInt(n*2)
                }
            },
            deltaT() {
                let c = parseInt(this.createTime / 1000)
                let t = parseInt(Date.parse(new Date()) / 1000)
                return t - c
            }
        }
	}
</script>
