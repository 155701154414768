<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('./index.scss');
.banner {
  background: url("../assets/banner.png") no-repeat;
  background-size: 100% 100%;
  height: 120px;
  box-shadow: 0 2.5px 10px 0 var(--rgbaColor,rgba(255,124,143,.5));
  .banner_text {
    width: 100%;
    height: 100%;
    // background: url('../assets/banner_text.png') no-repeat;
    // background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 27px;
    .title {
      padding-top: 10px;
    }
  }
  .banner_title {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-size: 14px;
    }
  }
}
.van-nav-bar {
  background: var(--titleColor);
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  /deep/ .van-nav-bar__text {
    color: #fff;
  }
  /deep/ .van-icon {
    color: #ffffff;
  }
}
.main-box {
  padding: 10px;
  .main {
    max-height: 450px;
    min-height: 400px;
    background: #fff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.06);
  }
}
</style>
<template>
  <div :class="layoutType">
    <mugiLayout v-if="isNewLayout"></mugiLayout>
    <template v-else>
      <van-nav-bar
        :title="title"
        :left-text="leftText"
        :right-text="rightText"
        :left-arrow="leftArrow"
        :safe-area-inset-top="true"
        @click-left="onClickLeft"
        @click-right="onClickRight"
        :border="false"
      />
      <div class="banner">
        <div class="banner_text" v-if="step == 1">
          <div class="title">完善资料</div>
          <div class="text">为你匹配最合适的人</div>
        </div>
        <div class="banner_title" v-else>
          <p>({{ step }}/{{ pageInfo.ownConfig == 1 ? "8" : "7" }})</p>
          {{ text[step - 2] }}
        </div>
      </div>
      <div class="main-box">
        <div class="main">
          <router-view></router-view>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ENV } from "../config.js";
import mugiLayout from "@/views/mugi/infoLayout";
export default {
  components: { mugiLayout },
  data() {
    return {
      title: "姓名",
      leftText: "",
      rightText: "",
      leftArrow: false,
      step: 1,
      text: [
        "你的工作地区在那里",
        "你是哪一年出生的？",
        "你的身高",
        "你的学历",
        "你的婚姻状况",
        "你的月收入",
        "是否是本人注册",
      ],
      isNewLayout: false,
      layoutType:''
    };
  },
  created() {
    let navData = this.$route.meta;
    this.isNewLayout = this.$route.query.layout != undefined;
    if(!this.$route.query.layout){
      this.layoutType = 'defaultColor'
    }else if(this.$route.query.layout === 'layout'){
      this.layoutType = 'mugiColor'
    } else {
      this.layoutType = 'biliColor'
    }
    this.step = navData.index;
    this.navDataFn(navData);
  },
  watch: {
    $route(to) {
      this.navDataFn(to.meta);
      this.step = to.meta.index;
    },
  },
  methods: {
    navDataFn(data) {
      let navData = data;
      this.title = navData.title;
      if (navData.back) {
        this.leftArrow = true;
        this.leftText = "返回";
      } else {
        this.leftArrow = false;
        this.leftText = "";
      }
      if (navData.right) {
        this.rightText = navData.right;
      } else {
        this.rightText = "";
      }
      this.$forceUpdate();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      let url;
      if (ENV == "development") {
        url = "https://h5-jotc.yihot.com";
      } else {
        url = "https://h5-jotc.yihot.com";
      }
      window.location.href = url;
    },
  },
};
</script>


