<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.enterPage()
    location.href= 'weixin://dl/business/?t=qHgOswtXC3p'
  }
}
</script>
