// mixin.js
import { enterPage } from "@/api/user.js"
export default {
  // .slice(0, p.indexOf("#/"))
  data () {
    return {
      newParam: {
        page: 2,
        traceInfo: null,
        url: null,
      },
    }
  },
  watch: {
    '$store.state.pageInfo': function () {
      this.pageInfo = this.$store.state.pageInfo
      if (window.location.pathname === '/wb') {
        document.title = '爱盟同城热恋群'
      } else {
        document.title = this.pageInfo.shopName
      }
      if (this.pageInfo.icon) document.querySelector('link[rel="icon"]').href = this.pageInfo.icon
    },
    'this.$store.state.isInit': function () {
      this.isInit = this.$store.state.isInit
    }
  },
  created () {
    if (this.$store) {
      this.pageInfo = this.$store.state.pageInfo
      this.isInit = this.$store.state.isInit
    }
  },
  methods: {
    enterPage () {
      console.log(this, "初始化")
      let from = this.getQueryVariable("from") || "0"
      let p = window.location.href
      console.log(this.newParam, "this.newParam")
      if (this.newParam) {
        this.newParam.traceInfo = from
        this.newParam.url = p
        enterPage(this.newParam).then(() => { })
      }
    },
    getQueryVariable (variable) {
      let query = window.location.search.substring(1)
      let vars = query.split("&")
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=")
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    },
    GetRequest () {
      let url = location.search //获取url中"?"符后的字串
      let theRequest = new Object()
      // let p = url.indexOf("?")
      if (url.indexOf("?") != -1) {
        let str = url.substr(1)
        let strs = str.split("&")
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1])
        }
        return theRequest
      } else {
        return false
      }
    },
  },
}
