export function getSexs() {
	return [
		{ name: '男', value: 0 },
		{ name: '女', value: 1 }
	]
}

export function getDducations() {
	return [
		{ name: '中专', value: 1 },
		{ name: '高中及以下', value: 2 },
		{ name: '大专', value: 3 },
		{ name: '本科', value: 4 },
		{ name: '硕士', value: 5 },
		{ name: '博士', value: 6 },
	]
}

export function getIncomes() {
	return [
		{ name: '3000以下', value: 1 },
		{ name: '3001-5000元', value: 2 },
		{ name: '5000-8000元', value: 3 },
		{ name: '8000-12000元', value: 4 },
		{ name: '12001-20000元', value: 5 },
		{ name: '20001-50000元', value: 6 },
		{ name: '50000元以上', value: 7 },
	]

}

export function getMaritalStatus() {
	return [
		{ name: '未婚', value: 1 },
		{ name: '离异', value: 2 },
		{ name: '丧偶', value: 3 },
	]
}