<style lang="scss" scoped>
@import "./index.scss";
</style>

<template>
  <div class="BgImg">
    <div class="tip">
      <div class="inner">
        <img :src="require('../../assets/challenge/challenge_28.png')"
             alt="" />
      </div>
    </div>
    <div class="content">
      <div>
        年龄25-35，大专以上学历，收入5000+
      </div>
      <div>
        在本地稳定发展，拥有正确的价值观、婚恋观
      </div>
      <div class="inner">
        <img :src="require('../../assets/challenge/content.png')"
             alt="" />
      </div>
      <div class="btn">
        <div class="applyBtn"
             @click="toInfoNewSex()">
          <img :src="require('../../assets/challenge/match.png')"
               alt="" />
        </div>
      </div>
      <div class="agreement">
        <label for="">
          <input type="checkbox"
                 v-model="isChecked" /><span>同意</span><span @click="toUserAgreement">《隐私协议》</span></label>
        <div class="company"
             v-if="companyName">
          <div>{{ companyName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    const hostNameMap = {
      'idoemotion': '深圳爱渡情感管理有限公司',
      'lspace': '爱乐云（深圳）科技有限公司',
      'aileyu': '成都市心动空间科技有限公司',
      'aimenn': '深圳市爱盟信息技术有限公司',
      'aileyun': '爱乐云（北京）科技有限公司',
    }
    return {
      hostNameMap,
      isChecked: false,
      pageInfo: {},
    }
  },
  computed: {
    companyName () {
      return this.hostNameMap[window.location.hostname.split('.').slice(-2, -1)[0]]
    }
  },
  created () {

  },
  methods: {
    toInfoNewSex () {
      if (this.isChecked) {
        this.$router.push({
          path: '/info/newSex',
          query: { ...this.$route.query, layout: 'bili' },
        })
      } else {
        this.$toast('请确认同意隐私协议')
      }
    },
    toUserAgreement () {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    },
  },
}
</script>
