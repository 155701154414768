<template>
  <div class="container">
    <div class="container_content">
      <div class="form" v-for="(option, index) in options" :key="index">
        <div class="form_title required">{{ option.title }}</div>
        <div class="form_content">
          <template v-if="option.list">
            <div
              v-for="(item, index) in option.list"
              :key="index"
              @click="onClick(option.list, index)"
              class="item"
              :class="{ activate: item.activate }"
            >
              {{ item.name }}
            </div>
          </template>
          <template v-else>
            <van-field
              v-model="option.value"
              class="item_input"
              :type="option.type || 'text'"
              :maxlength="option.maxlength"
              :placeholder="option.placeholder"
            />
            <van-field
              v-if="option.key === 'phone'"
              v-model="verifyCode"
              type="digit"
              class="item_input"
              maxlength="6"
              placeholder="请输入验证码"
            >
              <div
                class="snedCode"
                :class="{ snedCodeDisabled: countdownText !== '获取验证码' }"
                slot="button"
                @click="sendCode"
              >
                {{ countdownText }}
              </div>
            </van-field>
          </template>
        </div>
      </div>
    </div>
    <van-button class="btn" :loading="btnLoading" @click="submit"
      >提交</van-button
    >
  </div>
</template>

<script>
import { verifyCode, H5Login, wechatRepair } from "@/api/user.js";
import {
  getSexs,
  getDducations,
  getIncomes,
  getMaritalStatus,
} from "@/lib/registerBaseData";
export default {
  data() {
    return {
      countdownText: "获取验证码",
      verifyCode: "",
      options: [],
      btnLoading: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    upLoadFrom(params) {
      const { phone, verifyCode } = params;
      delete params.phone;
      delete params.verifyCode;
      // this.btnLoading = true;
      this.$toast({
        type: "loading",
      });
      H5Login({ phone, verifyCode, loginType: 3 })
        .then((res) => {
          if (res.code === 200) {
            return wechatRepair({
              ...params,
              userId: res.data.userId,
              traceInfo: window.location.href,
            });
          }
          return Promise.reject(res);
        })
        .then((res) => {
          this.btnLoading = false;
          if (res.code === 200) {
            this.$toast("提交成功");
            this.$emit("success");
          } else {
            return Promise.reject(res);
          }
        })
        .catch((err) => {
          // this.btnLoading = false;
          this.$toast(err.message || "系统错误");
        });
    },
    submit() {
      const list = this.options;
      const params = {};
      const tip = (title) => {
        this.$toast(`请输入${title}`);
      };
      for (const option of list) {
        if (option.list) {
          const item = option.list.find((v) => v.activate);
          if (item) {
            params[option.key] = item.value;
          } else {
            return tip(option.title);
          }
        } else {
          if (option.value) {
            params[option.key] = option.value;
          } else {
            return tip(option.title);
          }
        }
      }
      const phone = this.options.find((v) => v.key === "phone").value;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(phone)) return this.$toast(`请输入正确的手机号码`);
      if (this.verifyCode.length < 6) return this.$toast(`请输入六位验证码`);
      params.verifyCode = this.verifyCode;
      this.upLoadFrom(params);
    },
    sendCode() {
      if (this.countdownText !== "获取验证码") return;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      const option = this.options.find((v) => v.key === "phone");
      if (reg.test(option.value || "")) {
        verifyCode({ phone: option.value }).then((res) => {
          if (res.code === 200) {
            this.$toast(`验证发送成功`);
            this.countdown(60);
          } else {
            this.$toast(res.message);
          }
        });
      } else {
        this.$toast(`请输入正确的手机号码`);
      }
    },
    async countdown(num) {
      this.countdownText = `请${num}s后重试`;
      for (let i = 0; i < 60; i++) {
        await slepp(1000);
        num -= 1;
        this.countdownText = `请${num}s后重试`;
      }
      this.countdownText = `获取验证码`;
      function slepp(timeout) {
        return new Promise((resolve) => {
          setTimeout(resolve, timeout);
        });
      }
    },
    onClick(list, index) {
      list.forEach((v, i) => (v.activate = index === i ? true : false));
    },
    initData() {
      const list = [
        { key: "sex", title: "您的性别", list: getSexs() },
        { key: "education", title: "您的学历", list: getDducations() },
        { key: "income", title: "您的月收入", list: getIncomes() },
        { key: "marriage", title: "您的婚况", list: getMaritalStatus() },
        {
          key: "age",
          type: "digit",
          title: "您的年龄",
          placeholder: "请输入年龄",
          value: "",
        },
        {
          key: "nickName",
          title: "您的称呼",
          placeholder: "请输入称呼",
          value: "",
        },
        {
          key: "phone",
          title: "您的电话",
          placeholder: "请输入电话",
          maxlength: 11,
          type: "digit",
          value: "",
        },
      ];
      list.forEach((option) => {
        option.list && option.list.forEach((v) => (v.activate = false));
      });
      this.options = list;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .container_content {
    max-height: 50vh;
    overflow: auto;
    padding: 0 16px;
  }
  .btn {
    width: 343px;
    height: 44px;
    background: #5cb0fe;
    border-radius: 44px 44px 44px 44px;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 24px;
  }
}
.form {
  color: #2c2c2c;
  .form_title {
    font-size: 16px;
    &.required::after {
      content: "*";
      color: red;
      margin-left: 2px;
    }
  }
  .form_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px 0 8px;
    .item {
      width: 160px;
      height: 26px;
      box-sizing: border-box;
      border: 1px solid #f4516f;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: white;
      margin-bottom: 16px;
      &.activate {
        background-color: #f4516f;
        color: white;
      }
    }
    .item_input {
      box-sizing: border-box;
      height: 40px;
      border: 1px solid #f4516f;
      border-radius: 40px;
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 16px;
    }
    .snedCode {
      color: #2d90ed;
      font-size: 14px;
      position: relative;
      &::after {
        content: "|";
        position: absolute;
        color: #d3d3d3;
        left: -8px;
        top: -1px;
      }
      &.snedCodeDisabled {
        color: #8a8a8e;
      }
    }
  }
}
</style>