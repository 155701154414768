<style lang="scss" scoped>
@import './index.scss';
</style>

<template>
  <div class="bitoBgImg">
    <img class="top" :src="require('../../assets/bili/top.png')" alt="" />
    <div class="tip">
      <div class="inner">
        <img :src="require('../../assets/bili/bitoTip.png')" alt="" />
      </div>
    </div>
    <div class="content">
      <img class="logo" :src="require('../../assets/bili/logo.png')" alt="" />
      <div class="content-inner">
        <div class="inner-box">
          <img :src="require('../../assets/bili/content4.png')" alt="" />
          <img :src="require('../../assets/bili/bitoBtn.png')" alt="" />
        </div>
        <div class="inner-box">
          <img :src="require('../../assets/bili/content3.png')" alt="" />
          <img :src="require('../../assets/bili/bitoBtn.png')" alt="" />
        </div>
        <div class="inner-box">
          <img :src="require('../../assets/bili/content2.png')" alt="" />
          <img :src="require('../../assets/bili/bitoBtn.png')" alt="" />
        </div>
        <div class="inner-box">
          <img :src="require('../../assets/bili/content1.png')" alt="" />
          <img :src="require('../../assets/bili/bitoBtn.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="bitoBtn">
      <div class="applyBtn" @click="toInfoNewSex()">
        <img :src="require('../../assets/bili/bitoMatch.png')" alt="" />
      </div>
    </div>
    <div class="agreement">
      <label for="">
        <input type="checkbox" v-model="isChecked" /><span>同意</span
        ><span @click="toUserAgreement">《隐私协议》</span></label
      >
      <h5
        v-if="
          show &&
            (pageInfo.tenantId === '6' ||
              pageInfo.tenantId === '78' ||
              pageInfo.tenantId === '44')
        "
      >
        深圳爱渡情感管理有限公司
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChecked: false,
      show: false,
      pageInfo: {},
    }
  },
  created() {
    if (
      window.location.hostname === 'h5.idoemotion.com' &&
      this.$route.query.from === 'kuaishou'
    ) {
      this.show = true
    }
  },
  methods: {
    toInfoNewSex() {
      if (this.isChecked) {
        this.$router.push({
          path: '/info/newSex',
          query: { ...this.$route.query, layout: 'bili' },
        })
      } else {
        this.$toast('请确认同意隐私协议')
      }
    },
    toUserAgreement() {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    },
  },
}
</script>
