
<style scoped>
  @import './style/main.css';
  @import './style/animationOnAndOut.css';
  @import './style/makaElementEffect.css';
</style>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<template>
  <div class="maka-canvas">
    <audio ref="audio" src="../../assets/36330079-MP3-128K-FTD.mp3" controls="controls" v-show="false"></audio>
    <div class="maka-pages" id="maka-pages">
      <div class="maka-page maka-page-0" v-show="current == 0">
        <div class="page-bg-0"></div>
        <div class="maka-eleCanvas maka-eleCanvas-0">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5">
            <img src="@/assets/h5/main-bg.png">
          </div>
          <div ele-id="1-1">
            <img src="@/assets/h5/1-img.png">
          </div>
          <div ele-id="1-2">
            <img src="@/assets/h5/2-img.png">
          </div>
          <div ele-id="1-3" @click="next(1)">
            <img src="@/assets/h5/3-img.png">
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-1" v-show="current == 1">
        <div class="page-bg-1"></div>
        <div class="maka-eleCanvas maka-eleCanvas-1">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5"></div>
          <div ele-id="2-1">
            <div class="text">
              Question 1
            </div>
          </div>
          <div ele-id="26">
            <img src="@/assets/h5/small-gitl.png" alt="">
          </div>
          <div ele-id="28">
            你平时容易出汗吗？
          </div>
          <div ele-id="29">
            <img src="@/assets/h5/small-boy.png" alt="">
          </div>
          <div ele-id="30" @click="next(2)">
            非常容易，时常汗流浃背
          </div>
          <div ele-id="31" @click="next(3)">
            非常热会，转凉会好很多
          </div>
          <div ele-id="32" @click="next(4)">
            基本不怎么出汗
          </div>
          <div ele-id="35">
            友情提示：请从上述三个答案中选一个你第一直觉想选的答案。
          </div>
          <div ele-id="36">
            <img src="@/assets/h5/food/octopus.png">
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-2" v-show="current == 2">
        <div class="page-bg-2"></div>
        <div class="maka-eleCanvas maka-eleCanvas-2">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5"></div>
          <div ele-id="2-1">
            <div class="text">
              Question 2
            </div>
          </div>
          <div ele-id="26">
            <img src="@/assets/h5/small-gitl.png" alt="">
          </div>
          <div ele-id="28">
            以下三种颜色中，你最喜欢哪一种？
          </div>
          <div ele-id="29">
            <img src="@/assets/h5/small-boy.png" alt="">
          </div>
          <div ele-id="30" @click="next(3)">
            红色
          </div>
          <div ele-id="31" @click="next(4)">
            黄色
          </div>
          <div ele-id="32" @click="next(5)">
            白色
          </div>
          <div ele-id="49">
            <img src="@/assets/h5/food/hotPot.png">
          </div>
          <div ele-id="50">
            <img src="@/assets/h5/food/steak1.png" alt="">
          </div>
          <div ele-id="51">
            <img src="@/assets/h5/text-happy.png" alt="">
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-3" v-show="current == 3">
        <div class="page-bg-3"></div>
        <div class="maka-eleCanvas maka-eleCanvas-3">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5"></div>
          <div ele-id="2-1">
            <div class="text">
              Question 3
            </div>
          </div>
          <div ele-id="26">
            <img src="@/assets/h5/small-gitl.png" alt="">
          </div>
          <div ele-id="28">
            你平时喜欢喝水吗？
          </div>
          <div ele-id="29">
            <img src="@/assets/h5/small-boy.png" alt="">
          </div>
          <div ele-id="30" @click="next(4)">
            非常喜欢，所以经常喝水
          </div>
          <div ele-id="31" @click="next(5)">
            不怎么喝水，反正也不渴
          </div>
          <div ele-id="32" @click="next(4)">
            更习惯喝饮料解渴
          </div>
          <div ele-id="35">
            友情提示：请从上述三个答案中选一个你第一直觉想选的答案。
          </div>
          <div ele-id="36">
            <img src="@/assets/h5/food/octopus.png">
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-4" v-show="current == 4">
        <div class="page-bg-4"></div>
        <div class="maka-eleCanvas maka-eleCanvas-4">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5"></div>
          <div ele-id="2-1">
            <div class="text">
              Question 4
            </div>
          </div>
          <div ele-id="26">
            <img src="@/assets/h5/small-gitl.png" alt="">
          </div>
          <div ele-id="28">
            对于人们日常化妆的行为，你有什么看法？
          </div>
          <div ele-id="29">
            <img src="@/assets/h5/small-boy.png" alt="">
          </div>
          <div ele-id="30" @click="next(5)">
            不喜欢，素颜看起来更自然
          </div>
          <div ele-id="31" @click="next(6, 'A')">
            正式场合可以化妆表示尊重
          </div>
          <div ele-id="32" @click="next(6, 'B')">
            精致的妆容会让人赏心悦目
          </div>
          <div ele-id="82">
            <img src="@/assets/h5/food/milkyTea.png">
          </div>
          <div ele-id="83">
            <img src="@/assets/h5/food/cray1.png">
          </div>
          <div ele-id="84">
            <div>
              <img src="@/assets/h5/peachHeart.png">
            </div>
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-5" v-show="current == 5">
        <div class="page-bg-5"></div>
        <div class="maka-eleCanvas maka-eleCanvas-5">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5"></div>
          <div ele-id="2-1">
            <div class="text">
              Question 5
            </div>
          </div>
          <div ele-id="26">
            <img src="@/assets/h5/small-gitl.png">
          </div>
          <div ele-id="28">
            你喜欢晒太阳或者日光浴吗？
          </div>
          <div ele-id="29">
            <img src="@/assets/h5/small-boy.png">
          </div>
          <div ele-id="30" @click="next(6, 'C')">
            非常喜欢，感觉温暖舒服
          </div>
          <div ele-id="31" @click="next(6, 'D')">
            可以晒，但之前要涂防晒霜
          </div>
          <div ele-id="32" @click="next(6, 'E')">
            不喜欢，不出太阳就最好了
          </div>
          <div ele-id="98">
            <img src="@/assets/h5/food/hotPot.png">
          </div>
          <div ele-id="99">
            <img src="@/assets/h5/food/milkyTea1.png" >
          </div>
          <div ele-id="100">
            <img src="@/assets/h5/food/steak.png">
          </div>
        </div>
      </div>
      <div class="maka-page maka-page-5" v-show="current == 6">
        <div class="page-bg-5"></div>
        <div class="maka-eleCanvas maka-eleCanvas-5">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5"></div>
          <div ele-id="201">
            恭喜你，完成测试！
          </div>
          <div ele-id="202">
            <p>完善资料，解锁你的专属报告</p>
            <p>专属报告将会告诉你：</p>
            <p>你的性格特征会遇到怎样的爱情？</p>
            <p>你的食物属性对应的对味情侣是谁？</p>
            <p>性格中的弱势项需要在爱情中注意什么？</p>
            <p>......</p>
          </div>
          <div ele-id="203" @click="next(7)">
            去解锁
          </div>
        </div>
      </div>

      <div class="maka-page maka-page-6" v-show="current == 8">
        <div class="page-bg-5"></div>
        <div class="maka-eleCanvas maka-eleCanvas-5">
          <div ele-id="3"></div>
          <div ele-id="4"></div>
          <div ele-id="5"></div>
          <div ele-id="8-1">
            <img :src="getAnswer.img1"/>
            <p>{{getAnswer.title}}</p>
          </div>
          <div ele-id="8-2">
             <img :src="getAnswer.img"/>
             <p>{{getAnswer.des}}</p>
          </div>
        </div>
      </div>

      <!-- <div class="maka-page maka-page-6" v-show="current == 6">
        <div class="page-bg-6"></div>
        <div class="maka-eleCanvas maka-eleCanvas-6">
          <div ele-id="101">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/9891cde1fafdc89759c43dffe8f9ff63.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="102">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/99b4c0b0eccb475458e76027f88d65a1.png?x-oss-process=image/auto-orient,1" >
            </div>
          </div>
          <div ele-id="103">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/7baa8a05e4ff6d03484dbd405a6b8021.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="104">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/c0f381e4a56acd5a92c521ebcce98ac5.png?x-oss-process=image/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="105">
            <div class="text">
              Question 6
            </div>
          </div>
          <div ele-id="106">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/06fa7e55f51cfd140f398d4c341545b0.png?x-oss-process=image/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="107">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/c0f381e4a56acd5a92c521ebcce98ac5.png?x-oss-process=image/crop,x_0,y_1,w_341,h_80/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="108">
            <div>
              你是否有向某人表白的经历？
            </div>
          </div>
          <div ele-id="109">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/2366bdaf233cc7c78982b167e6a18e74.png?x-oss-process=image/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="110">
            <div @click="next">有</div>
          </div>
          <div ele-id="111">
            <div @click="next">没有</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="maka-page maka-page-7" v-show="current == 7">
        <div class="page-bg-7"></div>
        <div class="maka-eleCanvas maka-eleCanvas-7">
          <div ele-id="114">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/9891cde1fafdc89759c43dffe8f9ff63.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="115">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/99b4c0b0eccb475458e76027f88d65a1.png?x-oss-process=image/auto-orient,1" >
            </div>
          </div>
          <div ele-id="116">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/7baa8a05e4ff6d03484dbd405a6b8021.png?x-oss-process=image/auto-orient,1">
            </div>
          </div>
          <div ele-id="117">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/06fa7e55f51cfd140f398d4c341545b0.png?x-oss-process=image/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="118">
            <div>
              <img src="https://img1.maka.im/user/4671052/images/c0f381e4a56acd5a92c521ebcce98ac5.png?x-oss-process=image/crop,x_3,y_0,w_332,h_83/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="119">
            <div class="text">分享到朋友圈后<br />点击分享链接立即查看答案哦</div>
          </div>
          <div ele-id="120">
            <div>
              <img src="https://img1.maka.im/materialStore/sucaisheji/xiangce/10/M_84CMRLYH/M_84CMRLYH_v1.png?x-oss-process=image/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="121">
            <div>
              <img src="https://img1.maka.im/materialStore/sucaisheji/shengri/10/M_5B0NA9JY/M_5B0NA9JY_v1.png?x-oss-process=image/auto-orient,1" alt="">
            </div>
          </div>
          <div ele-id="122">
            <div @click="isPopups = true">点我，查看答案</div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="music" :class="isPlay? 'runAnimation':'stopAnimation'" @click="isPlay = !isPlay" ref="audioClick">
      <img src="https://res.maka.im/cdn/maka/release/music_icon.png?32fb85122f139626cd4982d113498ac8" alt="">
    </div>

    <div class="popups" v-if="isPopups">
      <img src="../../assets/popups.png" alt="">
      <div class="popups-text">
        分享到朋友圈即可查看答案
      </div>
      <div class="popups-btn" @click="isPopups = false">
        我知道了
      </div>
    </div>
    
  </div>
</template>
<script>
let wxj = window.wxj;
import {WeChatH5Mandate, WeChatFriendsShare} from '@/common/WXH5.js'
  export default {
    data() {
      return {
        current: 0,
        isPopups: false,
        isInfo: false,
        isPlay: false,
        textList: ['A 桃花运满满', 'B 可能被催婚', 'C 或许会有意外之喜'],
        answerArr:[
          {
            id:'A',
            title:'小龙虾',
            img1:require('@/assets/h5/food/cray1.png'),
            img:require('@/assets/h5/food/cray.png'),
            des:"你就像浸泡在调料中的小龙虾，喜欢沐浴在冰水中，让自己保持干净清爽的感觉。虽然不论是清洁剂还是沐浴露，味道都不会像食物香料那么劲爆，但却依然能够使你散发出吸引某些人的气息。",
          },
          {
            id:'B',
            title:'火锅',
            img1:require('@/assets/h5/food/hotPot1.png'),
            img:require('@/assets/h5/food/hotPot.png'),
            des:"你拥有一些不同的特长，这跟能够容纳众多食材和底料的火锅，有着不少相似之处。同时，你也不喜欢把自己泡在水里太久，跟吃火锅涮一下就会捞上来的操作有着异曲同工之妙。",
          },
          {
            id:'C',
            title:'红烧鱼',
            img1:require('@/assets/h5/food/fish1.png'),
            img:require('@/assets/h5/food/fish.png'),
            des:"你在不同的情况下就会有不同的处事风格，你给人的感觉就像红烧鱼一样，是多种味道的混合激发出的新体验。哪怕你会散发出来的不同气质，但它们依然是融洽的，不会互相矛盾冲突。",
          },
          {
            id:'D',
            title:'牛排',
            img1:require('@/assets/h5/food/steak1.png'),
            img:require('@/assets/h5/food/steak.png'),
            des:"热爱运动的你，浑身上下散发着健康的气息。你就跟顶级牛排一样，有着独特的魅力。只要稍加注意，大家就会轻易地感觉到你的活力，以及运动体格给人的安全感。",
          },
          {
            id:'E',
            title:'奶茶',
            img1:require('@/assets/h5/food/milkyTea1.png'),
            img:require('@/assets/h5/food/milkyTea.png'),
            des:"你有着液体一般流畅的身段，能够通过自己的柔韧性展现出不一样的美。你给人的感觉就像奶茶，不会因为过度热情让人感觉不适，有时甚至会有点冷静，但是深入接触下来又会非常舒服。",
          }
        ],
        getAnswer:{},
      }
    },
    created() {
      console.log(this.$route.query)
      WeChatH5Mandate(window.location.href, ['ready', 'onMenuShareAppMessage', 'onMenuShareTimeline']).then(() => {
        let data = {
          title: '我生命中的那个TA竟然会在这个时候出现！你也来测测吧~',
          desc: '我刚试特别准，你也来测测吧~',
          link: '/lancer/#/h5',
          imgUrl: 'https://img1.maka.im/user/13840054/images/88d4e0170437440093b7ba8296dc60eb.png@23-22-172-172a'
        }
        WeChatFriendsShare(data)
      })
      // this.enterPage()
      if(this.$route.query && this.$route.query.current)  {
        if(this.$route.query.current == 7 && !this.$route.query.result) {
          this.next(this.$route.query.current, this.$route.query.result)
          return false
        }
        if(this.$route.query.current == 8 && this.$route.query.result){
          this.next(this.$route.query.current,this.$route.query.result)
          return false
        }
        this.next(this.$route.query.current)
      }
    },
    mounted() {
      wxj.ready(() => {
        this.$refs.audioClick.click()
      })
    },
    watch: {
      isPlay(nval) {
        if(nval) {
          this.$refs.audio.play()
        } else {
          this.$refs.audio.pause()
        }
      },
      $route:{
        handler(newVal){
            if(newVal.query.current == 8 && newVal.query.result){
              this.getAnswer=this.answerArr.filter((item)=>{
              return newVal.query.result==item.id
            })[0]
          }
        },
        immediate: true
      }
    },
    computed: {
      
    },
    methods: {
      next(current, result) {
        let query = {
          current
        }
        console.log(current, result)
        if(result) query['result'] = result
        if(current == 7) {
          this.$router.push({path:'/info/sex',query: {
            ...this.$route.query,
            ...query
          }})
          return false
        }
        this.$router.push({
          path: `/h5`,
          query
        })
        this.current = current
        this.enterPage()
      }
    }
  }
</script>
