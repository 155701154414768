<style lang="scss" scoped>
    .title {
        font-size: 18px;
        color: #4a4a59;
        text-align: center;
        padding-top: 30px;
    }
    .flex-box {
        margin-top: 36px;
        padding: 0 42px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        .gender {
            width: 100px;
            height: 143px;
            .maleImg,.femaleImg {
                width: 100px;
                height: 100px;
                box-shadow: 0 10px 20px #ddd;
                border-radius: 50%;
            }
            &:hover {
                .maleImg,.femaleImg {
                    -webkit-filter: grayscale(0);
                    filter: gray;
                    filter: grayscale(0);
                }
            }
            .maleImg {
                background: url('../assets/boy.png') no-repeat;
                background-size: 100% 101%;
                -webkit-filter: grayscale(1);
                filter: gray;
                filter: grayscale(1);
            }
            .femaleImg {
                background: url('../assets/girl.png') no-repeat;
                background-size: 100% 101%;
                -webkit-filter: grayscale(1);
                filter: gray;
                filter: grayscale(1);
            }
        }
        p{
            margin: 0;
            color: #8f8f8f;
            font-size: 15px;
            margin-top: 10px;
        }
    }

</style>
<template>
  <div class="sex">
      <div class="title">请问你的性别是</div>
      <div class="flex-box">
          <div class="maleBox gender" @click="goToUrl(0)">
              <div class="maleImg"></div>
              <p>男</p>
          </div>
          <div class="femaleBox gender" @click="goToUrl(1)" >
              <div class="femaleImg"></div>
              <p>女</p>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            param: {}
        }
    },
    created() {
        this.enterPage()
        let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
        this.param = param
    },
    methods: {
        goToUrl(sex) {
            this.param.sex = sex
            sessionStorage.setItem('lancer',JSON.stringify(this.param))
            setTimeout(()=> {
                this.$router.push({path:'/info/region',query: {
                    ...this.$route.query
                }})
            }, 300)
        }
    }
}
</script>