<template>
    <div class="box">
        <div class="tip">
            <img class="img" src="../assets/dui.png" alt="" />
            <div class="label">已成功复制微信公众号：</div>
            <div class="text">{{pageInfo.publicAppName}}</div>
        </div>
        <div class="btn" @click="copy(pageInfo.publicAppName)">点击打开微信</div>
        <img
            class="publicBg"
            :src="pageInfo.publicAppImg"
            alt=""
        />
    </div>
</template>
<script>
import { isWeiXinH5 } from "@/utils/index";
export default {
    data() {
        return {};
    },
    watch: {
      '$store.state.pageInfo': function() {
        this.pageInfo = this.$store.state.pageInfo
      }
    },
    created(){
      this.enterPage()
      this.pageInfo = this.$store.state.pageInfo
    },
    methods: {
        copy(val) {
            if (isWeiXinH5()) {
                window.location.href =this.pageInfo.wecahtUrl
                    // "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkxMDIwMjgyMQ==#wechat_redirect";
                return;
            }
            var oInput = document.createElement("input"); //创建一个隐藏input（重要！）
            oInput.value = val; //赋值
            oInput.setAttribute("readonly", "readonly"); //禁止呼出键盘
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            oInput.className = "oInput";
            oInput.style.display = "none";
            window.location.href = "weixin://";
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
.box {
    background: #fff;
}
.tip {
    padding: 20px 0 0 20px;
    display: flex;
    align-items: center;
    font-size: 15px;
    
    .img {
        height: 24px;
        width: 24px;
        background-color: #ff9199;
        border-radius: 50%;
    }
    .label {
        color: #2c2c2c;
        margin-left: 11px;
    }
    .text {
        color: #ff7c8f;
    }
}
.btn {
    height: 44px;
    width: 334px;
    margin: 25px auto 0;
    font-size: 15px;
    color: white;
    background-color: #ff7c8f;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.publicBg {
    margin-top: 42px;
    width: 100%;
}
</style>