<style lang="scss" scoped>
.pc_box {
  overflow: hidden;
}
.pc_header_box {
  width: 100%;
  // max-height: 380px;
  // background: #ffa8ac;
  // background: url("../../assets/pc_header_box_banner.jpg");
  // background-repeat: no-repeat;
  // height: 250px;
  object-fit: cover;
  .pc_content_box {
    height: 80px;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    .pc_logo {
      width: 150px;
    }
    .pc_official_btn {
      padding: 0 20px;
      height: 36px;
      line-height: 36px;
      background: transparent;
      border: 1px solid #fff;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
    }
  }
}
.pc_content_box {
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  .pc_main_left {
    width: 880px;
    padding: 20px 30px;
  }
  .pc_main_right {
    width: 290px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pc_main_left,
  .pc_main_right {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .pc_title_box {
    font-size: 16px;
    color: #333;
    line-height: 22px;
    .pc_title {
      display: inline-block;
      width: 130px;
      &::before {
        content: "";
        width: 4px;
        position: relative;
        top: 2px;
        display: inline-block;
        margin-right: 4px;
        height: 16px;
        background: #ffa8ac;
      }
    }
    .pc_title_tip {
      line-height: 22px;
      font-size: 14px;
      font-weight: bold;
    }
    .pc_title_sub {
      font-size: 16px;
      span {
        color: #ffa8ac;
      }
    }
  }
  .pc_slider {
    width: 500px;
    position: relative;
    .pc_ruler_box {
      width: 100%;
      position: absolute;
      bottom: 2px;
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      line-height: 14px;
      .pc_ruler {
        width: 10%;
        padding-left: 3px;
        padding-top: 2px;
        background: url("../../assets/rule.png") no-repeat;
        position: relative;
        &:last-child {
          background: none;
          &::before {
            content: "";
            position: absolute;
            display: block;
            width: 0;
            height: 10px;
            border-left: 1px solid #4d4d4d;
            top: 0;
            left: 0;
          }
        }
        &:first-child {
          background: none;
        }
      }
    }
  }
  /deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 40px;
    .el-radio {
      margin: 5px 10px 5px 0;
    }
    .el-radio.is-bordered + .el-radio.is-bordered {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  /deep/ .el-form-item {
    margin-bottom: 15px;
  }
  /deep/ .el-form-item__label {
    font-size: 16px;
    color: #333;
  }
}
.pc_submit_box {
  display: flex;
  font-size: 12px;
  line-height: 17px;
  span {
    padding-left: 5px;
  }
  a {
    color: #ffa8ac;
  }
}
.pc_submit_btn {
  width: 200px;
  border-radius: 4px;
  margin-top: 30px;
  border: none;
  background-image: linear-gradient(
    135deg,
    #ff7c8f,
    rgba($color: #ff7c8f, $alpha: 0.5)
  );
  box-shadow: 0 5px 20px 0 rgba($color: #ff7c8f, $alpha: 0.5);
  color: #fff;
}
.pc_pink {
  color: #ffa8ac;
}
.pc_introduce_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 30px;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: -4px;
    width: 6px;
    height: 6px;
    margin-right: 10px;
    border-radius: 6px;
    background-color: #ffa8ac;
  }
}
.pc_introduce {
  font-size: 14px;
  margin-top: 4px;
  line-height: 20px;
}
.pc_succeed_box {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  padding: 60px 15px 30px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 30px;
  text-align: center;
  .pc_succeed_content {
  }
  p {
    line-height: 60px;
    font-size: 16px;
    color: #333;
  }
}
.pc_icon {
  color: #00ff00;
  font-size: 80px;
  font-weight: bold;
  margin-bottom: 40px;
}
.pc_qrcode {
  width: 200px;
}
</style>

<template>
  <div class="pc_box">
    <!-- <div class="pc_header_box">
      <div class="pc_content_box">
        <img class="pc_logo"
             :src="pageInfo.logo"
             alt="">
      </div>
    </div> -->
    <img class="pc_header_box"
         src="../../assets/pc_header_box_banner.jpg"
         alt=""
         srcset="">
    <div class="pc_content_box"
         v-if="!isSucceed">
      <div class="pc_main_left">
        <div class="pc_title_box">
          <span class="pc_title">编辑征婚资料</span>
          <span class="pc_title_tip">(完整填写将为你推荐更合适的异性)</span>
        </div>
        <el-form style="padding:15px 0"
                 label-position="left"
                 :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="130px"
                 class="demo-ruleForm">
          <el-form-item label="我的性别"
                        prop="name">
            <el-radio-group v-model="ruleForm.sex"
                            size="small">
              <el-radio :label="0"
                        border>男生</el-radio>
              <el-radio :label="1"
                        border>女生</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="我的生日"
                        prop="region">
            <!-- <el-date-picker
              size="small"
              v-model="ruleForm.birthday"
              type="date"
              :default-value="new Date('1990-01-01')"
              placeholder="选择日期">
            </el-date-picker> -->
            <el-cascader size="small"
                         :options="birthdayList"
                         :props="{ expandTrigger: 'hover',label:'text',value:'value' }"
                         @change="birthdayChange"></el-cascader>
          </el-form-item>
          <el-form-item label="我的身高"
                        prop="date1">
            <div class="pc_slider">
              <el-slider ref="slider"
                         :min="120"
                         :max="220"
                         v-model="ruleForm.height"
                         :show-tooltip="true"
                         :format-tooltip="formatTooltip"></el-slider>
              <div class="pc_ruler_box">
                <div class="pc_ruler">130以下</div>
                <div class="pc_ruler">130</div>
                <div class="pc_ruler">140</div>
                <div class="pc_ruler">150</div>
                <div class="pc_ruler">160</div>
                <div class="pc_ruler">170</div>
                <div class="pc_ruler">180</div>
                <div class="pc_ruler">190</div>
                <div class="pc_ruler">200</div>
                <div class="pc_ruler">210以上</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="我的工作地"
                        prop="delivery">
            <el-cascader size="small"
                         v-model="workCityArr"
                         :options="region"
                         :props="{ expandTrigger: 'hover',label:'text',value:'values' }"
                         @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="我的学历"
                        prop="type">
            <el-radio-group v-model="ruleForm.education"
                            size="small">
              <el-radio :label="item.id"
                        border
                        v-for="(item,index) of educationList"
                        :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="我的月收入"
                        prop="resource">
            <el-radio-group v-model="ruleForm.income"
                            size="small">
              <el-radio :label="item.id"
                        border
                        v-for="(item,index) of incomeList"
                        :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="我的婚姻状况"
                        prop="desc">
            <el-radio-group v-model="ruleForm.marriage"
                            size="small">
              <el-radio :label="item.id"
                        border
                        v-for="(item,index) of maritalStatusList"
                        :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="pc_title_box">
          <span class="pc_title">注册账户</span>
          <span class="pc_title_sub">今天已有<span>13685</span>位新会员加入{{pageInfo.shopName}}</span>
        </div>
        <el-form label-position="left"
                 :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 style="padding:15px 0"
                 label-width="130px"
                 class="demo-ruleForm">
          <el-form-item label="我的手机"
                        prop="name">
            <el-input style="width:300px"
                      v-model="phone"
                      size="small"
                      placeholder="请输入你的手机号"></el-input>
            <el-button style="margin-left: 10px"
                       type="primary"
                       :disabled="codeDisabled"
                       :loading="codeBtnLog"
                       size="small"
                       @click="getCode">{{codeText}}</el-button>
          </el-form-item>
          <el-form-item label="验证码"
                        prop="region">
            <el-input style="width:300px"
                      v-model="code"
                      size="small"
                      placeholder="请输入验证码"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="pc_submit_box">
              <el-checkbox v-model="checked"></el-checkbox>
              <span>已阅读和同意<a href="javascript:void(0);">{{pageInfo.shopName}}的服务协议</a>和<a href="javascript:void(0);">个人信息保护政策</a>并同意将本人提供之信息
                <br />由{{pageInfo.shopName}}提供线上/线下服务使用</span>
            </div>
            <el-button class="pc_submit_btn"
                       @click="submitFn">
              同意协议并注册
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="pc_main_right">
        <img src="../../assets/t_register230201.png"
             alt="">
        <!-- <div class="pc_introduce_title">全国首家婚恋商场门店</div>
        <div class="pc_introduce">爱渡是爱乐空间旗下的婚恋平台，在成都奥克斯广场、金牛万达、春熙路百盛开设有三家服务中心。是成都规模最大的连锁婚恋平台。在深圳，武汉，杭州，重庆等地相继设立服务中心。</div> -->
        <!-- <div class="pc_introduce_title">上百万诚意会员</div>
        <div class="pc_introduce">截止2022年3月，{{pageInfo.shopName}}诚意精选会员已经突破百万</div>
        <div class="pc_introduce_title">专业红娘持证上岗</div>
        <div class="pc_introduce">拥有庞大的专业红娘团队，上岗红娘均受过国家正规婚恋心理培训</div> -->
      </div>
    </div>
    <div class="pc_succeed_box"
         v-else>
      <div class="pc_succeed_content">
        <i class="el-icon-circle-check pc_icon"></i>
        <div class="pc_succeed_text">
          <p>您的资料已经提交成功，稍后会有专业红娘老师与您联系。</p>
          <p>{{pageInfo.successText}}</p>
        </div>
      </div>
      <!-- <img src="../../assets/ad_qrcode.jpg" class="pc_qrcode" alt=""> -->
    </div>
  </div>
</template>

<script>
import { region } from '../../assets/area.js'
import { verifyCode, H5Login, wechatRepair } from '@/api/user'

export default {
  data () {
    return {
      region,
      checked: true,
      ruleForm: {
        birthday: '',
        education: '',
        height: 160,
        income: '',
        marriage: '',
        sex: '',
        workCity: ''
      },
      phone: '',
      code: '',
      workCityArr: [],
      rules: {
      },
      educationList: [
        { text: '中专', id: 1 },
        { text: '高中及以下', id: 2 },
        { text: '大专', id: 3 },
        { text: '本科', id: 4 },
        { text: '硕士', id: 5 },
        { text: '博士', id: 6 }
      ],
      incomeList: [
        { text: '3000以下', id: 1 },
        { text: '3001-5000元', id: 2 },
        { text: '5000-8000元', id: 3 },
        { text: '8000-12000元', id: 4 },
        { text: '12001-20000元', id: 5 },
        { text: '20001-50000元', id: 6 },
        { text: '50000元以上', id: 7 }
      ],
      maritalStatusList: [
        { text: '未婚', id: 1 },
        { text: '离异', id: 2 },
        { text: '丧偶', id: 3 }
      ],
      codeText: '获取验证码',
      codeDisabled: false,
      codeBtnLog: false,
      isSucceed: false,
      pageInfo: {}
    }
  },
  mounted () {
    this.pageInfo = this.$store.state.pageInfo
    this.enterPage()
    let arr = [
      this.yearFn('1950', 0, 10),
      this.yearFn('1960', 0, 10),
      this.yearFn('1970', 0, 10),
      this.yearFn('1980', 0, 10),
      this.yearFn('1990', 0, 10),
      this.yearFn('2000', 0, 2)
    ]
    this.birthdayList = arr
    let data = Object.assign(this.$route.query, this.GetRequest())
    setTimeout(() => {
      console.log(this.pageInfo.defaultWorkCity, data.workCity)
      if (data.workCity || this.pageInfo.defaultWorkCity) {
        let workCity = "c" + (data.workCity || this.pageInfo.defaultWorkCity)
        this.region.forEach((e) => {
          e.children.forEach(i => {
            i.children.forEach(j => {
              if (j.values === workCity) {
                this.workCityArr = [e.values, i.values, j.values]
                this.ruleForm.workCity = this.workCityArr[2].slice(1)
              }
            })
          })
        })
      }
    }, 500)

    console.log(this.region)
    console.log(arr, data)
  },
  methods: {
    yearFn (prefix, min, max) {
      let obj = {
        text: prefix.substring(prefix.length - 2) + '后',
        value: prefix.substring(prefix.length - 2),
        children: (() => {
          let arr = []
          for (let i = 0; i < 10; i++) {
            let obj = {
              text: prefix.slice(0, 3) + i + '年',
              value: prefix.slice(0, 3) + i + '-01-01',
              on: false
            }
            if (i >= min && i <= max) {
              obj.activate = true
            } else {
              obj.activate = false
            }
            arr.push(obj)
          }
          return arr
        })()
      }
      return obj
    },
    birthdayChange (val) {
      this.ruleForm.birthday = val[1]
    },
    toOfficial () {
      // window.location.href = 'https://aileyu.com'
    },
    countdown () {
      if (this.codeDisabled) return
      this.codeDisabled = true
      this.codeText = `请60s后重试`
      let second = 60;
      let timer = null;
      timer = setInterval(() => {
        second -= 1;
        if (second > 0) {
          this.codeDisabled = true
          this.codeText = `请${second}s后重试`
        } else {
          clearInterval(timer);
          this.codeDisabled = false
          this.codeText = "获取验证码"
        }
      }, 1000)
    },
    getCode () {
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (myreg.test(this.phone)) {
        this.codeBtnLog = true
        verifyCode({ phone: this.phone }).then(res => {
          this.codeBtnLog = false
          if (res.code == 200) {
            if (res.data.success) {
              this.$message({
                message: '验证码发送成功',
                type: 'success'
              })
              this.countdown()
            } else {
              this.$message({
                message: res.data.message,
                type: 'warning'
              })
            }
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({ message: '手机号输入有误', type: 'warning' })
      }
    },
    formatTooltip (val) {
      let num = val
      if (num < 130) num = '小于130'
      if (num > 210) num = '大于210'
      return num + 'cm'
    },
    handleChange (val) {
      this.ruleForm.workCity = val[2].slice(1)
    },
    submitFn () {
      if (this.ruleForm.sex === '') {
        this.$message({ message: '请选择性别', type: 'warning' })
        return
      }
      if (!this.ruleForm.birthday) {
        this.$message({ message: '请选择生日', type: 'warning' })
        return
      }
      if (!this.ruleForm.height) {
        this.$message({ message: '请选择身高', type: 'warning' })
        return
      }
      if (!this.ruleForm.workCity) {
        this.$message({ message: '请选择工作地', type: 'warning' })
        return
      }
      if (!this.ruleForm.education) {
        this.$message({ message: '请选择学历', type: 'warning' })
        return
      }
      if (!this.ruleForm.income) {
        this.$message({ message: '请选择月收入', type: 'warning' })
        return
      }
      if (!this.ruleForm.marriage) {
        this.$message({ message: '请选择婚况', type: 'warning' })
        return
      }
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(this.phone)) {
        this.$message({ message: '手机号不能为空', type: 'warning' })
        return
      }
      if (!this.code) {
        this.$message({ message: '验证码不能为空', type: 'warning' })
        return
      }
      let param = {
        phone: this.phone,
        verifyCode: this.code,
        loginType: 3
      }
      H5Login(param).then(res => {
        if (res.code == 200) {
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('memberId', res.data.userId)
          localStorage.setItem('sex', this.ruleForm.sex)
          let p = window.location.href
          if (!res.data.infoComplete) {
            wechatRepair({ ...this.ruleForm, ...{ userId: res.data.userId }, ...{ traceInfo: p } }).then(res => {
              if (res.code == 200) {
                this.$alert('您已注册成功', '提示', {
                  confirmButtonText: '确定',
                  callback: () => {
                    this.isSucceed = true
                    // window.location.href = 'https://aileyu.com'
                  }
                });
                // window.location.href = 'https://aileyu.com'
              } else {
                this.$message({ message: res.message, type: 'warning' })
              }
            })
          } else {
            this.$alert('您已注册成功', '提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.isSucceed = true
                // window.location.href = 'https://aileyu.com'
              }
            })
          }
        } else {
          this.$message({ message: res.message, type: 'warning' })
        }
      })
    }
  }
}
</script>

