<style lang="scss" scoped>
  .rests {
    min-height: 100vh;
    // background: rgb(237,246,255);
    img {
      display: block;
    }
    .header_box {
      background: rgb(106,60,132);
      padding: 15px 0;
      img {
        margin-left: 15px;
      }
    }
    .content {
      margin-top: 20px;
      img {
        width: 100%;
      }
    }
    .footer-box {
      color: #000;
      text-align: left;
      padding: 0 20px;
      .title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .text {
        line-height: 20px;
        font-size: 10px;
        white-space: nowrap;
      }
    }
    .btn-box {
      width:230px;
      height: 40px;
      margin: 0 auto 20px;
      .btn {
        position: relative;
        color: #fff;
        font-size: 15px;
        background-color: rgb(232,108,108);
        border-color: #06c29c;
        border-width: 0px;
        border-style: solid;
        opacity: 1;
        border-radius: 7px;
        text-align: center;
        height: 100%;
        width: 100%;
        padding: 1px 6px;
        line-height: 40px;
        font-size: 16px;
      }
    }
    .header_text {
      img {
        width: 80%;
        margin: 20px auto 0;
      }
    }
  }
</style>
<template>
    <div class="rests" :style="'background:'+backgroundColor+';'">
      <div class="header_box">
        <img class="logo" height="35" :src="pageInfo.logo" alt="">
      </div>
      <div class="header_text">
        <!-- 本硕专场 -->
        <img :src="require('@/assets/restsText'+type+'.png')" alt="">
      </div>
      <div class="content">
        <img :src="require('@/assets/rests'+ type +'.png')" alt="">
      </div>
      <div class="btn-box" :style="'margin-top:'+ btnNum +'px;'">
        <div class="btn" @click="goToUrl('')">{{btnText}}</div>
      </div>
      <div class="footer-box">
        <div class="list_item" v-for="(item,index) of pageInfo.contentLists" :key="index">
          <div class="title">
              {{item.title}}：
          </div>
          <template v-for="(e,i) of item.advPageVals">
            <div class="text" :key="i" v-if="e.type == 1">
              {{e.val}}
            </div>
            <div class="img_box" :key="i" v-else-if="e.type == 2">
              <van-image v-for="(item,index) of e.imgs" :key="index" width="100" :src="item" />
            </div>
          </template>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: 'rests',
    components: {
    },
    data() {
        return {
          isShow: false,
          pageInfo: {},
          backgroundColor: 'rgb(237,246,255)',
          type: 1,
          btnNum: 0,
          btnText: '免费参与'
        }
    },
    mounted() {
        this.enterPage()
        setTimeout(()=>{
          this.isShow = true
        }, 100)
    },
    created() {
      let data = Object.assign(this.$route.query,this.GetRequest())

      if(data.img && data.img == 2) {
        this.type = 2
        this.backgroundColor = 'rgb(245,243,248)'
        this.btnNum = -20
        this.btnText = '免费进入专场'
      }
      console.log(data)
    },
    methods: {
        goToUrl() {
            this.$router.push({path:'/info/sex',query: {
                ...this.$route.query
            }})
        }
    }
}
</script>
