<template>
  <div class="bgImg">
    <div class="title center">
      <img src="@/assets/cp/Encounter_encounter.png"
           alt="" />
    </div>
    <div class="sub_title center">
      <img src="@/assets/cp/Encounter_love.png"
           alt="" />
    </div>
    <div class="match">
      <img src="@/assets/cp/Encounter_match.png"
           alt="" />
    </div>
    <div class="heart">
      <img src="@/assets/cp/Encounter_heart.png"
           alt="" />
    </div>
    <div class="btn heartBeat"
         @click="toInfoNewSex()">
      <img src="@/assets/cp/Encounter_btn.png"
           alt="" />
    </div>
    <div class="agreement">
      <label for="">
        <input type="checkbox"
               v-model="isChecked" /><span>同意</span><span class="font-white"
              @click="toUserAgreement">《隐私协议》</span></label>
    </div>
    <div class="companyName"
         v-if="companyName">
      {{companyName }}
      <!-- 深圳爱渡情感管理有限公司 -->

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isChecked: false,
      pageInfo: {},
    }
  },
  computed: {
    companyName () {
      return window.location.hostname.split('.').slice(-2, -1)[0] === 'idoemotion' ? '深圳爱渡情感管理有限公司' : ''
    }
  },
  methods: {
    toInfoNewSex () {
      if (this.isChecked) {
        this.$router.push({
          path: '/info/newSex',
          query: { ...this.$route.query, layout: 'bili' },
        })
      } else {
        this.$toast('请确认同意隐私协议')
      }
    },
    toUserAgreement () {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    }
  }
}
</script>

<style scoped lang="scss">
.font-white {
  color: #eff9f5;
}

.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.bgImg {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/cp/EncounterII_bg.jpg") center center
    no-repeat;
  background-size: cover;
  // background-position-y: 100%;
  display: flex;
  flex-direction: column;

  .title {
    top: 29px;

    img {
      width: 246px;
      height: 81px;
    }
  }

  .sub_title {
    top: 91px;

    img {
      width: 342px;
      height: 73px;
    }
  }

  .match {
    position: absolute;
    bottom: 274px;
    left: 26px;

    img {
      height: 97px;
    }
  }

  .heart {
    position: absolute;
    bottom: 189px;
    right: 31px;

    img {
      height: 59px;
    }
  }

  .btn {
    text-align: center;
    position: relative;
    top: 83%;

    img {
      height: 37px;
    }
  }

  .agreement {
    font-size: 14px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 90%;

    label {
      input {
        vertical-align: middle;
        height: 16px;
        width: 16px;
      }

      span {
        vertical-align: middle;
      }

      span:nth-of-type(1) {
        margin-left: 4px;
        // color: rgb(92, 83, 83);
        color: #eff9f5;
      }
    }
  }

  .companyName {
    font-size: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    color: #eff9f5;
  }
}

.heartBeat {
  -webkit-animation: heartbeat 1s infinite;
  animation: heartbeat 1s infinite;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>