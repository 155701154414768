<style lang="scss" scoped>
    .container {
        padding: 10px 15px;
        .item {
            box-sizing: border-box;
            line-height: 44px;
            height: 44px;
            font-size: 15px;
            border-radius: 5px;
            color: #666978;
            padding-left: 21px;
            border: 1px solid var(--btnBg);
            margin-top: 10px;
            &:first-child {
                margin-top: 0;
            }
        }
        .item.activate {
            border:none;
            background-image: linear-gradient(135deg,var(--btnBg),var(--rgbaColor));
            box-shadow: 0 5px 20px 0 var(--rgbaColor);
            color:#fff;
        }
    }
</style>
<template>
  <div class="container">
      <div class="item " :class="item.activate?'activate':''" v-for="(item,index) of income" :key="index" @click="itemClick(item)">
          {{item.text}}
      </div>
  </div>
</template>

<script>
import { smsRecallLogin, wechatRepair} from '@/api/user.js'
export default {
    data() {
      return {
        income: [{
          text: '3000以下',
          activate: false,
          id: 1
        }, {
          text: '3001-5000元',
          activate: false,
          id: 2
        }, {
          text: '5000-8000元',
          activate: false,
          id: 3
        }, {
          text: '8000-12000元',
          activate: false,
          id: 4
        }, {
          text: '12001-20000元',
          activate: false,
          id: 5
        }, {
          text: '20001-50000元',
          activate: false,
          id: 6
        }, {
          text: '50000元以上',
          activate: false,
          id: 7
        }],
        activateVal: '',
        code: null
      }
    },
    created() {
      let data = Object.assign(this.$route.query,this.GetRequest())
      if(data.tenantId == 6) {
        if(data.shopId == 40 || data.shopId == 36 || data.shopId == 20) {
          this.income = this.income.splice(1)
        }
      }
      this.enterPage()
      let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
      this.param = param
      this.income.forEach(i=> {
        if(param.income == i.id) {
          i.activate = true
        }
      })
      this.code = data.code
    },
    methods: {
        goToUrl() {
          let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
          param.income = this.activateVal
          sessionStorage.setItem('lancer',JSON.stringify(param))
          console.log(this.$route.query, param,this.code)
          if(this.code) {
            let lancer = JSON.parse(sessionStorage.getItem('lancer')) || {}
            let isParam = false
            if(lancer.sex != undefined&&
                lancer.workCity != undefined&&
                lancer.birthday != undefined&&
                lancer.height != undefined&&
                lancer.education != undefined&&
                lancer.marriage != undefined&&
                lancer.income != undefined) {
              lancer.workCity =  lancer.workCity[2].id.slice(1)
              isParam = true
            }
            if(!isParam) {
              this.$toast('资料有误,请重新填写')
              setTimeout(()=> {
                  this.$router.push({path:'/info/sex'})
              }, 500)
              return false
            }
            let obj = {
              code: this.code,
              loginType: 3
            }
            this.$toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration: 0
            })
            smsRecallLogin(obj).then(res => {
              if(res.code ==200) {
                localStorage.setItem('token',res.data.token)
                localStorage.setItem('memberId',res.data.userId)
                localStorage.setItem('sex',lancer.sex)
                let p = window.location.href
                let likeMemberId = this.$route.query.likeMemberId
                console.log(likeMemberId)
                if(!res.data.infoComplete) {
                  wechatRepair({...lancer,...{userId: res.data.userId},...{traceInfo: p.slice(0,p.indexOf('#/'))},likeMemberId}).then(res=> {
                    if(res.code == 200) {
                      this.$toast.clear()
                      this.$router.push('/copy')
                    } else {
                      this.$toast(res.message)
                    }
                  })
                } else {
                  this.$router.push('/copy')
                  this.$toast.clear()
                }
              }else {
                this.$toast(res.message)
              }
            })
          } else {
            if(this.pageInfo.ownConfig && this.pageInfo.ownConfig == 1) {
              setTimeout(()=> {
                this.$router.push({path:'/info/isSelf',query: {
                  ...this.$route.query
                }})
              }, 300)
            } else {
              setTimeout(()=> {
                this.$router.push({path:'/login',query: {
                  ...this.$route.query
                }})
              }, 300)
            }
          }
        },
        itemClick(item) {
          this.income.forEach(item=>{
            item.activate = false
          })
          item.activate = true
          this.activateVal = item.id
          this.goToUrl()
        },
        GetRequest() {
          let url = location.search; //获取url中"?"符后的字串
          let theRequest = new Object();
          // let p = url.indexOf("?")
          if (url.indexOf("?") != -1) {
              let str = url.substr(1);
              let strs = str.split("&");
              for(let i = 0; i < strs.length; i ++) {
                theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
              }
              return theRequest;
          } else {
              return false
          }
        }
    }
}
</script>