<template>
  <div class="bgImg">
    <div class="cp_box">
      <img src="../../assets/match/cp.png"
           alt=""
           class="cp">
      <div class="cp_content">
        <img src="../../assets/match/invite.png"
             alt=""
             class="invite">
        <img src="../../assets/match/match.png"
             alt=""
             class="match">
        <img src="../../assets/match/arrow.png"
             alt=""
             class="arrow">
        <img src="../../assets/match/btn.png"
             alt=""
             class="btn"
             @click="toInfoNewSex()">
        <div class="agreement">
          <label for="">
            <input type="checkbox"
                   v-model="isChecked" /><span>同意</span><span @click="toUserAgreement">《隐私协议》</span></label>
        </div>

      </div>

    </div>
    <div class="pic_box">
      <div class="pic">
        <img src="../../assets/match/pic1.png"
             alt="">
        <img src="../../assets/match/pic2.png"
             alt="">
        <img src="../../assets/match/pic3.png"
             alt="">
        <img src="../../assets/match/pic4.png"
             alt="">
      </div>
    </div>
    <div class="bottom_box">
      <div class="bottom">
        <img src="../../assets/match/left.png"
             alt=""
             class="left">
        <img src="../../assets/match/service.png"
             alt=""
             class="service">
        <img src="../../assets/match/right.png"
             alt=""
             class="right">
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      isChecked: false,
    }
  },
  methods: {
    toInfoNewSex () {
      if (this.isChecked) {
        this.$router.push({
          path: '/info/newSex',
          query: { ...this.$route.query },
        })
      } else {
        this.$toast('请确认同意隐私协议')
      }
    },
    toUserAgreement () {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    }
  }

}
</script>

<style scoped lang="scss">
.bgImg {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/match/bg.png") no-repeat;
  background-size: cover;
  background-position-y: 100%;
  display: flex;
  flex-direction: column;

  .cp_box {
    .cp {
      position: absolute;
      width: 100vw;
    }
    .cp_content {
      .invite {
        position: absolute;
        width: 56%;
        height: 6%;
        top: 30%;
        left: 5%;
      }
      .match {
        position: absolute;
        width: 31%;
        height: 4%;
        top: 36%;
        left: 5%;
      }
      .arrow {
        position: absolute;
        width: 16%;
        height: 7%;
        top: 36%;
        right: 9%;
      }
      .btn {
        position: absolute;
        width: 53%;
        height: 7%;
        top: 42%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .pic_box {
    position: absolute;
    top: 53%;
    .pic {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      img {
        width: 46%;
        height: 19%;
        margin-bottom: 2%;
      }
      img:nth-of-type(odd) {
        margin-right: 2%;
      }
    }
  }
  .bottom_box {
    position: absolute;
    top: 93%;
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;

      .left,
      .right {
        width: 11%;
        height: 5%;
      }
      .service {
        width: 41%;
        height: 5%;
        margin-left: 1%;
        margin-right: 1%;
      }
    }
  }
}
.agreement {
  font-size: 14px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(99% / 2);
  label {
    input {
      vertical-align: middle;
      height: 16px;
      width: 16px;
    }
    span {
      vertical-align: middle;
    }

    span:nth-of-type(1) {
      margin-left: 4px;
      color: rgb(92, 83, 83);
    }
  }
}
</style>