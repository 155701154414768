<template>
  <div class="wrapper">
    <div class="header" v-if="current < 9">
      <p @click="$router.go(-1)">返回</p>
      <div class="progress">
        <div
          class="slide"
          :style="{ width: Math.round((current / pageTotal) * 100) + '%' }"
        ></div>
      </div>
      <p>{{ current }}/{{pageTotal}}</p>
    </div>
    <div class="content" v-if="current < 9">
      <div class="part part1" v-show="current == 1">
        <p class="title">您的性别</p>
        <div class="btn-wrapper">
          <div
            class="btn"
            v-for="(item, index) in sexArr"
            :key="index"
            :class="info.sex == item.value ? 'be-select' : ''"
            @click="
              info.sex = item.value;
              next(2);
            "
          >
            {{ item.text }}
          </div>
        </div>
        <div class="part1-footer">
          <p>我们重视您的信息安全</p>
          <p>该资料仅用作为您寻找匹配的人</p>
          <p>请如实认真填写相关信息</p>
        </div>
      </div>

      <div class="part part2" v-show="current == 2">
        <p class="title">您的工作地区在哪里?</p>
        <div class="content">
          <van-picker
            ref="picker"
            :columns="columns"
            :swipe-duration="500"
            @change="onChange"
          />
          <div class="next-btn" @click="next(3)">
            下一步
          </div>
        </div>
      </div>

      <div class="part part3" v-show="current == 3">
        <p class="title">您是哪一年出生的</p>
        <div class="content">
          <div class="birthday">
            <van-field
              v-model="info.birthday"
              type="number"
              maxlength="4"
              placeholder="请输入出生年份"
            />
            年
          </div>
          <p
            class="tips"
            :style="{ visibility: birthdayValidator ? 'hidden' : 'initial' }"
          >
            <van-icon name="warning-o" color="#00ab9a" />
            <span>请输入1940~{{ new Date().getFullYear() }}之间的数据</span>
          </p>
          <div
            class="next-btn"
            @click="
              validator(4);
              next(4);
            "
          >
            下一步
          </div>
        </div>
      </div>
      <div class="part part4" v-show="current == 4">
        <p class="title">您的身高</p>
        <div class="content">
          <div class="birthday">
            <van-field
              v-model="info.height"
              type="number"
              maxlength="3"
              placeholder="请输入身高"
            />
            cm
          </div>
          <p
            class="tips"
            :style="{ visibility: heightValidator ? 'hidden' : 'initial' }"
          >
            <van-icon name="warning-o" color="#00ab9a" />
            <span>请输入120~250之间</span>
          </p>
          <div
            class="next-btn"
            @click="
              validator(5);
              next(5);
            "
          >
            下一步
          </div>
        </div>
      </div>

      <div class="part part5" v-show="current == 5">
        <p class="title">您的学历</p>
        <div class="btn-wrapper">
          <div
            class="btn"
            v-for="(item, index) in educationArr"
            :key="index"
            :class="info.education == item.value ? 'be-select' : ''"
            @click="
              info.education = item.value;
              next(6);
            "
          >
            {{ item.text }}
          </div>
        </div>
      </div>

      <div class="part part6" v-show="current == 6">
        <p class="title">您的月收入</p>
        <div class="btn-wrapper">
          <div
            class="btn"
            v-for="(item, index) in incomeArr"
            :key="index"
            :class="info.income == item.value ? 'be-select' : ''"
            @click="
              info.income = item.value;
              next(7);
            "
          >
            {{ item.text }}
          </div>
        </div>
      </div>

      <div class="part part7" v-show="current == 7">
        <p class="title">您的婚姻状况</p>
        <div class="btn-wrapper">
          <div
            class="btn"
            v-for="(item, index) in marriageArr"
            :key="index"
            :class="info.marriage == item.value ? 'be-select' : ''"
            @click="
              info.marriage = item.value;
              next(9);
            "
          >
            {{ item.text }}
          </div>
        </div>
      </div>

      <!-- <div class="part part8" v-show="current == 8">
        <p class="title">怎么称呼您?</p>
        <div class="content">
          <div class="birthday">
            <van-field
              v-model="info.name"
              type="text"
              placeholder="请输入您的名字"
            />
          </div>
          <div
            class="next-btn"
            @click="
              next(9);
            "
          >
            下一步
          </div>
        </div>
      </div> -->
    </div>

    <div class="register" v-else>
      <div class="reg-header">
        <img src="../../assets/pickU/submit-title.png" alt="" />
        <p>根据您的信息</p>
        <span>已为您筛选出<strong>200</strong>个匹配对象</span>
      </div>
      <div class="reg-content">
        <van-field
          v-model="phone"
          type="tel"
          :rules="[
            { required: true, message: '请输入手机号' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
          maxlength="11"
          placeholder="请输入手机号"
          clearable
          ref="phoneInput"
        />
        <van-field
          v-model="sms"
          center
          clearable
          type="number"
          maxlength="6"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              color="#000"
              native-type="button"
              @click="sendSms"
              :disabled="codeDisabled"
              >{{ codeText }}</van-button
            >
          </template>
        </van-field>

        <div class="sumbit" @click="sumbit">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { region } from "../../assets/area.js";
import { verifyCode, H5Login, wechatRepair, submitTmpInfo } from "@/api/user";
export default {
  data() {
    return {
      info: {
        sex: Number,
        birthday: "",
        height: "",
        education: Number,
        income: Number,
        marriage: Number,
        // name: "",
        traceInfo: "",
        workCity: "",
      },
      pageTotal:7,
      birthdayValidator: true,
      heightValidator: true,
      current: this.$route.query.current || 1,
      columns: [],
      area: [
        {
          id: "c10115000",
          index: 10,
          text: "湖北",
        },
        {
          id: "c10115001",
          index: 0,
          text: "武汉",
        },
        {
          id: "c10115092",
          index: 0,
          text: "东西湖区",
        },
      ],
      sexArr: [
        { value: 0, text: "女" },
        { value: 1, text: "男" },
      ],
      educationArr: [
        { value: 1, text: "中专" },
        { value: 2, text: "高中及以下 " },
        { value: 3, text: "大专" },
        { value: 4, text: "本科" },
        { value: 5, text: "硕士" },
        { value: 6, text: "博士" },
      ],
      incomeArr: [
        { value: 1, text: "3000元以下" },
        { value: 2, text: "3001-5000元" },
        { value: 3, text: "5001-8000元" },
        { value: 4, text: "8001-12000元" },
        { value: 5, text: "12001-20000元" },
        { value: 6, text: "20001-50000元" },
        { value: 7, text: "50001元以上" },
      ],
      marriageArr: [
        { value: 1, text: "未婚" },
        { value: 2, text: "离异" },
        { value: 3, text: "丧偶" },
      ],
      pageInfo: {},
      phone: "",
      sms: "",
      codeText: "获取验证码",
      codeDisabled: false,
    };
  },
  created() {
    let info = sessionStorage.getItem("info");
    this.columns = region;
    this.enterPage()
    if (info) {
      this.info = JSON.parse(info);
      this.area = this.info.workCity || this.area;
    } else {
      this.$router.push({
        path: `/pickU`,
        current: 1,
      });
    }
  },
  computed: {},
  mounted() {},
  watch: {
    $route(to) {
      this.current = to.query.current || 1;
    },
    columns() {
      let info = sessionStorage.getItem("info")||{};
      let workCity = info.workCity || false;
      if (workCity && this.current != 9 ) {
        this.$refs.picker.setColumnIndex(0, workCity[0].index);
        this.$refs.picker.setColumnIndex(1, workCity[1].index);
        this.$refs.picker.setColumnIndex(2, workCity[2].index);
      }
    },
    "$store.state.pageInfo": function() {
      if (this.current == 9 ) return;
      this.pageInfo = this.$store.state.pageInfo;
      let workCity = this.info.workCity || false;
      if (workCity) {
        this.setColumns(workCity);
      } else {
        this.setColumns(this.pageInfo.defaultWorkCity);
      }
    },
  },
  methods: {
    onChange(picker, value) {
      let index = this.$refs.picker.getIndexes();
      let obj = [];
      this.columns.forEach((item) => {
        if (item.text == value[0]) {
          item.children.forEach((i) => {
            if (i.text == value[1]) {
              i.children.forEach((j) => {
                if (j.text == value[2]) {
                  obj.push({
                    text: item.text,
                    id: item.values,
                    index: index[0],
                  });
                  obj.push({
                    text: i.text,
                    id: i.values,
                    index: index[1],
                  });
                  obj.push({
                    text: j.text,
                    id: j.values,
                    index: index[2],
                  });
                }
              });
            }
          });
        }
      });
      this.area = obj;
    },
    setColumns(val) {
      let newVal = "c" + val;
      this.columns.forEach((v1, i1) => {
        if (v1.children) {
          v1.children.forEach((v2, i2) => {
            if (v2.children) {
              v2.children.forEach((v3, i3) => {
                if (v3.values == newVal) {
                  this.$refs.picker.setColumnIndex(0, i1);
                  this.$refs.picker.setColumnIndex(1, i2);
                  this.$refs.picker.setColumnIndex(2, i3);
                  this.onChange("", [v1.text, v2.text, v3.text]);
                }
              });
            }
          });
        }
      });
    },
    sendSms() {
      let p = window.location.href;
      this.$refs.phoneInput
        .validate()
        .then(() => {
          let likeMemberId = this.$route.query.likeMemberId;
          submitTmpInfo({
            ...this.info,
            ...{ traceInfo: p },
            likeMemberId,
            ...{ phone: this.phone },
            ...{ birthday:this.info.birthday+"-01-01"}
          }).then(() => {});
          verifyCode({ phone: this.phone }).then((res) => {
            if (res.code == 200) {
              if (res.data.success) {
                this.$toast("验证码发送成功");
                this.countdown(false);
              } else {
                this.$toast(res.data.message);
              }
            } else {
              this.$toast(res.message);
            }
          });
        })
        .catch(() => {
        });
    },
    sumbit() {
      let param = {
        phone: this.phone,
        verifyCode: this.sms,
        loginType: 3,
      };
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let str = new RegExp(/^[(http)(https)]:\/\//);
      H5Login(param).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("memberId", res.data.userId);
          localStorage.setItem("sex", this.info.sex);
          let p = window.location.href;
          let likeMemberId = this.$route.query.likeMemberId;
          if (!res.data.infoComplete) {
            wechatRepair({
              ...this.info,
              ...{ userId: res.data.userId },
              ...{ traceInfo: p },
              likeMemberId,
              ...{ birthday:this.info.birthday+"-01-01"}
            }).then((res) => {
              if (res.code == 200) {
                this.$toast.clear();
                this.$dialog
                  .alert({
                    message: `${this.pageInfo.successText}`,
                    confirmButtonColor: "#00ab9a",
                  })
                  .then(() => {
                    if (str.test(this.pageInfo.successUrl)) {
                      window.location.href = this.pageInfo.successUrl;
                    } else {
                      this.$router.push(this.pageInfo.successUrl);
                    }
                  });
              } else {
                this.$toast(res.message);
              }
            });
          } else {
            this.$dialog
              .alert({
                message: `${this.pageInfo.successText}`,
                confirmButtonColor: "#00ab9a",
              })
              .then(() => {
                if (str.test(this.pageInfo.successUrl)) {
                  window.location.href = this.pageInfo.successUrl;
                } else {
                  this.$router.push(this.pageInfo.successUrl);
                }
              });
            this.$toast.clear();
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    countdown(i) {
      if (this.codeDisabled) return;
      if (i) {
        this.getCode();
      }
      this.codeDisabled = true;
      this.codeText = `请60s后重试`;
      let second = 60;
      let timer = null;
      timer = setInterval(() => {
        second -= 1;
        if (second > 0) {
          this.codeDisabled = true;
          this.codeText = `请${second}s后重试`;
        } else {
          clearInterval(timer);
          this.codeDisabled = false;
          this.codeText = "获取验证码";
        }
      }, 1000);
    },
    validator(current) {
      if (current == 4) {
        if (this.info.birthday.length == 4) {
          this.birthdayValidator =
            parseInt(this.info.birthday) > 1940 &&
            parseInt(this.info.birthday) < new Date().getFullYear();
        } else {
          this.birthdayValidator = false;
        }
      }
      if (current == 5) {
        if (this.info.height.length == 3) {
          this.heightValidator =
            parseInt(this.info.height) >= 120 &&
            parseInt(this.info.height) <= 250;
        } else {
          this.heightValidator = false;
        }
      }
    },
    next(current) {
      if (current == 3) {
        this.info.workCity = this.area[this.area.length - 1].id.slice(1);
      }
      sessionStorage.setItem("info", JSON.stringify(this.info));
      if (current == 4 && !this.birthdayValidator) {
        return;
      }
      if (current == 5 && !this.heightValidator) {
        return;
      }
      this.current = current;
      let query = {
        current,
      };
      this.$router.push({
        path: `/pickU`,
        query,
      });
      this.enterPage()
    },
  },
};
</script>
<style lang="scss" scoped>
$primary-color: #81d8cf;
$font-color: #00ab9a;
.wrapper {
  font-size: 16px;
  .header {
    padding-top: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    .progress {
      width: 100%;
      background-color: #fff;
      height: 12px;
      border-radius: 6px;
      overflow: hidden;
      .slide {
        background-color: $primary-color;
        height: 12px;
        border-radius: 6px;
        transition: all 0.5s;
      }
    }
    p {
      flex: 0 0 58px;
      line-height: 18px;
      text-align: center;
    }
  }
  .content {
    .part {
      width: 100%;
      .title {
        font-size: 22px;
        font-weight: bold;
        margin: 60px auto 30px;
        text-align: center;
      }
      .next-btn {
        width: 150px;
        font-size: 18px;
        text-align: center;
        background-color: $primary-color;
        height: 48px;
        border-radius: 5px;
        line-height: 48px;
        margin: 0 auto;
        margin-top: 100px;
      }
      .btn-wrapper {
        .btn.be-select {
          background-color: #000;
          color: #fff;
        }
      }
    }
    .part1,
    .part5,
    .part6,
    .part7 {
      width: 100%;
      .btn-wrapper {
        .btn {
          width: 300px;
          height: 72px;
          background-color: #fff;
          border-radius: 10px;
          text-align: center;
          line-height: 72px;
          margin: 0 auto 40px;
        }
      }
      .part1-footer {
        margin-top: 140px;
        p {
          font-size: 12px;
          text-align: center;
          color: $font-color;
        }
      }
    }
    .part3,
    .part4,
    .part8 {
      .content {
        .birthday {
          width: 150px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #000;
          padding: 10px 20px 10px 0;
          margin: 0 auto;
        }
        /deep/ .van-field {
          background-color: transparent;
        }

        .tips {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $font-color;
          font-size: 12px;
          margin-top: 2px;
          span {
            margin-left: 4px;
          }
        }
      }
    }
    .part5,
    .part6,
    .part7 {
      .btn-wrapper {
        .btn {
          height: 56px;
          line-height: 56px;
          margin: 0 auto 20px;
        }
      }
    }
  }

  .register {
    .reg-header {
      background-color: #d8f6f4;
      height: 200px;
      text-align: center;
      img {
        width: 186px;
        margin-top: 60px;
      }

      p {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
      span {
        strong {
          font-weight: bold;
          font-size: 18px;
          margin: 0 6px;
        }
      }
    }
    .reg-header:before,
    .reg-header:after {
      content: "";
      background-repeat: no-repeat;
      width: 50%;
      height: 200px;
      position: absolute;
      top: 0;
    }
    .reg-header:before {
      background-image: url("../../assets/pickU/right-icon.png");
      background-size: cover;
      right: 0;
    }
    .reg-header:after {
      background-image: url("../../assets/pickU/left-icon.png");
      background-size: contain;
      left: 0;
    }
    .reg-content {
      margin-top: 50px;
      padding: 0 30px;
      .sumbit {
        background-color: #000;
        color: #fff;
        width: 300px;
        height: 56px;
        line-height: 56px;
        font-size: 20px;
        border-radius: 5px;
        text-align: center;
        margin: 50px auto 0;
      }
    }
  }
}
</style>
