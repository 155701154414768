export function isWeiXinH5() {
	// 是否微信内浏览器
	var ua = window.navigator.userAgent.toLowerCase();
	let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
	if (!isWx) {
		return false;
	} else {
		let isWxWork = ua.match(/WxWork/i) == "wxwork";
		if (isWxWork) {
			return false;
		} else {
			return true;
		}
	}
}

/**
 * 
 * @param {*} fun 
 * @param {*} time 
 * @returns 节流
 */
export function throttle(fun, time) {
	let flag = true;
	let lastRunFunction = null;
	return function () {
		if (flag) {
			flag = false;
			fun();
			setTimeout(() => {
				flag = true;
				lastRunFunction && lastRunFunction();//保证最后一次运行
			}, time);
		} else {
			lastRunFunction = fun
		}
	};
}