// 微信h5专用
// #ifdef H5
import { getSdkPermission } from "@/api/user.js";
window.wxj = require("jweixin-module");
let wxj = window.wxj;

export function isWeiXinH5() {
  // 是否微信内浏览器
  var ua = window.navigator.userAgent.toLowerCase();
  let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
  if (!isWx) {
    return false;
  } else {
    let isWxWork = ua.match(/WxWork/i) == "wxwork";
    if (isWxWork) {
      return false;
    } else {
      return true;
    }
  }
}

export function isWxWork() {
  // 是否企业微信内浏览器
  var ua = window.navigator.userAgent.toLowerCase();
  let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
  if (!isWx) {
    return false;
  } else {
    let isWxWork = ua.match(/WxWork/i) == "wxwork";
    if (isWxWork) {
      return true;
    } else {
      return false;
    }
  }
}

export function sdkPermission(jsApiList) {
  let url = window.location.href;
  var u = navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // XXX: 修复iOS版微信HTML5 History兼容性问题
  if (isiOS && window.entryUrl !== undefined) {
    // console.log('来了')
    url = window.entryUrl;
  }
  // console.log(url,'处理后的url')
  return WeChatH5Mandate(url, jsApiList);
}
export function WeChatH5Mandate(url, jsApiList) {
  return new Promise((rs) => {
    getSdkPermission({
      url: url,
    }).then((res) => {
      wxj.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.appid, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名
        jsApiList: jsApiList, // 必填，需要使用的JS接口列表
      });
      rs();
    });
  });
}
/**
 *  WeChatH5Share H5分享内容修改
 *  @param Object
 *  @param obj.title  分享标题
 *  @param obj.desc   分享描述
 *  @param obj.link   分享链接
 *  @param obj.imgUrl 分享图标
 *  @return null
 */
export function WeChatFriendsShare(obj, CallBack) {
  let link = window.location.origin + obj.link;
  // console.log(link, '加载完成')
  wxj.onMenuShareAppMessage({
    title: obj.title, // 分享标题
    desc: obj.desc, // 分享描述
    link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: obj.imgUrl, // 分享图标
    // type: '', // 分享类型,music、video或link，不填默认为link
    // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
    success: () => {
      // 用户点击了分享后执行的回调函数
      CallBack && CallBack();
    },
  });
  wxj.onMenuShareTimeline({
    title: obj.title, // 分享标题
    link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: obj.imgUrl, // 分享图标
    success: () => {
      CallBack && CallBack();
      // 用户点击了分享后执行的回调函数
    },
  });
}
export function WeChatH5Share(obj) {
  // WeChatH5Mandate(url, )
  let link = window.location.origin + obj.link; //公众号appid
  wxj.ready(() => {
    wxj.updateAppMessageShareData({
      title: obj.title,
      desc: obj.desc,
      link: link,
      imgUrl: obj.imgUrl,
      success: () => {
        console.log("分享设置成功");
      },
    });
    wxj.updateTimelineShareData({
      title: obj.title,
      link: link,
      imgUrl: obj.imgUrl,
      success: () => {
        console.log("2分享设置成功");
      },
    });
  });
  wxj.error((res) => {
    console.log(res, "失败");
  });
}

/**
 * 获取h5 code
 * @param {Object} url 重定向url 据对路径
 */
// export function getH5Code(url) {
//   const redirectUrl = encodeURIComponent(`${window.location.origin}${url}`);
//   const weixinUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${h5APPID}`;
//   const toUrl = `${weixinUrl}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
//   window.location.replace(toUrl);
// }
// #endif
