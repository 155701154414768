<style lang="scss" scoped>
    @import './index.scss';
</style>
<template>
  <div>
      <div class="title-box" v-if="detailInfoData">
          <div class="photo-box">
              <van-image class="photo" :src="detailInfoData.defaultPhoto" fit="cover" @click="previewImage('photo', 0, detailInfoData.defaultPhoto)"/>
              <div class="text-box">
                <div class="realName">{{detailInfoData.trueName}}</div>
                <div class="tags">
                    <div v-for="(item, index) of makerTags" :key="index">{{item.tagName}}</div>
                </div>
                <div class="info">
                    <span>{{detailInfoData.age?detailInfoData.age+'岁' : ''}}</span>
                    <span>{{detailInfoData.workCityDesc? '/现居'+detailInfoData.workCityDesc: ''}}</span>
                    <span>{{detailInfoData.occupationDesc? '/'+detailInfoData.occupationDesc: ''}}</span>
                </div>
              </div>
          </div>
          <van-row class="authentication-box" v-if="findAllAuthList">
              <van-col span="8" v-for="(item, index) of AllAuth" :key="index">
                <van-image
                    fit="cover"
                    width="47"
                    height="47"
                    :src="item.img[findAllAuthList[item.key]==1? 1: 0 ]"
                ></van-image>
                <div class="text">{{item.text[findAllAuthList[item.key] ==1? 1: 0 ]}}</div>
              </van-col>
          </van-row>
      </div>
      <div class="content-box">
        <div class="item" v-if="memberCards && memberCards.length > 0">
            <div class="title">心动卡牌</div>
            <div class="card-list">
                <div class="card-item" v-for="(item,index) of memberCards" :key="index">
                    <div class="card-title">
                        <div class="dot"></div>
                        <span>{{item.tagName}}</span>
                    </div>
                    <div class="card-content">
                        <div class="card-text">
                            {{item.cardContent}}
                        </div>
                        <div class="card-imgs card-video">
                            <van-image
                                class="img"
                                fit="cover"
                                :src="i.imgUrl + '?imageView2/0/w/200/h/200'"
                                v-for=" (i,ii) of item.cardImgList"
                                :key="ii"
                                @click="previewImage('card', ii, item)"
                                ></van-image>
                                <my-video :url="j.videoUrl" v-for="(j, jj) of item.cardVideoList" :key="jj"></my-video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item" v-if="memberValuesNot && memberValuesNot.length > 0">
            <div class="title">三观问答</div>
            <div class="item-text" v-for="(item, index) of memberValuesNot" :key="index">
                <div class="label">
                    <div></div>
                    <div>{{item.valuesTitle}}</div>
                </div>
                <div class="text">{{item.valuesAnswer}}</div>
            </div>
        </div>
        <div class="item" v-if="detailInfoData">
            <div class="title">基础信息</div>
            <div class="tags">
                <div class="tag" v-if="detailInfoData.age">{{detailInfoData.age}}岁</div>
                <div class="tag" v-if="detailInfoData.constellationDesc">{{detailInfoData.constellationDesc}}</div>
                <div class="tag" v-if="detailInfoData.height">{{detailInfoData.height}}cm</div>
                <div class="tag" v-if="detailInfoData.marriageDesc">{{detailInfoData.marriageDesc}}</div>
                <div class="tag" v-if="detailInfoData.personalityDesc">性格:{{detailInfoData.personalityDesc}}</div>
                <div class="tag" v-if="detailInfoData.interestDesc">爱好:{{detailInfoData.interestDesc}}</div>
                <div class="tag" v-if="detailInfoData.homeTownDesc">籍贯:{{detailInfoData.homeTownDesc}}</div>
                <div class="tag" v-if="detailInfoData.occupationDesc">{{detailInfoData.occupationDesc}}</div>
                <div class="tag" v-if="detailInfoData.incomeDesc">月薪:{{detailInfoData.incomeDesc}}</div>
                <div class="tag" v-if="detailInfoData.workCityDesc">工作地:{{detailInfoData.workCityDesc}}</div>
                <div class="tag" v-if="detailInfoData.educationDesc">{{detailInfoData.educationDesc}}</div>
                <div class="tag" v-if="detailInfoData.houseDesc">{{detailInfoData.houseDesc}}</div>
                <div class="tag" v-if="detailInfoData.carDesc">{{detailInfoData.carDesc}}</div>
            </div>
        </div>
        <template v-if="detailInfoData">
            <div class="item" v-if="detailInfoData.memberPhotos && detailInfoData.memberPhotos.length > 0">
                <div class="title">个人相册</div>
                <div class="imgs">
                    <van-image
                    class="img"
                    width="98"
                    height="98"
                    fit="cover"
                    :src="item.smallFileName + '?imageView2/0/w/200/h/200'"
                    v-for=" (item,index) of detailInfoData.memberPhotos"
                    @click="previewImage('memberPhotos', index, detailInfoData.memberPhotos)"
                    :key="item.autoId"></van-image>
                </div>
            </div>
        </template>
        
        <div class="item" v-if="detailInfoData && detailInfoData.myHeartTaDTO">
            <div class="title">理想生活</div>
            <div class="item-text">
                <div class="label">
                    <div></div>
                    <div>我的理想型</div>
                </div>
                <div class="text-tags" v-if="detailInfoData.myHeartTaDTO.objectTagList && detailInfoData.myHeartTaDTO.objectTagList.length> 0">
                    <div class="tag" v-for="item of detailInfoData.myHeartTaDTO.objectTagList" :key="item.tagId">{{item.tagName}}</div>
                </div>
                <div class="text">{{detailInfoData.myHeartTaDTO.objectContent}}</div>
            </div>
            <div class="item-text">
                <div class="label">
                    <div></div>
                    <div>如果遇到对的人，我期待什么样的生活</div>
                </div>
                <div class="text-assist">#想与你一起完成的几件事#</div>
                <div class="text-tags">
                    <div class="tag"  v-for="item of detailInfoData.idealLifeTagList" :key="item.id">{{item.tagName}}</div>
                </div>
                <div class="text">{{detailInfoData.idealLife}}</div>
            </div>
        </div>
      </div>
      <div class="footer-box">
          <div class="btn" @click="dislike">
              <van-image class="icon" fit="cover" width="22" height="22" src="https://static.lspace.yihot.com/images/recommend/btnA.png"></van-image>
              错过Ta
          </div>
          <div class="btn" @click="like">
              <van-image class="icon" fit="cover" width="22" height="22" src="https://static.lspace.yihot.com/images/recommend/btnB.png"></van-image>
              想认识
          </div>
      </div>
  </div>
</template>

<script>
import {detailInfo, getMakerTags, getMemberCards, getMemberValuesNotDefault, findAllAuth, recommend} from '@/api/user.js';
import { ImagePreview } from "vant";
import myVideo from '@/components/video.vue'
export default {
    components: {
        myVideo
    },
    data() {
        return {
            authString: null,
            shearObjectId: null,
            detailInfoData: null,
            makerTags: null,
            memberCards: null,
            memberValuesNot: null,
            findAllAuthList: null,
            models: ['detailInfoData','makerTags','memberCards','memberValuesNot','findAllAuthList'],
            AllAuth: [{
                key: 'faceAuthStatus',
                img: ['https://static.lspace.vip/images/my/realName.png','https://static.lspace.vip/images/my/realName2.png'],
                text: ['实名未认证','实名认证']
            },{
                key: 'workAuthStatus',
                img: ['https://static.lspace.vip/images/my/jops.png','https://static.lspace.vip/images/my/jops2.png'],
                text: ['工作未认证', '工作认证']
            },{
                key: 'educateAuthStatus',
                img: ['https://static.lspace.vip/images/my/education.png','https://static.lspace.vip/images/my/education2.png'],
                text: ['学历未认证', '学历认证']
            }],
            shearList: [],
            isShearListEnd: false,
            
        }
    },
    created() {
        this.enterPage()
        let url = window.location.href;
        recommend({url}).then(res => {
            if(res.code ==200 && res.data.length > 0) {
                this.shearList = res.data
                this.init()
            }
        })
        
    },
    methods: {
        dislike() {
            console.log(this.shearList.length)
            if(this.isShearListEnd) {
                this.$dialog.alert({
                    message: '请完善信息获得更多推荐~',
                    confirmButtonColor:'#FF7C8F',
                    confirmButtonText: '完善基本信息'
                }).then(() => {
                    this.$router.push({path:'/info/sex',query: {
                        ...this.$route.query
                    }})
                })
            } else {
                this.$dialog.confirm({
                    message: '真的没感觉? 你可能会永远错过Ta哦~',
                    confirmButtonColor:'#FF7C8F',
                    confirmButtonText: '在考虑一下',
                    cancelButtonText: '错过Ta',
                }).then(() => {
                }).catch(()=>{
                    this.models.forEach(i => {
                        this[i] = null
                    })
                    this.init()
                })
            }
            
        },
        like() {
            this.$dialog.confirm({
                message: '喜欢后，对方需了解你的基本信息和联系方式，以便跟您联系。',
                confirmButtonColor:'#FF7C8F',
                confirmButtonText: '完善基本信息'
            }).then(() => {
                this.$router.push({path:'/info/sex',query: {
                    ...this.$route.query,
                    likeMemberId: this.detailInfoData.memberId
                }})
            }).catch(()=>{})
        },
        init() {
            let param = {
                memberId: this.shearList[0].memberId,
                authString: this.shearList[0].authString
            }
            Promise.all([detailInfo(param), getMakerTags(param), getMemberCards(param), getMemberValuesNotDefault(param), findAllAuth(param)]).then(res => {
                res.forEach((item,index) => {
                    if(item.code == 200 && item.data) {
                        this[this.models[index]] = item.data
                    }
                })
                if(this.shearList.length > 1) {
                    this.shearList.splice(0,1)
                } else {
                    this.isShearListEnd = true
                }
            })
        },
        previewImage(type,index,item) {
            let arr = []
            switch (type) {
                case 'photo':
                    arr.push(item)
                    break;
                case 'card':
                    arr = item.cardImgList.map(i => i.imgUrl)
                    break;
                case 'memberPhotos':
                    arr = item.map(i => i.showFileName)
                    break;
                default:
                    break;
            }
            ImagePreview({
                images: arr,
                startPosition: index,
                closeable: true,
            })
        }
    }
}
</script>

