<template>
  <div class="page">
    <div class="banner"></div>
    <div class="contnet">
      <img
        class="contnet_avatars"
        :src="require('@/assets/ritsu/avatars.png')"
      />
      <div class="contnet_describe">
        <!-- <span class="contnet_num">3256</span> -->
        <div>
          为你匹配到<count-to
            class="contnet_num"
            :startVal="0"
            :endVal="3256"
            :duration="3000"
            separator=""
          ></count-to
          >位同城异性！
        </div>
        <div>完善信息后可见</div>
      </div>
      <div class="slogan">
        <span>{{ city }}征婚相亲 报名免费参加</span>
        <div class="slogan_bg"></div>
      </div>
      <div class="button" @click="clickShowPopup">立刻参加</div>
    </div>
    <img
      ref="reaName"
      class="imgTitle"
      style="margin-top: 18px"
      :src="require('@/assets/ritsu/reaName.png')"
    />
    <img class="members" :src="require('@/assets/ritsu/members.png')" />
    <img
      class="imgTitle"
      style="margin-top: 40px"
      :src="require('@/assets/ritsu/one-on-one.png')"
    />
    <img
      class="serve"
      style="margin-top: 20px"
      :src="require('@/assets/ritsu/serve1.png')"
    />
    <img class="serve" :src="require('@/assets/ritsu/serve2.png')" />
    <img class="serve" :src="require('@/assets/ritsu/serve3.png')" />
    <img class="serve" :src="require('@/assets/ritsu/serve4.png')" />
    <img
      class="imgTitle"
      style="margin-top: 40px"
      :src="require('@/assets/ritsu/activity.png')"
    />
    <img
      class="activityContent"
      :src="require('@/assets/ritsu/activityContent.png')"
    />
    <img
      class="imgTitle"
      style="margin-top: 40px"
      :src="require('@/assets/ritsu/step.png')"
    />
    <img class="stepContent" :src="require('@/assets/ritsu/stepContent.png')" />
    <div class="contnet">
      <img
        class="contnet_avatars"
        :src="require('@/assets/ritsu/avatars.png')"
      />
      <div class="contnet_describe">
        <div>为你匹配到<span class="contnet_num">3256</span>位同城异性！</div>
        <div>完善信息后可见</div>
      </div>
      <div class="slogan">
        <span>{{ city }}征婚相亲 报名免费参加</span>
        <div class="slogan_bg"></div>
      </div>
      <div class="button" @click="clickShowPopup">立刻参加</div>
    </div>
    <div class="floating" :class="showFloating ? 'show' : ''">
      <div class="button" @click="clickShowPopup">立刻参加</div>
    </div>
    <van-popup
      class="popup"
      :overlay="false"
      position="bottom"
      v-model="showPopup"
    >
      <div class="popup_header">
        <div class="ionc_close" @click="showPopup = false"></div>
      </div>
      <component-form @success="showPopup = false"></component-form>
    </van-popup>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import { throttle } from "@/utils/index";
import componentForm from "./components/form.vue";
export default {
  components: {
    countTo,
    componentForm,
  },
  data() {
    return {
      showFloating: false,
      showPopup: false,
    };
  },
  computed: {
    city() {
      return this.$store.state.pageInfo.cityDesc || "";
    },
  },
  mounted() {
    window.addEventListener("scroll", throttle(this.controlFloating(), 300));
  },
  methods: {
    clickShowPopup() {
      this.showPopup = true;
    },
    controlFloating() {
      const elOffsetTop = this.$refs.reaName.offsetTop;
      const windowHeight = document.documentElement.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      return () => {
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        console.log({ windowHeight, elOffsetTop, scrollHeight, scrollTop });
        console.log(Math.abs(scrollTop + windowHeight - scrollHeight));
        if (
          scrollTop >= elOffsetTop - windowHeight + 80 &&
          Math.abs(scrollTop + windowHeight - scrollHeight) > 1
        ) {
          this.showFloating = true;
        } else {
          this.showFloating = false;
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>