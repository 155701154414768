<style lang="scss" scoped>
    .container {
        padding: 10px 15px;
        .item {
            box-sizing: border-box;
            line-height: 44px;
            height: 44px;
            font-size: 15px;
            border-radius: 5px;
            color: #666978;
            padding-left: 21px;
            border: 1px solid var(--btnBg);
            margin-top: 10px;
            &:first-child {
                margin-top: 0;
            }
        }
        .item.activate {
            border:none;
            background-image: linear-gradient(135deg,var(--btnBg),var(--rgbaColor));
            box-shadow: 0 5px 20px 0 var(--rgbaColor);
            color:#fff;
        }
    }
</style>
<template>
  <div class="container">
      <div class="item " :class="item.activate?'activate':''" v-for="(item,index) of educationList" :key="index" @click="itemClick(item)">
          {{item.text}}
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            educationList: [{
                text: '中专',
                activate: false,
                id: 1
            }, {
                text: '高中及以下',
                activate: false,
                id: 2
            }, {
                text: '大专',
                activate: false,
                id: 3
            }, {
                text: '本科',
                activate: false,
                id: 4
            }, {
                text: '硕士',
                activate: false,
                id: 5
            }, {
                text: '博士',
                activate: false,
                id: 6
            }],
            activateVal: ''
        }
    },
    created() {
        this.enterPage()
        let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
        this.param = param
        let query = Object.assign(this.$route.query,this.GetRequest())
        if(query.tenantId == 6) {
          if(query.shopId == 40 || query.shopId == 36 || query.shopId == 20) {
            this.educationList = this.educationList.splice(1)
          }
        }
        this.educationList.forEach(i=> {
            if(param.education == i.id) {
                i.activate = true
            }
        })
    },
    methods: {
        goToUrl() {
            let param = JSON.parse(sessionStorage.getItem('lancer')) || {}
            param.education = this.activateVal
            sessionStorage.setItem('lancer',JSON.stringify(param))
            setTimeout(()=> {
                this.$router.push({path:'/info/maritalStatus',query: {
                    ...this.$route.query
                }})
            }, 300)

        },
        itemClick(item) {
            console.log(item)
            this.educationList.forEach(item=>{
                item.activate = false
            })
            item.activate = true
            this.activateVal = item.id
            this.goToUrl()
        }
    }
}
</script>