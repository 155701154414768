import { render, staticRenderFns } from "./isSelf.vue?vue&type=template&id=72885cca&scoped=true&"
import script from "./isSelf.vue?vue&type=script&lang=js&"
export * from "./isSelf.vue?vue&type=script&lang=js&"
import style0 from "./isSelf.vue?vue&type=style&index=0&id=72885cca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72885cca",
  null
  
)

export default component.exports