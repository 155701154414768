<style lang="scss" scoped>
    @import './index.scss';
    .shop-box {
      color: #fff;
      text-align: left;
      padding: 50px 6px 0;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .text {
        font-size: 10px;
        white-space: nowrap;
      }
    }
    .img_box {
      display: flex;
      margin: 5px 0;
      .van-image {
        margin: 3px;
      }
    }
</style>
<template>
    <div class="cpBgImg">
        <div class="cpBgColor">
            <div class="logo">
                <img :src="require('../../assets/logo.png')" alt="">
            </div>
            <div class="titleImg">
                <img :src="require('@/assets/cp/whIndexTitle.png')" alt="">
            </div>
            <div class="textImg">
                <img :src="require('@/assets/cp/indexText.png')" alt="">
            </div>
            <div class="btn" @click="clickBtn">
                完善资料立即匹配
            </div>
            <div class="shop-box">
              <div class="title">门店照片:</div>
              <div class="img_box">
                <van-image width="130" src="https://static.lspace.vip/lancer_images/jotc_companyImage1.jpg" fit="contain" />
                <van-image width="130" src="https://static.lspace.vip/lancer_images/jotc_companyImage2.jpg" fit="contain" />
              </div>
              <div class="text">地址:武汉武昌区汉街国际总部A座26楼（佳偶天成）</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            branch: null
        }
    },
    created() {
        this.enterPage()
        this.branch = Object.assign(this.$route.query,this.GetRequest()).branch
        console.log(this.branch, 'this.branch')       
    },
    methods: {
        clickBtn() {
            if(this.branch == 1) {
                this.$router.push({path:'/cp/form', query: this.$route.query})
            } else if(this.$route.query.branch == 2){
                this.$router.push({path:'/info/sex', query: this.$route.query})
            } else {
                this.$router.push({path:'/info/sex', query: this.$route.query})
            }
        },
        GetRequest() {
            let url = location.search; //获取url中"?"符后的字串
            let theRequest = new Object();
            // let p = url.indexOf("?")
            if (url.indexOf("?") != -1) {
                let str = url.substr(1);
                let strs = str.split("&");
                for(let i = 0; i < strs.length; i ++) {
                        theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
                }
                return theRequest;
            } else {
                return false
            }
        }
    }
}
</script>