<style scoped lang="scss">
    /deep/ .video-js .vjs-big-play-button {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        font-size: 35px;
        line-height: 45px !important;
    }
    /deep/ .vjs-custom-skin > .video-js .vjs-big-play-button  {
        margin-top: -25px !important;
        margin-left: -25px;
        height: 50px !important;
        line-height: 45px !important;
        border-radius: 50%;
    }
    /deep/ .vjs-custom-skin > .video-js .vjs-control {
        // width: 10px;
    }
    /deep/ .vjs-custom-skin > .video-js .vjs-control-bar .vjs-time-control {
        display: none;
    }
    /deep/ .vjs-custom-skin > .video-js .vjs-progress-control.vjs-control {
        display: none;
    }
    /deep/ .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal {
        display: none;
    }
</style>
<template>
  <div>
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer" 
        :playsinline="true" 
        :options="playerOptions"
        ></video-player>
  </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            playerOptions : {
                autoplay: false, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '4:4', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                    src: this.url //url地址
                }],
                poster: this.url.slice(0,-4)+ '-snapshot.jpg', //你的封面地址
                // width: document.documentElement.clientWidth, //播放器宽度
                notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: false,
                    durationDisplay: false,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true  //全屏按钮
                }
            }
        }
    },
    created() {
        console.log()
    }
}
</script>

