import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import "amfe-flexible/index.js";
import { post, patch, put, get } from "./utils/http";
import VideoPlayer from "vue-video-player";
import "./common/fonts/font.css";
import vueTouch from "kim-vue-touch";
import enterPage from "./mixins/enterPage.js";
import ElementUI from 'element-ui';
import './theme/index.css';
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

Vue.use(VideoPlayer);
Vue.use(vueTouch);
Vue.use(ElementUI);
Vue.prototype.$post = post;
Vue.prototype.$patch = patch;
Vue.prototype.$put = put;
Vue.prototype.$get = get;
// console.log(Vant)
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.mixin(enterPage);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
