<template>
  <div class="bgImg">
    <div class="title">
      <img :src="require('../../assets/bili/biliCity_tongcheng.png')"
           alt="" />
      <img :src="require('../../assets/bili/biliCity_xiehou.png')"
           alt="" />
    </div>
    <div class="phoneContent">
      <div class="phone">
        <img class="phone_img"
             :src="require('../../assets/bili/biliCity_phone.png')"
             alt="" />
        <div class="btn"
             @click="toInfoNewSex()">
          <img :src="require('../../assets/bili/biliCity_btn.png')"
               alt="" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    toInfoNewSex () {
      this.$router.push({
        path: '/info/newSex',
        query: { ...this.$route.query, layout: 'bili' },
      })
    }

  }

}
</script>

<style scoped lang="scss">
.bgImg {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/bili/biliCity_bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  text-align: center;

  .title {
    margin-top: 83px;
    img:first-child {
      height: 50px;
    }
    img:nth-child(2) {
      height: 30px;
    }
  }
  .phoneContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .phone {
      position: relative;
      .phone_img {
        width: 310px;
        height: 400px;
      }
      .btn {
        position: absolute;
        top: 324px;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 254px;
          height: 49px;
        }
      }
    }
  }
}
</style>