import Vue from 'vue'
import Vuex from 'vuex'
import {pageInfo} from '@/api/user.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageInfo: {},
    isInit: false,
  },
  mutations: {
    set_pageInfo: (state, data) => {
      state.pageInfo = data
    },
    set_isInit:(state, data) => {
      state.isInit = data
    }
  },
  actions: {
    getPageInfo({commit}, Param) {
      pageInfo(Param).then(res => {
        (res.data.contentLists || []).forEach(e => {
          e.advPageVals.forEach(i => {
            if(i.type == 2) {
              i.imgs = i.val.split(',')
            }
          })
        })
        commit('set_pageInfo', res.data)
        commit('set_isInit', true)
      })
    }
  },
  modules: {
  }
})
