<style lang="scss" scoped>
@import './index.scss';
</style>

<template>
	<div class="mugiBgImg">
		<div class="logo">
			<img :src="pageInfo.logo" alt="" class="logoImg" />
		</div>

		<div class="title">
			<div class="inner">
				<img :src="require('../../assets/mugi/title.png')" alt="" />
			</div>
		</div>

		<div class="subTitle">
			<!-- <img :src="require('../../assets/mugi/subTitle.png')" alt="" /> -->
		</div>

		<div class="avatar">
			<img :src="require('../../assets/mugi/avatar.png')" alt="" />
		</div>

		<div class="info">本地单身异性，完善信息后可见</div>

		<div class="btn">
			<div class="applyBtn heartBeat" @click="toInfoNewSex()">
				立即报名
			</div>
		</div>
		<div class="agreement">
			<label for="">
				<input type="checkbox" v-model="isChecked" /><span>同意</span><span @click="toUserAgreement">《隐私协议》</span></label>
		</div>

		<div class="rules">
			<div class="rulesTitle">活动规则：</div>
			<div class="rulesContent">
				<p>本活动是由{{ pageInfo.shopName }}组织的多人线下聊天相亲活动；</p>
				<!-- <p>2.我们会为您匹配同城单身异性</p> -->
			</div>
			<div class="footer" v-if="pageInfo.tenantId == '44'">
				深圳市爱盟信息技术有限公司
				<br />
				<a target="view_window" style="text-decoration: none;color: white;" href="http://beian.miit.gov.cn">
					粤ICP备2022087227号
				</a>
			</div>
			<h5 v-if="show && pageInfo.tenantId === '6'">
				深圳爱渡情感管理有限公司
			</h5>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isChecked: false,
			show: false,
			pageInfo: {},
		}
	},
	created() {
		if (window.location.hostname === 'h5.idoemotion.com')
			return (this.show = true)
	},
	methods: {
		toInfoNewSex() {
			if (this.isChecked) {
				this.$router.push({
					path: '/info/newSex',
					query: { ...this.$route.query, layout: 'layout' },
				})
			} else {
				this.$toast('请确认同意隐私协议')
			}
		},
		toUserAgreement() {
			this.$router.push({
				path: '/userAgreement',
				query: {
					appName: this.pageInfo.shopName,
				},
			})
		},
	},
}
</script>
