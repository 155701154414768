var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[(_vm.current < 9)?_c('div',{staticClass:"header"},[_c('p',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回")]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"slide",style:({ width: Math.round((_vm.current / _vm.pageTotal) * 100) + '%' })})]),_c('p',[_vm._v(_vm._s(_vm.current)+"/"+_vm._s(_vm.pageTotal))])]):_vm._e(),(_vm.current < 9)?_c('div',{staticClass:"content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 1),expression:"current == 1"}],staticClass:"part part1"},[_c('p',{staticClass:"title"},[_vm._v("您的性别")]),_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.sexArr),function(item,index){return _c('div',{key:index,staticClass:"btn",class:_vm.info.sex == item.value ? 'be-select' : '',on:{"click":function($event){_vm.info.sex = item.value;
            _vm.next(2);}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0),_vm._m(0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 2),expression:"current == 2"}],staticClass:"part part2"},[_c('p',{staticClass:"title"},[_vm._v("您的工作地区在哪里?")]),_c('div',{staticClass:"content"},[_c('van-picker',{ref:"picker",attrs:{"columns":_vm.columns,"swipe-duration":500},on:{"change":_vm.onChange}}),_c('div',{staticClass:"next-btn",on:{"click":function($event){return _vm.next(3)}}},[_vm._v(" 下一步 ")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 3),expression:"current == 3"}],staticClass:"part part3"},[_c('p',{staticClass:"title"},[_vm._v("您是哪一年出生的")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"birthday"},[_c('van-field',{attrs:{"type":"number","maxlength":"4","placeholder":"请输入出生年份"},model:{value:(_vm.info.birthday),callback:function ($$v) {_vm.$set(_vm.info, "birthday", $$v)},expression:"info.birthday"}}),_vm._v(" 年 ")],1),_c('p',{staticClass:"tips",style:({ visibility: _vm.birthdayValidator ? 'hidden' : 'initial' })},[_c('van-icon',{attrs:{"name":"warning-o","color":"#00ab9a"}}),_c('span',[_vm._v("请输入1940~"+_vm._s(new Date().getFullYear())+"之间的数据")])],1),_c('div',{staticClass:"next-btn",on:{"click":function($event){_vm.validator(4);
            _vm.next(4);}}},[_vm._v(" 下一步 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 4),expression:"current == 4"}],staticClass:"part part4"},[_c('p',{staticClass:"title"},[_vm._v("您的身高")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"birthday"},[_c('van-field',{attrs:{"type":"number","maxlength":"3","placeholder":"请输入身高"},model:{value:(_vm.info.height),callback:function ($$v) {_vm.$set(_vm.info, "height", $$v)},expression:"info.height"}}),_vm._v(" cm ")],1),_c('p',{staticClass:"tips",style:({ visibility: _vm.heightValidator ? 'hidden' : 'initial' })},[_c('van-icon',{attrs:{"name":"warning-o","color":"#00ab9a"}}),_c('span',[_vm._v("请输入120~250之间")])],1),_c('div',{staticClass:"next-btn",on:{"click":function($event){_vm.validator(5);
            _vm.next(5);}}},[_vm._v(" 下一步 ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 5),expression:"current == 5"}],staticClass:"part part5"},[_c('p',{staticClass:"title"},[_vm._v("您的学历")]),_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.educationArr),function(item,index){return _c('div',{key:index,staticClass:"btn",class:_vm.info.education == item.value ? 'be-select' : '',on:{"click":function($event){_vm.info.education = item.value;
            _vm.next(6);}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 6),expression:"current == 6"}],staticClass:"part part6"},[_c('p',{staticClass:"title"},[_vm._v("您的月收入")]),_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.incomeArr),function(item,index){return _c('div',{key:index,staticClass:"btn",class:_vm.info.income == item.value ? 'be-select' : '',on:{"click":function($event){_vm.info.income = item.value;
            _vm.next(7);}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 7),expression:"current == 7"}],staticClass:"part part7"},[_c('p',{staticClass:"title"},[_vm._v("您的婚姻状况")]),_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.marriageArr),function(item,index){return _c('div',{key:index,staticClass:"btn",class:_vm.info.marriage == item.value ? 'be-select' : '',on:{"click":function($event){_vm.info.marriage = item.value;
            _vm.next(9);}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]):_c('div',{staticClass:"register"},[_vm._m(1),_c('div',{staticClass:"reg-content"},[_c('van-field',{ref:"phoneInput",attrs:{"type":"tel","rules":[
          { required: true, message: '请输入手机号' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' } ],"maxlength":"11","placeholder":"请输入手机号","clearable":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('van-field',{attrs:{"center":"","clearable":"","type":"number","maxlength":"6","placeholder":"请输入短信验证码"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{attrs:{"size":"small","type":"primary","color":"#000","native-type":"button","disabled":_vm.codeDisabled},on:{"click":_vm.sendSms}},[_vm._v(_vm._s(_vm.codeText))])]},proxy:true}]),model:{value:(_vm.sms),callback:function ($$v) {_vm.sms=$$v},expression:"sms"}}),_c('div',{staticClass:"sumbit",on:{"click":_vm.sumbit}},[_vm._v("确定")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"part1-footer"},[_c('p',[_vm._v("我们重视您的信息安全")]),_c('p',[_vm._v("该资料仅用作为您寻找匹配的人")]),_c('p',[_vm._v("请如实认真填写相关信息")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reg-header"},[_c('img',{attrs:{"src":require("../../assets/pickU/submit-title.png"),"alt":""}}),_c('p',[_vm._v("根据您的信息")]),_c('span',[_vm._v("已为您筛选出"),_c('strong',[_vm._v("200")]),_vm._v("个匹配对象")])])}]

export { render, staticRenderFns }