<template>
  <div>
    <van-nav-bar title="隐私协议" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="main">
      
      <div class="text">
        <span class="h1">{{appConfig.appName}}隐私协议</span>
        <span class="p">
  欢迎使用 {{appConfig.appName}}（www.aileyu.com，以下称“ {{appConfig.appName}}”或“本平台”）。本服务协议（以下简称“协议”）是您与{{appConfig.appName}}的运营商成都市心动空间科技有限公司（以下简称“心动空间公司”、“我司”或“我们”）之间关于您成为{{appConfig.appName}}会员且使用我司提供的在线婚介服务所订立的协议。
  </span>
        <span class="p">
  本协议适用于{{appConfig.appName}}下所有的服务、资料及信息，而且也适用于我们和您之间通过电子邮件或电话、传真等任何方式进行的任何沟通。请在使用本平台的服务之前认真阅读下述条款，特别是<span class="bold">本协议含有免除或者限制我们责任的条款（该等条款通常含有“不负任何责任”、“无义务”等词汇），和其它限制会员权利的条款（该等条款通常含有“不得”等词汇）</span>。除非您接受本《协议》所有条款，否则您无权注册、登录或使用本协议所涉相关服务。您的注册、登录、使用等行为将视为对本《协议》的接受，并同意接受本《协议》各项条款的约束。为免歧义，本协议中的“注册”、“登录”或“使用”是指您注册成为本平台会员并使用本平台提供的任何服务，或者您未注册成为本平台会员，但通过阅读、浏览、复制或以其他方式使用本平台上提供的任何信息以及以任何方式使用本平台提供之各类服务。 为免歧义，除非本协议中另有说明或根据本协议上下文理解另有含义，“会员”指免费会员和付费会员。
        </span>
        <span class="p">
          您对本协议的接受即受全部条款的约束，包括接受我司对任一服务条款随时所做的任何修改。<span class="bold">本《协议》可由我司随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，会员可以查阅最新版协议条款。在我司修改《协议》条款后，如果会员不接受修改后的条款，请立即停止使用{{appConfig.appName}}提供的服务，会员继续使用{{appConfig.appName}}提供的服务将被视为已接受了修改后的协议。</span>
        </span>
        <span class="h2">1.信息</span>
        <span class="p">
          在满足上述资格的情况下，您可以注册成为本平台的会员。要创建并维持会员账户，您必须向我们提供正确有效的信息，承诺以其真实身份注册成为{{appConfig.appName}}的会员，并保证所提供的个人身份资料，信息真实、完整、有效，依据法律规定和本协议约定对所提供的信息承担相应的法律责任。
        </span>
        <span class="p"><span class="bold">a.身份认证。</span>您授权心动空间公司（直接或通过第三方）进行我们认为验证您的身份所必须的任何查询。为更好地满足您的服务要求，这可能包括要求您提供更多信息或文件。在您使用{{appConfig.appName}}提供的部分服务时，我们有权直接或委托第三方通过移动电话号码、身份证实名认证或其他有效途径对您进行身份信息验证，如您不接受身份信息验证，{{appConfig.appName}}有权拒绝为您提供相关服务。</span>
        <span class="p"><span class="bold">b.信息授权。</span>任何您传输至本平台或在本平台上公布的资料都将视为非机密和非专有的。<span class="bold">您的上述传输行为表明您已同意我们对公布及以其他方式使用该类资料将不承担任何责任。</span>同时，您也授权我们和我们指定的人员有权复制、披露、传播、整合或以其他方式使用该类数据、图像、声音、文本以及上述资料包含的其他内容，以用作商业或非商业目的，并且您同意我们有权以上述方式无偿使用该信息。</span>
        <span class="p"><span class="bold">c.信息查询和更新。您可以通过平台的我的资料页查询您的个人信息。</span>如果您所提供的个人身份资料信息发生变更的，应当及时、有效地在{{appConfig.appName}}平台个人资料设置中同步更新，{{appConfig.appName}}有权对您更新的信息真实性和合法性进行审核。若您所提供的资料与事实不符或所提供的资料业已变更而未更新或有任何误导之嫌导致{{appConfig.appName}}无法为您进一步提供服务，我司不因此承担任何责任。</span>
        <span class="h2">2.帐户安全</span>
        <span class="p">您应当采取适当措施保障注册本平台过程中所指定之用户名和密码的机密性，不应在您公开的会员档案中包含任何联系方式（包括但不限于电话号码、街道地址、姓氏、URL、电子邮件地址或即时聊天工具号码）。而且您对以您的用户名和密码进行的所有活动承担全部责任。您同意：(a) 一经发现，将未经授权使用您的用户名或密码或其他侵犯帐户安全的任何行为立即通知本品后台；(b) 确保您于每次会话结束后退出您的帐户；<span class="bold">(c)本平台对因您未遵守上述规定而给您或他人带来的任何损失不承担责任。</span></span>
        <span class="p">当您从公共或共享计算机上访问您的帐户时，应格外小心以使其他人不能查看或记录您的密码或其他个人信息。如果您与其他人共享您的计算机，应将 Microsoft .Net Passport 或 AOL ScreenName 链接至您的{{appConfig.appName}}帐户，您应考虑禁用自动登录功能。</span>
        <span class="h2">3.会员服务使用规则</span>
        <span class="p"><span class="bold">3.1</span>为保护本平台所有会员之利益，以及保障本平台持续经营之目的，除经我们事先书面允许之外，您不得在任何公共信息平台（包括但不限于任何网站、网页、微博、抖音、报纸、期刊、电视、电影等各种类型的媒体）或在使用任何网络软件（包括但不限于QQ、微信、MSN等即时通讯工具）的过程中将您在本平台的ID号码或昵称与您的任何联系方式一并予以公布。一旦我们发现您存在上述行为，则我们有权独立做出判断并就此采取限制您的会员权限或取消您的会员资格等惩罚性措施。</span>
        <span class="p"><span class="bold">3.2</span>
        为保护本平台所有会员之利益，以及保障本平台持续经营之目的，一旦我们发现您在本平台的ID或昵称和相应个人的联系方式被一并公布在任何其他公共信息平台（包括但不限于任何网站、网页、微博、抖音、报纸、期刊、电视、电影等各种类型的媒体）或被任何个人通过网络软件（包括但不限于QQ、微信、MSN等即时通讯工具）所公布，无论该信息的公布是任何人所为，您在此同意完全授权我们，在您会员身份持续期间，就撤销上述公布之信息采取任何行动，在前述公布之信息得以撤销之前，我们有权以任何方式限制您在本平台的会员权利，无论该信息之公布是否是您所为。对于我们采取以上措施而给您带来的不便甚至损失，<span class="bold">于缔结本协议时您已表示理解并同意完全免除我们因此给您造成的任何损失的赔偿责任。</span>对于因您公布上述信息而给我们造成的损失，我们有权要求您予以赔偿。</span>
        <span class="h2">4.隐私</span>
        <span class="p">
          为了达成向您提供及时、优质服务之目的，在您注册时或访问本平台，或使用本平台产品或服务，或参加任何形式的会员活动时，我们会收集并使用您的个人信息。这些个人信息包括但不限于：您的姓名、昵称、出生年月、性别、籍贯、行业、收入、照片、手机号码及电子邮件地址等联系方式个人信息以及平台自动接收并记录会员的浏览器和服务器日志上的信息(含会员登录所在地的IP地址、在线、无线信息、信件资料等)。本平台收集上述信息将用于：提供服务、改进网容，满足会员对某种产品、活动的需求、通知会员最新产品、活动信息、或根据法律法规要求的用途。
        </span>
        <span class="p">
          您提供的上述个人信息将存储在我们的计算机上。一经缔结本协议则意味着您同意我们在您会员身份终止前，无需您事先同意即可无偿使用该等信息为您<span class="bold">提供婚介服务</span>或您定制的<span class="bold">其他相关服务</span>，包括但不限于创建兴趣、偏好和浏览模式档案或开展其它活动。您同意我们对所有会员提交的信息进行有关编排处理后形成的数据库拥有完全的知识产权。
        </span>
        <span class="p">
          为了保证向您提供及时、优质的婚介服务，本平台会向您提供的电话、手机、电子邮箱拨打电话或发送短信及邮件（内容包括但不限于服务流程确认，其他会员希望与您联系、约见，活动信息和咨询、新服务或新产品的推介等、提示再次登录本平台等）。您注册为我们的会员即表示您已经明确同意接听上述电话或收到短信、邮件；如您不想接听上述电话或收到短信、邮件，请您联系客服注销您的注册账号。如您未注销您的账户，则视为您同意接听或接受{{appConfig.appName}}给您的电话、短信及邮件。
        </span>
        <span class="p">
          您在缔结本协议时已理解并同意，您在本平台上公开发布的个人信息，任何登陆到本平台的第三人均可查看。尊重会员个人隐私是{{appConfig.appName}}的一项基本政策。所以，在未经您的授权时{{appConfig.appName}}不会公开、编辑或透露您的未在本平台上公开发布的个人信息及保存在{{appConfig.appName}}各项服务中的非公开内容。但是为了保证向您提供及时、优质的婚介服务，我们在诚信的基础上认为透露这些信息某些情况是必要的，我们会将您的个人信息向下列第三人提供： (a)您明确同意让第三方共享资料；( b)只有透露您的个人信息，才能提供您所要求的产品和服务；( c)为保护本平台、会员及社会公众的财产权利或人身权利；( d)根据法律法规的规定，向司法机关提供；(e) “隐私政策”列出的各类情况；(f)执行软件验证服务；(g)执行软件升级服务；(h)网络同步服务；(i)提高会员的使用安全性并提供客户支持；(j)被搜索引擎在搜索结果中显示；<span class="bold">(k)因会员使用本平台特定功能或因会员有潜在婚恋服务意愿，向代表我们提供产品或服务的合作单位或授权单位（包括但不限于{{appConfig.appName}}线下VIP授权店）提供您的个人信息；</span>(l)受到黑客攻击导致会员信息泄漏的；（m）为实现使用目的，其他有必要的情况。
        </span>
        <span class="p">
          在前述情况下我们将您的个人信息提供给第三方不需要获得您事先同意。如因此给您造成任何损害您同意免除我们的相应法律责任。
        </span>
        <span class="p">
          如果您位于中华人民共和国（为本协议之目的，本协议中所指中华人民共和国或中国，仅包括中华人民共和国大陆地区，不包括香港特别行政区、澳门特别行政区和台湾地区）境外，请注意，您提供的信息将被发送到中华人民共和国。通过成为本平台的会员，您同意: (a) 将您的个人信息传输到中华人民共和国；(b) 在中华人民共和国境内使用；(c) 依据我们的“隐私政策”，在服务提供和操作中将您的个人信息传输给第三方。
        </span>
        <span class="h2">6.免责条款</span>
        <span class="p"><span class="bold"> 6.1 于缔结本协议时，您已同意就下列事由免除我们的法律责任：</span></span>
        <span class="p">a.我们会依据本协议为您提供服务，但您已理解并同意我们不保证所提供的服务将最终帮助您找到您的伴侣；</span>
        <span class="p">b.您承认对于您利用本平台提供的服务或信息而从事的各类行为，包括在本平台发布各类信息，利用本平台提供之信息联系其他会员等，均为您个人行为且您个人对该类行为承担完全责任；</span>
        <span class="p bold">c.对于会员上传的照片、资料、证件等信息，本平台已采用相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，因此您在使用本平台时，可能会接触到令人不快、不适当或令人厌恶的内容。您同意在任何情况下，我们均不为会员经由本平台以张贴、发送电子邮件或其它方式传送的任何内容负责。相关责任由上传上述内容的会员负责。对于上传的信息，其它会员应以自己的独立判断来确定上传的照片的真实性、准确性，并独立承担可能产生的不利后果和责任，本平台不承担任何法律责任；</span>
        <span class="p bold">d.对于本平台提供的各种广告信息、链接、资讯等（如有），本平台已采用相关措施并已尽合理努力进行审核；但并不就你与经由本平台所提供之信息与前述广告主、广告经营者进行的联系或进行的商业往来做出任何承诺及保证，基于前述行为完全属于您和广告主、广告经营者之间的行为，与本平台没有任何关系。对于前述商业往来所产生的任何损害或损失，本平台不承担任何责任；</span>
        <span class="p bold">e.是否使用本平台服务下载或取得任何资料应由您自行考虑并自负风险，您同意因任何资料的下载而导致您电脑系统的任何损坏或数据丢失等后果，本平台不承担任何责任；</span>
        <span class="p bold">f.对于会员自发组织的任何活动，或自发成立的组织的任何行为，本平台不负责任。</span>
        <span class="h2">6.2 责任限制。</span>
        <span class="p bold">本平台和{{appConfig.appName}}或任何{{appConfig.appName}}关联企业，对于您或任何第三人因以任何方式连接本平台、或因使用、无法使用或使用本平台的结果可能导致的任何金钱或其他损失或损害不承担任何责任（该等损失或损害包括但不限于，任何直接的、间接的、惩罚性的或必然的损失或伤害，或任何收入、利润、信誉、数据、合同、资金使用上的任何损失，或以任何方式由任何种类的业务中断引起或与之相关的损失或伤害，无论是民事侵权行为、合同还是其他）。</span>
        <span class="p bold">遵照以前所述，您同意，倘若您因为使用本平台或本平台提供之服务而遭受损失，且经过我们书面确认本平台应当对该损失负责的情况下，我们对您承担的赔偿责任，将不超过您已支付的会员费的金额。</span>
        <span class="p"><span class="bold">6.3</span>会员因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的一切损失，{{appConfig.appName}}及合作单位不承担责任。</span>
        <span class="p"><span class="bold">6.4</span>本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰。会员应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</span>
        <span class="p"><span class="bold">6.5</span>对于会员的投诉，本平台将认真核实，但不能保证最终公布的投诉处理结果的完全公正，您同意对于投诉内容和投诉处理结果，如侵犯您的隐私权、名誉权等合法权利，免除本平台的相应法律责任。本平台保留根据业务发展情况修改上述免责事由的权利。</span>
        <span class="p bold">6.6 在任何情况下，心动空间公司均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因会员使用服务而遭受的利润损失、利益损失，承担责任（即使心动空间公司已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，心动空间公司对会员承担的全部责任，无论因何原因或何种行为方式，始终不超过会员在使用{{appConfig.appName}}服务而支付给公司的服务费用(如有) 。</span>
        <span class="h2">7.本协议之生效及终止</span>
        <span class="p"><span class="bold">7.1</span>一旦您开始使用本平台或者成为本平台的会员，则本协议立即生效。如果您选择使用本平台或者成为本平台的会员，表示您选择开始使用本平台提供之任何信息及服务并同时接受本协议所有条款之约束。</span>
        <span class="p"><span class="bold">7.2</span>您可以随时终止您的会员身份，您的终止行为在我们收到您有效通知后立即生效。您可以通过以下方式与我们联系：本人亲自至成都市武侯区锦城大道666号奥克斯广场5F，或者在线联系我们。于缔结本协议时，您已知悉并同意，就前述单方面终止会员身份的行为，您并无任何权利要求，<span class="bold">本平台亦无义务向您返还您已支付的任何费用（如有），</span>但本平台将于收到您的有效通知并审查确认您的身份后撤销您在本平台上的相关信息。<span class="bold">如您希望终止您的会员身份但未通过有效方式通知我们，使得您在本平台上的相关信息未能及时撤销的，您无权以任何理由就此对我们主张任何权利。</span></span>
        <span class="p"><span class="bold">7.3</span>如您违反本协议之任一条款，我们有权立即终止您的会员身份（包括付费会员之身份），及访问和使用本平台的权利。您同意在上述情况下我们终止本协议并不需要通知您即可生效，<span class="bold">对于您在此情况下受到的任何损失我们不承担任何责任且我们没有义务返还您已交纳的会员费（无论全部或部分）。</span></span>
        <span class="h2">8.不可抗力</span>
        <span class="p">您同意并认可，对于因超出我们合理控制范围的任何事件或情况，包括但不限于战争、入侵、恐怖袭击、自然灾害或其他不可抗力，<span class="bold">以及任何技术故障的发生、电气短路、第三方侵入本平台等情况所给您造成的任何损失或损害，我们无需承担任何赔偿责任，并不被视为违反本协议。</span></span>
        <span class="h2">9.其他</span>
        <span class="h2">9.1 可分割性。</span>
        <span class="p">如果本协议的任何部分被有权机关确定为无效或被协议双方认定为不可实施，那么协议的其余部分应该继续生效。就无效或不可实施之部分，我们有权单方面进行修改，使之具备法律效力且可以实施。</span>
        <span class="h2">9.2 转让。</span>
        <span class="p">我们有权单方面转让或转移我们在本协议项下的全部或部分权利和义务而不需要提前通知您。由于本平台提供服务有较强的人身性，您承诺放弃您转让本协议项下之权利和义务的权利。</span>
        <span class="h2">9.3 关系。</span>
        <span class="p">您同意不会因为本协议或使用本平台，而与本平台之间产生合资、合伙、雇佣或代理关系。</span>
        <span class="h2">9.4 权利认定。</span>
        <span class="p">本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。</span>
        <span class="h2">9.5 标题。</span>
        <span class="p">本协议中的标题仅为方便而设，不具法律或契约效果。</span>
        <span class="h2">9.6 联系信息。</span>
        <span class="p">如在使用{{appConfig.appName}}服务过程中有任何疑问及需求可通过前往门店：成都市武侯区锦城大道666号奥克斯广场5F或线上与我们联系。</span>
        <span class="h2">特 别 声 明：</span>
        <span class="p"> 鉴于：我国《合同法》第39条规定：采用格式条款订立合同的，提供格式条款的一方应当遵循公平原则确定当事人之间的权利和义务，并采取合理的方式提请对方注意免除或者限制其责任的条款，按照对方的要求，对该条款予以说明。{{appConfig.appName}}在此依法做出特别声明如下：{{appConfig.appName}}采取合理的方式提请您注意的义务将通过如下方式实现：在本协议中{{appConfig.appName}}以明确的足以引起您注意的加重字体、斜体、下划线、颜色标记等合理方式提醒会员注意相关条款（需要强调的是，还包括会员应特别注意任何未明确标记的含有“不承担”、“免责”“不得”等形式用语的条款），该等条款的确认将导致您在特定情况下的被动、不便、损失，请您在确认同意本协议之前再次阅读上述条款。双方确认上述条款非属于《合同法》第40条规定的“免除其责任、加重对方责任、排除对方主要权利的”的条款，{{appConfig.appName}}尊重会员的权利尤其是诉讼的权利，但作为在线网络信息服务提供方，{{appConfig.appName}}在尊重会员诉讼权利的同时建议诉讼管辖地法院为我司所在地法院，而会员选择同意合同并使用服务即视为双方对此约定达成了一致意见。鉴于{{appConfig.appName}}已依法明确了上述条款、履行了格式条款制订方的义务，您进行下一步，将被视为且应当被视为您已经完全注意并同意了本协议所有条款尤其是提醒会员注意的条款的合法性及有效性，会员不应当以{{appConfig.appName}}未对格式条款以合理方式提醒会员注意，或未根据会员要求尽到说明义务为理由而声称或要求法院或其它任何第三方确认相关条款非法或无效。</span>
        <span class="p">我已阅读并充分理解了以上协议中各条款，特别是涉及免除或者限制{{appConfig.appName}}责任的免责条款，对会员的权利限制的条款、约定争议解决方式、司法管辖、法律适用的条款，并且同意以上协议的全部内容。</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appConfig: {
        appName: '爱乐空间'
      }
    }
  },
  created() {
    this.appConfig.appName = this.$route.query.appName || '爱乐空间'
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
	.text {
		padding: 40rpx;
	}
	.h1,.h2,.p {
		display: block;
	}
	.h1 {
		text-align: center;
		color: #2d2d2d;
    font-size: 20px;
    margin-bottom: 10px;
	}
	.h1 {
		font-size: 40rpx;
		line-height: 80rpx;
		padding-bottom: 40rpx;
	}
	.h2 {
		line-height: 80rpx;
		font-size: 30rpx;
		font-weight: bold;
    margin: 10px 0;
	}
	.p {
		text-indent:2em;
		display: block;
		line-height: 1.5;
	}
	.bold {
		font-weight: bold;
	}
  .main {
    padding: 10px 20px;
    font-size: 16px;
  }
</style>