import Vue from "vue"
import VueRouter from "vue-router"
import index from "../views/index.vue"
import layout from "../components/layout.vue"
import sex from "../views/sex.vue"
import isSelf from "../views/isSelf.vue"
import region from "../views/region.vue"
import year from "../views/year.vue"
import height from "../views/height.vue"
import education from "../views/education.vue"
import income from "../views/income.vue"
import maritalStatus from "../views/maritalStatus.vue"
import login from "../views/login.vue"
import share from "../views/share/index.vue"
import cp from "../views/cp/index.vue"
import cpForm from "../views/cp/form/index.vue"
import cpPair from "../views/cp/pair/index.vue"
import cpIM from "../views/cp/IM/index.vue"
import copy from "../views/copy.vue"
import h5 from "../views/h5/index.vue"
import h5Answer from "../views/h5/h5Answer.vue"
import rests from "../views/rests/index.vue"
import v1 from "../views/v1/index.vue"
import pc from "../views/pc/index.vue"
import userAgreement from "../views/userAgreement.vue"
import kk from "../views/kk.vue"
import pickU from "../views/pickU/index.vue"
import xshd from "../views/xshd/index.vue"
import Ritsu from '@/views/ritsu/index'
import mugi from '@/views/mugi/index'
import submitSuccess from '@/views/submitSuccess/index'
import newSex from '@/views/mugi/newSex'
import bili from '@/views/bili/index'
import bito from '@/views/bili/bito'
import bithird from '@/views/bili/bithird'
import bifo from '@/views/bili/bifo'
import ocean from '@/views/ocean/index'
import challenge from '@/views/challenge/index'
import wb from '@/views/weibo/index'
import city from '@/views/bili/city.vue'
import sevenDaysCp from '@/views/sevenDaysCp/index.vue'
import encounterI from '@/views/cp/encounter/I.vue'
import encounterII from '@/views/cp/encounter/II.vue'
import welfare from '@/views/aimenn/welfare/index.vue'

import spring from '@/views/spring/index'
import blind from '@/views/blind/index.vue'
import single from '@/views/single/index.vue'
import match from '@/views/match/index.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// import xshd from "../views/xshd/index.vue"; 

Vue.use(VueRouter)
const routes = [{
  path: "/",
  name: "index",
  component: index,
},
{
  path: "/login",
  name: "login",
  component: login,
  meta: {
    back: true,
    right: "",
    title: "完善资料",
  },
},
{
  path: "/info",
  name: "info",
  component: layout,
  children: [{
    path: "sex",
    name: "sex",
    component: sex,
    meta: {
      back: false,
      right: "",
      title: "完善资料",
      index: 1,
    },
  },
  {
    path: "newSex",
    name: "newSex",
    component: newSex,
    meta: {
      back: false,
      right: "",
      title: "完善资料",
      index: 1,
    },
  },
  {
    path: "region",
    name: "region",
    component: region,
    meta: {
      back: true,
      right: "",
      title: "完善资料",
      index: 2,
    },
  },
  {
    path: "year",
    name: "year",
    component: year,
    meta: {
      back: true,
      right: "",
      title: "完善资料",
      index: 3,
    },
  },
  {
    path: "height",
    name: "height",
    component: height,
    meta: {
      back: true,
      right: "",
      title: "完善资料",
      index: 4,
    },
  },
  {
    path: "education",
    name: "education",
    component: education,
    meta: {
      back: true,
      right: "",
      title: "完善资料",
      index: 5,
    },
  },
  {
    path: "income",
    name: "income",
    component: income,
    meta: {
      back: true,
      right: "",
      title: "完善资料",
      index: 7,
    },
  },
  {
    path: "maritalStatus",
    name: "maritalStatus",
    component: maritalStatus,
    meta: {
      back: true,
      right: "",
      title: "完善资料",
      index: 6,
    },
  },
  {
    path: "isSelf",
    name: "isSelf",
    component: isSelf,
    meta: {
      back: true,
      right: "",
      title: "是否本人",
      index: 8,
    },
  },
  ],
},
{
  path: "/share",
  name: "share",
  component: share,
},
{
  path: "/cp",
  name: "cp",
  component: cp,
},
{
  path: "/cp/form",
  name: "cpForm",
  component: cpForm,
},
{
  path: "/cp/pair",
  name: "cpPair",
  component: cpPair,
},
{
  path: "/cp/IM",
  name: "cpIM",
  component: cpIM,
},
{
  path: "/cp/encounter/I",
  name: "encounterI",
  component: encounterI,
},
{
  path: "/cp/encounter/II",
  name: "encounterII",
  component: encounterII,
},
{
  path: "/copy",
  name: "copy",
  component: copy,
},
{
  path: "/h5",
  name: "h5",
  component: h5,
},
{
  path: "/h5/answer",
  name: "h5Answer",
  component: h5Answer,
},
{
  path: "/rests",
  name: "rests",
  component: rests,
}, {
  path: "/v1",
  name: 'v1',
  component: v1
},
{
  path: "/pc",
  name: "pc",
  component: pc,
},
{
  path: "/userAgreement",
  name: "userAgreement",
  component: userAgreement,
},
{
  path: "/kk",
  name: 'kk',
  component: kk
},
{
  path: "/pickU",
  name: 'pickU',
  component: pickU
},
{
  path: "/xshd",
  name: 'xshd',
  component: xshd
},
{
  path: "/ritsu",
  name: "ritsu",
  component: Ritsu
},
{
  path: "/mugi",
  name: "mugi",
  component: mugi
},
{
  path: "/submitSuccess",
  name: "submitSuccess",
  component: submitSuccess
},
{
  path: "/bili",
  name: "bili",
  component: bili,
},
{
  path: "/bili/city",
  name: "city",
  component: city,
},
{
  path: "/bito",
  name: "bito",
  component: bito
},
{
  path: "/bifo",
  name: "bifo",
  component: bifo
},
{
  path: "/ocean",
  name: "ocean",
  component: ocean
},
{
  path: "/bithird",
  name: "bithird",
  component: bithird
},
{
  path: "/challenge",
  name: "challenge",
  component: challenge
},
{
  path: "/welfare",
  name: "welfare",
  component: welfare
},
{
  path: "/wb",
  name: "wb",
  component: wb
},
{
  path: "/spring",
  name: "spring",
  component: spring
},
{
  path: "/blind",
  name: "blind",
  component: blind
},
{
  path: "/single",
  name: "single",
  component: single
},
{
  path: "/sevenDaysCp",
  name: "sevenDaysCp",
  component: sevenDaysCp
},
{
  path: "/match",
  name: "match",
  component: match
},
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router