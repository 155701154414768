<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@font-face {
  font-family: 'DFShaoNvGB-W5';
  src: url(~@/assets/ttf/字魂142号-小浣熊.TTF);
  font-weight: normal;
  font-style: normal;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.banner {
  background: url('~@/assets/mugi/navBg.png') no-repeat;
  background-size: cover;
  height: 174px;
  margin-top: -2px;
  display: block;
  // box-shadow: 0 2.5px 10px 0 rgba($color: #ff7c8f, $alpha: 0.5);
  font-family: 'DFShaoNvGB-W5';
  color: #f34b6f;
  text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff,
    -1px -1px 0 #fff;
  .banner_text {
    width: 100%;
    height: 100%;
    // background: url('../assets/banner_text.png') no-repeat;
    // background-size: 100% 100%;
    text-align: center;
    font-size: 26px;
    line-height: 34px;
    .title {
      padding-top: 26px;
    }
  }
  .banner_title {
    width: 100%;
    height: 100%;
    // color: #fff;
    font-size: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-size: 14px;
    }
  }
}
.van-nav-bar {
  background: var(--titleColor);
  // height: 49px;
  /deep/ .van-nav-bar__title {
    color: #fffefe;
    // font-size: 20px;
  }
  /deep/ .van-nav-bar__text {
    color: #fff;
  }
  /deep/ .van-icon {
    color: #ffffff;
  }
}
/deep/.main {
  background: linear-gradient(to top, var(--bgColorF), var(--bgColorT));
  flex: 1;
  .title {
    color: #000;
    font-size: 20px;
  }
  .yearName,
  .yearItem {
    border-color: var(--bgColorF) !important;
  }
}
</style>
<template>
  <div class="container">
    <van-nav-bar
      :title="title || '完善资料'"
      :left-text="leftText"
      :right-text="rightText"
      :left-arrow="leftArrow"
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :border="false"
    />
    <div class="banner">
      <div class="banner_text" v-if="step == 1">
        <div class="title">完善资料</div>
        <!-- <div class="text">为你匹配最合适的人</div> -->
      </div>
      <div class="banner_title" v-else>
        <p>({{ step }}/{{ pageInfo.ownConfig == 1 ? '8' : '7' }})</p>
        {{ text[step - 2] }}
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { ENV } from '@/config.js'
export default {
  data() {
    return {
      title: '姓名',
      leftText: '',
      rightText: '',
      leftArrow: false,
      step: 1,
      text: [
        '你的工作地区在那里',
        '你是哪一年出生的？',
        '你的身高',
        '你的学历',
        '你的婚姻状况',
        '你的月收入',
        '是否是本人注册',
      ],
    }
  },
  created() {
    console.log(666)
    let navData = this.$route.meta
    // this.step = navData.index;
    this.navDataFn(navData)
  },
  watch: {
    $route(to) {
      this.navDataFn(to.meta)
      this.step = to.meta.index
    },
  },
  methods: {
    navDataFn(data) {
      console.log(data)
      let navData = data
      this.title = navData.title
      if (navData.back) {
        this.leftArrow = true
        this.leftText = '返回'
      } else {
        this.leftArrow = false
        this.leftText = ''
      }
      if (navData.right) {
        this.rightText = navData.right
      } else {
        this.rightText = ''
      }
      this.$forceUpdate()
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onClickRight() {
      let url
      if (ENV == 'development') {
        url = 'https://h5-jotc.yihot.com'
      } else {
        url = 'https://h5-jotc.yihot.com'
      }
      window.location.href = url
    },
  },
}
</script>
