<template>
  <div class="page">
		<img class="page_logo" :src="require('@/assets/logo2.png')">
    <img class="page_content" @click="goToUrl('')" style="width:100%" src="https://static.lspace.vip/lancer_images/kk_bg.jpg" alt="">
  </div>
</template>

<script>
export default {
	computed:{
		logo(){
			return this.$store.state.pageInfo.logo
		}
	},
  methods: {
    goToUrl() {
      this.$router.push({path:'/info/sex',query: {
        ...this.$route.query
      }})
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
	position: relative;
	background: white;
	min-height: 100vh;
	.page_logo{
		// position: absolute;
		// top: 0px;
		// left: 10px;
		margin-top: 10px;
		margin-left: 10px;
		object-fit: contain;
		width: 120px;
		z-index: 99;
	}
	.page_content{
		// position: absolute;
		// top: 50%;
		// transform: translateY(-50%);
	}
}
</style>