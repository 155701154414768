<template>
  <div class="bgImg">
    <div class="sub_bg">
      <img style="margin-top: 8%;"
           src="@/assets/aimenn/welfare_bgSub.png"
           alt="" />
      <div class="content">
        <div class="title">
          <img src="@/assets/aimenn/welfare.png"
               alt="" />
          <img src="@/assets/aimenn/welfare_blindBox.png"
               alt="" />
        </div>
        <div class="people">
          <img src="@/assets/aimenn/welfare_people.png"
               alt="" />
        </div>
      </div>
      <div class="gift">
        <img src="@/assets/aimenn/welfare_gift.png"
             alt="" />
      </div>
      <div class="btn heartBeat"
           @click="toInfoNewSex()">
        <img src="@/assets/aimenn/welfare_btn.png"
             alt="" />
      </div>
      <div class="agreement">
        <label for="">
          <input type="checkbox"
                 v-model="isChecked" /><span>同意</span><span @click="toUserAgreement">《隐私协议》</span></label>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      isChecked: false,
      pageInfo: {},

    }
  },
  methods: {
    toInfoNewSex () {
      if (this.isChecked) {
        this.$router.push({
          path: '/info/newSex',
          query: { ...this.$route.query },
        })
      } else {
        this.$toast('请确认同意隐私协议')
      }
    },
    toUserAgreement () {
      this.$router.push({
        path: '/userAgreement',
        query: {
          appName: this.pageInfo.shopName,
        },
      })
    }
  }
}
</script>

<style scoped lang="scss">
.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.bgImg {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/aimenn/welfare_bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;

  .sub_bg {
    position: relative;
    text-align: center;
    left: -1%;

    img {
      width: 87vw;
      height: 94vh;
    }

    .content {
      .title {
        position: absolute;
        top: 42px;
        left: 4%;

        img:nth-of-type(1) {
          height: 32px;
          width: 189px;
          margin-top: 23px;
        }

        img:nth-of-type(2) {
          height: 51px;
          width: 248px;
        }
      }

      .people {
        position: absolute;
        top: 23%;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 4%;

        img {
          width: 247px;
          height: 428px;
        }
      }
    }

    .gift {
      position: absolute;
      bottom: 11%;
      left: 1%;

      img {
        width: 100vw;
        height: 210px;
      }
    }

    .btn {
      position: absolute;
      bottom: 4%;
      left: 50%;
      margin-left: -25%;

      img {
        width: 54vw;
        height: 42px;
      }
    }

    .agreement {
      font-size: 14px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-left: 3%;
      bottom: 2%;

      label {
        input {
          vertical-align: middle;
          height: 16px;
          width: 16px;
        }

        span {
          vertical-align: middle;
        }

        span:nth-of-type(1) {
          margin-left: 4px;
          color: rgb(92, 83, 83);
        }
      }
    }
  }
}
.heartBeat {
  -webkit-animation: heartbeat 1s infinite;
  animation: heartbeat 1s infinite;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>