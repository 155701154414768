<style lang="scss" scoped>
.title_box {
  width: 375px;
  height: 64px;
  background: #ffa8ac;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    width: 110px;
  }
}
.list_box {
  background-image: url('../../assets/v1/listBg.png');
  background-size: 100% 100%;
  height: 438px;
  position: relative;
  .list{
    display: flex;
    flex-wrap:wrap;
    padding: 19px 15px;
    justify-content: space-between;
    position: relative;
    .item {
      width: 108px;
      height: 120px;
      background-size: 100% 100%;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        margin-left: 10px;
      }
      span,.van-icon {
        margin-top: 30px;
      }
      .van-icon {
        opacity: 0.8;
      }
      &:nth-child(1) {
        background-image: url('../../assets/v1/item1.png');
        color: #21d87c;
      }
      &:nth-child(2) {
        background-image: url('../../assets/v1/item2.png');
        color: #6d70ee;
      }
      &:nth-child(3) {
        background-image: url('../../assets/v1/item3.png');
        color: #fb7794;
      }
      &:nth-child(4) {
        background-image: url('../../assets/v1/item4.png');
        color: #bb75ca;
      }
      &:nth-child(5) {
        background-image: url('../../assets/v1/item5.png');
        color: #25a1f4;
      }
      &:nth-child(6) {
        background-image: url('../../assets/v1/item6.png');
        color: #fe979f;
      }
    }
  }
  .images {
    position: absolute;
    width: 231px;
    left: 7px;
    bottom: -45px;
  }
  .imageText {
    width: 101px;
    position: absolute; 
    right: 18px;
    bottom: 14px;
  }
}
.rule_box {
  background-image: url('../../assets/v1/ruleBg.png');
  background-size: 105% 100%;
  width: 100%;
  height: 270px;
  font-size: 11px;
  color: #fff;
  .rule_title {
    font-size: 13px;
    font-weight: bold;
    position: relative;
    text-align: center;
    margin-bottom: 21px;
    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      height: 1px;
      background: #abc1c1;
      width: 60px;
      left: 50%;
      margin-left: -30px;
      bottom: -10px;
    }
  }
  .rule_text_box {
    width: 200px;
    margin-left: 24px;
    padding-top: 60px;
    p {
      margin-bottom: 12px;
    }
  }
}
.shop_box {
  width: 100%;
  overflow: hidden;
  height: 144px;
  position: relative;
  .circle-left,.circle-right {
    position: absolute;
    border-radius: 50%;
    background: #e8eeee;
  }
  .circle-left {
    width: 60px;
    height: 60px;
    bottom: -10px;
    left: -10px;
  }
  .circle-right {
    width: 138px;
    height: 138px;
    top: 1px;
    right: -79px;
  }
  .shop_img_box {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-top: 10px;
    .shop_img {
      width: 110px;
    }
  }
}
.site_box {
  background-image: url('../../assets/v1/siteBg.png');
  background-size: 100% 100%;
  height: 221px;
  padding: 21px 16px 0;
  .footer_logo {
    img {
      width: 90px;
    }
    margin-bottom: 15px;
  }
  .footer_text_item {
    color: #fff;
    margin-bottom: 12px;
    .footer_title {
      font-size: 14px;
      line-height: 24px;
    }
    .footer_text {
      font-size: 12px;
    }
  }
}
.shop_box {
  padding: 15px;
  box-sizing: border-box;
  .shop_title {
    font-size: 18px;
    color: #1c5b5a;
    font-weight: bold;
  }
}
.van-popup {
  overflow-y: visible;
  border-radius: 6px;
}
.popup_box {
  width: 300px;
  min-height: 484px;
  border-radius: 6px;
  position: relative;
  padding-top: 155px;
  box-sizing: border-box;
  
  .popup_bg {
    width: 315px;
    height: 185px;
    position: absolute;
    top: -30px;
  }
  .btn_next,.btn_close {
    width: 194px;
    line-height: 38px;
    font-size: 14px;
    text-align: center;
    border-radius: 21px;
  }
  .btns_box {
    text-align: center;
    padding-bottom: 15px;
    .btn_next {
      color: #fff;
      background: #ffa9ad;
      border: 1px solid #ffa9ad;
      margin-bottom: 16px;
    }
    .btn_close {
      color: #ffa9ad;
      background: #fff;
      border: 1px solid #ffa9ad;
    }
  }
  .popup_text_box {
    color: #525d75;
    padding:18px 15px 20px;
    .popup_text_item {
      display: flex;
      margin-bottom: 15px;
      .popup_text_label {
        flex-shrink: 0;
        width: 70px;
        font-size: 13px;
      }
      .popup_text {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .popup_title_box {
    position: absolute;
    top:70px;
    left: 70px;
    text-align: center;
    color: #fff;
    .popup_logo {
      width: 54px;
      margin-bottom: 5px;
    }
    .popup_title {
      font-size: 17px;
      font-weight: bold;
      line-height: 35px;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 0 7px;
    }
  }
}
.agreement {
  color: #fff !important;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  line-height: 20px;
  width: 100%;
  margin-top: -8px;
  position: relative;
  z-index: 10;
  .van-checkbox {
    margin-right: 5px;
  }
  span {
    color: #ffa8ac;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="content_box">
    <div class="title_box">
      <img class="logo" src="../../assets/v1/LOGO.png" alt="">
    </div>
    <div class="list_box">
      <div class="list">
        <div class="item" @click="showPopup(0)">
          <span>高学历专场</span><van-icon name="arrow" />
        </div>
        <div class="item" @click="showPopup(1)">
          <span>高收入专场</span><van-icon name="arrow" />
        </div>
        <div class="item" @click="showPopup(2)">
          <span>名企专场</span><van-icon name="arrow" />
        </div>
        <div class="item" @click="showPopup(3)">
          <span>活动专场</span><van-icon name="arrow" />
        </div>
        <div class="item" @click="showPopup(4)">
          <span>男生专场</span><van-icon name="arrow" />
        </div>
        <div class="item" @click="showPopup(5)">
          <span>女生专场</span><van-icon name="arrow" />
        </div>
        <div class="agreement" v-if="isChecked"><van-checkbox v-model="checked" checked-color="#ffa8ac" ></van-checkbox>我已同意<span @click="toUserAgreement">《用户协议》</span></div>
      </div>
      <img class="images" src="../../assets/v1/images.png" alt="">
      <img class="imageText" src="../../assets/v1/imageText.png" alt="">
    </div>
    <div class="rule_box">
      <div class="rule_text_box">
        <div class="rule_title">加入群聊规则</div>
        <p>1.本活动是由爱乐空间组织的专场脱单活动，根据不同单身人群的不同属性，为大家定制专场脱单群聊。</p>
        <p>2.每个专场群聊内都是符合当前群聊属性的单身人群，符合要求即可进入，所填信息经由情感顾问核实后才可进入对应群聊，若不符合当前群聊要求，建议选择其他群聊加入。</p>
      </div>
    </div>
    <div class="shop_box">
      <div class="circle-left"></div>
      <div class="circle-right"></div>
      <div class="shop_title">门店介绍</div>
      <div class="shop_img_box">
        <img class="shop_img" src="../../assets/v1/shop1.png" alt="">
        <img class="shop_img" src="../../assets/v1/shop2.png" alt="">
        <img class="shop_img" src="../../assets/v1/shop3.png" alt="">
      </div>
    </div>
    <div class="site_box">
      <div class="footer_logo">
        <img src="../../assets/v1/LOGO.png" alt="">
      </div>
      <div class="footer_text_item">
        <div class="footer_title">爱乐空间（奥克斯店）</div>
        <div class="footer_text">地址： 成都市武侯区锦城大道666号奥克斯广场5楼503</div>
      </div>
      <div class="footer_text_item">
        <div class="footer_title">爱乐空间（金牛店）</div>
        <div class="footer_text">地址： 成都市金牛区一环路北三段1号金牛万达广场3055</div>
      </div>
      <div class="footer_text_item">
        <div class="footer_title">爱乐空间（春熙路店）</div>
        <div class="footer_text">地址： 成都市锦江区总府路2号百盛购物中心4L</div>
      </div>
    </div>
    <van-popup v-model="show">
      <div class="popup_box">
        <img class="popup_bg" src="../../assets/v1/popbg.png" alt="">
        <div class="popup_title_box">
          <img class="popup_logo" src="../../assets/v1/logo_text.png" alt="">
          <div class="popup_title">{{itemList[type].title}}</div>
        </div>
        <div class="popup_text_box">
          <div class="popup_text_item" v-for="(item,index) of itemList[type].textList" :key="index">
            <div class="popup_text_label">{{item.label}}</div>
            <div class="popup_text" v-html="item.text"></div>
          </div>
        </div>
        <div class="btns_box">
          <button class="btn_next" @click="goToUrl">符合要求,继续加入</button>
          <button class="btn_close" @click="show = false">退出加入其他群聊</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      type: 1,
      itemList: [{
        title: '高学历专场',
        textList: [{
          label:'群聊规模：',
          text: '498'
        }, {
          label:'学历分布：',
          text: `本科50%<br />硕士30%<br />博士20%`
        }, {
          label:'真实可靠：',
          text: '经过实名+学历认证'
        }, {
          label:'男女比例：',
          text: '1:1'
        }, {
          label:'要求：',
          text: '本科以上学历'
        }]
      }, {
        title: '高收入专场',
        textList: [{
          label:'群聊规模：',
          text: '486'
        }, {
          label:'收入分布：',
          text: '年入50w+占比50%<br />年入100w+占比30%<br />年入200w+占比20%'
        }, {
          label:'真实可靠：',
          text: '经过实名+房车认证'
        }, {
          label:'男女比例：',
          text: '1.5:1'
        }, {
          label:'要求：',
          text: '年入50w+以上'
        }]
      }, {
        title: '名企专场',
        textList: [{
          label:'群聊规模：',
          text: '500'
        }, {
          label:'名企分布：',
          text: '互联网高管占比50%<br />金融高管占比30%<br />政企占比20%'
        }, {
          label:'真实可靠：',
          text: '经过实名+工作认证'
        }, {
          label:'男女比例：',
          text: '1.2:1'
        }, {
          label:'要求：',
          text: '所在公司规模在300人以上团队/在公司处于重要职位/自有公司'
        }]
      }, {
        title: '活动专场',
        textList: [{
          label:'群聊规模：',
          text: '478'
        }, {
          label:'活动类型：',
          text: '户外旅游，剧本杀，KTV，射箭，高尔夫，电竞游戏······'
        }, {
          label:'真实可靠：',
          text: '经过实名+工作认证+学历认证'
        }, {
          label:'男女比例：',
          text: '1:1'
        }, {
          label:'要求：',
          text: '有自己的兴趣爱好/所擅长的领域<br />至少大专以上学历'
        }]
      }, {
        title: '男生专场',
        textList: [{
          label:'群聊规模：',
          text: '378'
        }, {
          label:'男生类型：',
          text: '阳光暖男，成熟大叔，运动型男，高颜值，风趣幽默，事业型，顾家型······'
        }, {
          label:'真实可靠：',
          text: '经过实名+工作认证+学历认证'
        }, {
          label:'男女比例：',
          text: '10:1'
        }, {
          label:'要求：',
          text: '无不良嗜好，恋爱观正向<br />至少大专以上学历'
        }]
      }, {
        title: '女生专场',
        textList: [{
          label:'群聊规模：',
          text: '428'
        }, {
          label:'女生类型：',
          text: '可爱俏皮，温柔善良，高颜值，风趣幽默，事业型，顾家型······'
        }, {
          label:'真实可靠：',
          text: '经过实名+工作认证+学历认证'
        }, {
          label:'男女比例：',
          text: '1:10'
        }, {
          label:'要求：',
          text: '无不良嗜好，恋爱观正向<br />至少大专以上学历'
        }]
      }],
      checked: false,
      isChecked: false
    }
  },
  created() {
    this.enterPage()
    let data = Object.assign(this.$route.query,this.GetRequest())
    console.log(data)
    if(data.from && data.from.indexOf('tc_') == 0 ) {
      this.isChecked = true
    } else {
      this.checked = true
    }
  },
  methods: {
    showPopup(type) {
      if(this.checked) {
        this.show = true
        this.type = type
      } else {
        this.$toast('请确认同意用户协议')
      }
    },
    goToUrl() {
      let arr = ['gaoxueli','gaoshouru','mingqi','huodong','nansheng','nvsheng']
      this.$router.push({path:'/info/sex',query: {
        crowd:arr[this.type],
        ...this.$route.query
      }})
    },
    toUserAgreement() {
      this.$router.push({path:'/userAgreement',query: {
        appName: this.pageInfo.publicAppName
      }})
    }
  }
};
</script>
