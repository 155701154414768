<style lang="scss" scoped>
    @import './index.scss';
</style>
<template>
    <div class="from_box">
        <div class="from_bg">
            <div class="logo">
                <img :src="require('@/assets/newLogo.png')" alt="">
            </div>
            <div class="from_title">
                <img :src="require('@/assets/cp/fromTitle.png')" alt="">
            </div>
            <div class="from_content">
                <div class="title">
                    <h4>加入一周CP·恋爱就变得如此简单</h4>
                    <p>游戏互动交流避免尴尬，先聊天了解，合适再见面</p>
                </div>
                <div class="from">
                    <div class="from_item" :class="!fromVerify.trueName? 'verify':''">
                        <div class="label">
                            姓名<span>*</span>
                        </div>
                        <div class="content">
                            <van-field v-model="fromData.trueName" placeholder="请输入姓名" />
                        </div>
                        <p>请输入姓名</p>
                    </div>
                    <div class="from_item" :class="!fromVerify.sex? 'verify':''">
                        <div class="label">
                            性别<span>*</span>
                        </div>
                        <div class="content">
                            <div class="sex male" :class="fromData.sex == '0'? 'activate':''" @click="set(fromData, 'sex', '0')">男</div>
                            <div class="sex female" :class="fromData.sex == '1'? 'activate':''" @click="set(fromData, 'sex', '1')">女</div>
                        </div>
                        <p>请选择性别</p>
                    </div>
                    <div class="from_item" :class="!fromVerify.age? 'verify':''">
                        <div class="label">
                            年龄<span>*</span>
                        </div>
                        <div class="content">
                            <van-field type="tel" maxlength="2" v-model="fromData.age" placeholder="请输入您的年龄" />
                        </div>
                        <p>请输入您的年龄</p>
                    </div>
                    <div class="from_item" :class="!fromVerify.phone? 'verify':''">
                        <div class="label">
                            手机号<span>*</span>
                        </div>
                        <div class="content">
                            <van-field type="tel" maxlength="11" v-model="fromData.phone" placeholder="请输入您的手机号" />
                        </div>
                        <p>请输入正确的手机号</p>
                    </div>
                    <div class="from_item" :class="!fromVerify.education? 'verify':''">
                        <div class="label">
                            学历(单选)<span>*</span>
                        </div>
                        <div class="content education">
                            <div class="education-item" :class="fromData.education == item.value? 'activate':''" v-for="(item, index) of educationList" :key="index" @click="set(fromData, 'education', item.value)">{{item.name}}</div>
                        </div>
                        <p>请选择您的学历</p>
                    </div>
                    <div class="btn" @click="submit">
                        一键开启缘分
                    </div>
                    <div class="carousel_box">
                        <ul class="carousel">
                            <li class="item" v-for="(item,index) of carouselList" :key="index">
                                <div class="left">
                                    <img :src="item.img" alt="">
                                </div>
                                <div class="name">
                                    {{item.name}}
                                </div>
                                <div class="centre">
                                    {{item.phone}}
                                </div>
                                <div class="right">
                                    {{item.time}}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { cpSubmitInfo, join } from '@/api/user.js';
export default {
    data() {
        return {
            fromData: {
                trueName: null,
                sex: null,
                age: null,
                phone: null,
                education: null
            },
            carouselList: [{
                name: '马*瑶',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg11.jpg',
                phone: '137****1119',
                time: '一分钟前'
            }, {
                name: '汪*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg12.jpg',
                phone: '131****5874',
                time: '一分钟前'
            }, {
                name: '唐*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg16.jpg',
                phone: '132****0013',
                time: '一分钟前'
            }, {
                name: '谢*才',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg17.jpg',
                phone: '130****0766',
                time: '一分钟前'
            }, {
                name: '邓*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg18.jpg',
                phone: '159****7749',
                time: '一分钟前'
            }, {
                name: '杨*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg13.jpg',
                phone: '136****6950',
                time: '一分钟前'
            }, {
                name: '陈*次',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg19.jpg',
                phone: '158****3156',
                time: '一分钟前'
            }, {
                name: '曾*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg14.jpg',
                phone: '168****4534',
                time: '一分钟前'
            }, {
                name: '高*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg15.jpg',
                phone: '158****7854',
                time: '一分钟前'
            }, {
                name: '周*宇',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg20.jpg',
                phone: '189****0024',
                time: '一分钟前'
            }, {
                name: '马*瑶',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg11.jpg',
                phone: '137****1119',
                time: '一分钟前'
            }, {
                name: '汪*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg12.jpg',
                phone: '131****5874',
                time: '一分钟前'
            }, {
                name: '唐*',
                img: 'https://static.lspace.vip/images/TikTok/TikTokimg16.jpg',
                phone: '132****0013',
                time: '一分钟前'
            }],
            educationList: [{
                name: '高中及以下',
                value: '2'
            }, {
                name: '中专',
                value: '1'
            }, {
                name: '大专',
                value: '3'
            }, {
                name: '大学本科',
                value: '4'
            }, {
                name: '硕士',
                value: '5'
            },{
                name: '博士',
                value: '6'
            }],
            fromVerify: {
                trueName: true,
                sex: true,
                age: true,
                phone: true,
                education: true
            },
            queryData: {}
        }
    },
    created() {
        this.enterPage()
        this.queryData = Object.assign(this.$route.query,this.GetRequest())
        console.log(this.queryData)
    },
    methods: {
        clickBtn() {
            this.$router.push({
                path: '/cp/form'
            })
        },
        set(obj,key,val) {
            this.$set(obj, key, val)
        },
        submit() {
            if(this.fromDataVerify()) {
                console.log('上传')
                console.log()
                this.$toast.loading({
                    message: '提交中...',
                    forbidClick: true,
                    duration: 0
                })
                console.log(this.getQueryVariable())
                this.fromData.traceInfo = this.queryData.from
                this.fromData.page = this.queryData.page
                this.fromData.url = window.location.href
                cpSubmitInfo(this.fromData).then(res => {
                    if(res.code == 200) {
                        localStorage.setItem('token',res.data.token)
                        localStorage.setItem('memberId',res.data.memberId)
                        localStorage.setItem('sex',res.data.sex)
                        this.$toast.clear()
                        this.$toast.loading({
                            message: '缘分开启中...',
                            forbidClick: true,
                            duration: 0
                        })
                        join({isAdv: 1}).then(e => {
                            console.log(e)
                            this.$toast.clear()
                            this.$router.push({
                                path: '/cp/pair'
                            })
                        })
                    } else {
                        this.$toast.clear()
                        this.$toast(res.message)
                    }
                })
            }
        },
        fromDataVerify() {
            let isVerify = true
            Object.keys(this.fromData).forEach(key => {
                if(!this.fromData[key]) {
                    isVerify = false
                    this.$set(this.fromVerify, key, false)
                } else {
                    if(key == 'phone') {
                        if(!(/^1[3456789]\d{9}$/.test(this.fromData[key]))) {
                            console.log('错误的')
                            isVerify = false
                            this.$set(this.fromVerify, key, false)
                        } else {
                            this.$set(this.fromVerify, key, true)
                        }
                    } else {
                        this.$set(this.fromVerify, key, true)
                    }
                }
            })
            return isVerify
        },
        GetRequest() {
            let url = location.search; //获取url中"?"符后的字串
            let theRequest = new Object();
            // let p = url.indexOf("?")
            if (url.indexOf("?") != -1) {
                let str = url.substr(1);
                let strs = str.split("&");
                for(let i = 0; i < strs.length; i ++) {
                        theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
                }
                return theRequest;
            } else {
                return false
            }
        }
    }
}
</script>